import { ActionReducerMap } from '@ngrx/store';
import { appReducer } from './app.reducers';
import { LocalPluginStates, SessionPluginStates } from '../core/constants/common.enum';

export interface State {
    optimyLocalStore: LocalPluginStates | null;
    optimySessionStore: SessionPluginStates | null;
}

export interface AppStore {
    app: State
}

export const reducers: ActionReducerMap<AppStore > | any = {
    app: appReducer,
};
