import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  PluginStates
} from '../../../../core/constants/common.enum';
import { Constants } from '../../../../core/constants/constant';
import { SharedService } from '../../../../core/services/shared/shared.service';
import { UtilsService } from '../../../../core/services/utils/utils.service';

@Component({
  selector: 'optimy-modal',
  templateUrl: './optimy-modal.component.html',
  styleUrls: ['./optimy-modal.component.scss']
})
export class OptimyModalComponent implements OnInit, OnDestroy {

  @Input() openModal: Observable<boolean>;
  @Input() closeModal: Observable<boolean>;
  @Input() closeOnOutsideClick: boolean = true;
  @Output() modalClosed: Subject<boolean> = new Subject();

  destroy$: Subject<boolean> = new Subject<boolean>();

  isOpen = false;

  constructor(private utils: UtilsService,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    if (this.openModal) {
      this.openModal.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.isOpen = true;
      });
    }
    if (this.closeModal) {
      this.closeModal.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.isOpen = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  close(event?: any): void {
    if (this.closeOnOutsideClick && (!event || event.target.classList.value.indexOf('optimy-modal') >= 0)) {
      if (this.utils.getLocalVal('isBookingConfirmed')) {
        this.utils?.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo]);
        this.utils.removeLocalStoreVal(Constants.optimyLocalStore, ['isBookingConfirmed']);
        this.sharedService.showAppLauncher(true);
      }
      this.isOpen = false;
      this.modalClosed.next(true);
    }
  }
}
