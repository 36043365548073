import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgentResponseData } from '../../../core/constants/agent.modal';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';
import {
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels, SnowplowTrackerProperties
} from '../../../core/constants/trackerLabels';

@Component({
  selector: 'app-queue-intro',
  templateUrl: './queue-intro.component.html',
  styleUrls: ['./queue-intro.component.scss']
})
export class QueueIntroComponent implements OnInit, AfterViewInit {
  @Input() agentStatusInfo: AgentResponseData;
  @Output() emitAction = new EventEmitter();

  customersInQueue: number;
  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties,
  };

  constructor(private snowplowService: SnowplowService) {}
  ngAfterViewInit() {
    this.customersInQueue = this.agentStatusInfo?.num_customers_in_queue > 0 ? this.agentStatusInfo?.num_customers_in_queue - 1 : 0;
  }

  ngOnInit() {
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.screenShown,
        this.agentStatusInfo.are_agents_online ? this.spTracker.labels.startScreenOnline : this.spTracker.labels.startScreenOffline, this.spTracker.labels.version, 2);
  }

  introAction() {
    this.emitAction.emit();
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.agentStatusInfo.are_agents_online ? this.spTracker.actions.start : this.spTracker.actions.leaveMessage,
        this.agentStatusInfo.are_agents_online ? this.spTracker.labels.startScreenOnline : this.spTracker.labels.startScreenOffline);
  }

}
