import { ScreenOrientationService } from 'src/app/core/services/utils/screen-orientation.service';
import {
  Component, ElementRef,
  EventEmitter, Input, OnDestroy, OnInit, Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnowplowTrackerCategories, SnowplowTrackerLabels } from 'src/app/core/constants/trackerLabels';
import { InviteReq, SendToList } from '../../../core/constants/chat.modal';
import { Context, PluginStates, RoomInfoStates } from '../../../core/constants/common.enum';
import { Constants } from '../../../core/constants/constant';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { VideoCallService } from '../../../core/services/video-call/video-call.service';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';

@Component({
  selector: 'app-invite-guest',
  templateUrl: './invite-guest.component.html',
  styleUrls: ['./invite-guest.component.scss']
})
export class InviteGuestComponent implements OnInit, OnDestroy {

  @Input() invitationLink: string;
  @Output() inviteFeedBack = new EventEmitter<void>();
  @ViewChild('invitation') inputEl: ElementRef;
  @ViewChild('userInput') userInput: ElementRef;

  inviteReq: InviteReq;
  inviteCode: string | any;
  guestList: SendToList[] = [];
  inviteLink: string;
  sendInvite = false;
  inviteList = [];
  contactError = false;
  expandField: boolean = false;

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories
  };
  currentCategory: string;
  context: Context[];

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private snowplowService: SnowplowService,
    private videoCallService: VideoCallService,
    private screenOrientationService: ScreenOrientationService,
    private utils: UtilsService,
    private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.context = this.utils.getCallSchemaContext();
    this.updateCurrentCategoryValue();
    this.inviteLink = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.inviteLink);
    this.inviteCode = this.utils.getCodeFromInviteLink(this.inviteLink);
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.screenShown,
      this.spTracker.labels.genericScreen, 'version', 1, this.context);

    this.screenOrientationService.orientationChanged$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateCurrentCategoryValue();
      });
  }

  private updateCurrentCategoryValue(): void {
    if (this.screenOrientationService.isLandscapeAndMobile) {
      this.currentCategory = this.spTracker.categories.callInviteLandscape;
    } else {
      this.currentCategory = this.spTracker.categories.callInvite;
    }
  }

  isMobile(): boolean {
    return this.utils.isOnMobile();
  }

  onAdding(tag: any): void {
    this.contactError = this.checkForValidation(tag?.value);
  }

  onRemoving(tag: any): void {
    if(this.checkForValidation(tag?.value)) {
      this.contactError = false;
    }
  }

  checkForValidation(tag: string) {
    return !new RegExp(Constants.emailRegex).test(tag) && !new RegExp(Constants.phoneNumRegex).test(tag);
  }

  copyLink() {
    this.copyLinkCommand();
    this.snowplowEventToggle(this.spTracker.labels.copy);
    this.toastr.success(this.translate.instant('inCall.linkCopied'));
  }

  private copyLinkCommand(): void {
    let range: any = document.createRange();
    range.selectNode(this.userInput.nativeElement);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    document.execCommand('copy');
    window.getSelection()?.removeAllRanges();
  }

  // Not sure if this will work, still need to check
  public shareLink(): void {
    this.copyLinkCommand();
    window.open(this.inviteLink, '', 'toolbar=0,status=0,width=626, height=436');
  }

  public snowplowEventToggle(label: string): void {
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.click, label,
        '', '', this.context);
  }

  inviteParticipants() {
    this.generateSendToKey(this.inviteList);
    this.inviteReq = {
      send_to: JSON.stringify(this.guestList),
      invite_code: this.inviteCode
    }
    this.snowplowEventToggle(this.spTracker.labels.send);
    this.videoCallService.sendInitiation(this.inviteReq).subscribe(() => {
      this.toastr.success(this.translate.instant('inCall.invitesSent'));
      this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.screenShown,
        this.spTracker.labels.sentToast, JSON.stringify(this.guestList), 1, this.context);
      this.inviteFeedBack.emit();
    });
  }

  generateSendToKey(formValue: any) {
    formValue.forEach((guest: any) => {
      const obj: any = {
        link_type: 'guest',
      }
      if (guest?.value?.match(Constants.emailRegex)) {
        obj['email'] = guest.value;
      } else {
        const val = guest.value.replace(/[^0-9]/g, '');
        obj['phone'] = `+1${val}`;
      }
      this.guestList.push(obj);
    });
  }

  cancel() {
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.click,
        this.spTracker.labels.close, '', '', this.context);
    this.inviteFeedBack.emit();
  }

}
