<router-outlet></router-outlet>
<div class="optimy" #pluginDiv [ngClass]="[pluginPosition?.position? pluginPosition?.position : 'bottom-right']"
    [id]="isOnMobile() ? 'mobile': 'desktop'">
    <div class="app-window" [ngClass]="{'shadow' : !showLauncher, 'no-shadow' : isOutboundCall, 'french' : language === 'fr'}">
        <div class="optimy__plugin">
            <div class="op-d-flex launcher-router op-justify-content-end">
                <div [hidden]="!showAppWindow || hideFab">
                    <router-outlet name="plugin"></router-outlet>
                    <!-- TODO: this outlet still needs work -->
                    <router-outlet name="modal-outlet"></router-outlet>
                </div>
            </div>
            <div *ngIf="agentDetails && (checkFabHide() || showFabIcon || isOutboundCall)">
                <div class="launcher-banner-div" *ngIf="!hideFab && (showLauncher || isOutboundCall)">
                    <div class="launcher op-d-flex" [ngClass]="{'fab-with-title' : fabTitle}" *ngIf="!isCallWaiting">
                        <div class="fab-overlay" [@inOutAnimation] *ngIf="buttonList?.length"></div>
                        <a class="op-d-flex op-justify-content-center fab">
                            <button class="mobile-fab" [ngClass]="
                            {
                                'agent-joining': agentJoining,
                                'live-agent' : agentsOnline && !isOutboundCall,
                                'hide-logo' : fabCollapse,
                                'outbound' : isOutboundCall
                            }" [ngStyle]="{'border-color': agentDetails?.fab_config?.fab_border_color}" (click)="fabClick($event)">
                                <ng-container *ngIf="!isOutboundCall">
                                    <ng-container *ngIf="agentsOnline && !isCallWaiting">
                                        <img alt="fab-image" [src]="agentDetails.fab_config?.fab_image" class="fab-image">
                                    </ng-container>
                                    <ng-container *ngIf="!agentsOnline">
                                        <ng-container *ngIf="agentDetails.fab_config?.fab_image && !isCallWaiting">
                                            <img alt="fab-image" [src]="agentDetails.fab_config?.fab_image" class="fab-image">
                                        </ng-container>
                                        <ng-container *ngIf="!agentDetails.fab_config?.fab_image && isCallWaiting">
                                            <svg-icon class="fab-logo" name="mobile-fab"
                                                      [ngClass]="{'hide-logo' : fabCollapse}"></svg-icon>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <span class="outbound-reject op-common-icon" *ngIf="isOutboundCall"></span>
                            </button>
                            <span class="live-agent" *ngIf="agentsOnline && !isOutboundCall && !isCallWaiting">
                                {{ 'common.live' | translate}}
                            </span>
                        </a>
                        <div class="title-div" *ngIf="!isOutboundCall">
                            <span class="recruiter" *ngIf="fabTitle">{{fabTitle}}</span>
                        </div>
                        <div *ngIf="currentNudge" class="fab-nudge" [ngStyle]="{'width': currentNudge?.content[language].width+'px',
                         'max-height': currentNudge?.content[language].height + 'px'}"
                            [ngClass]="{'fadeIn' : openNudge}">
                            <div class="op-d-flex op-justify-content-end">
                                <button class="nudge-close" (click)="nudgeClose(false)">
                                    <svg-icon name="bubble-close"></svg-icon>
                                </button>
                            </div>
                            <app-nudge-text [nudgeContent]="nudgeContent" [currentNudge]="currentNudge" [language]="language" (nudgeClickEvent)="nudgeClick()"></app-nudge-text>
                        </div>
                        <div *ngIf="showNoAgentModal" class="message-schedule-modal op-d-flex op-flex-column op-align-items-end">
                            <span [innerHTML]="'noAgentModal.noAgent' | translate"></span>
                            <button class="op-btn op-d-flex op-align-items-center op-btn-primary op-mt-3" (click)="openBooking(); showNoAgentModal = false;">
                                <svg-icon name="schedule-live-session-calendar" class="calendar-icon op-mr-2"></svg-icon>
                                <span [innerHTML]="'noAgentModal.scheduleCall' | translate"></span>
                            </button>
                            <button class="op-btn op-d-flex op-align-items-center op-btn-primary op-mt-2" (click)="showLeaveMessageModal(); showNoAgentModal = false;">
                                <svg-icon name="chat-on-video-call" class="calendar-icon op-mr-2"></svg-icon>
                                <span [innerHTML]="'noAgentModal.leaveMessage' | translate"></span>
                            </button>
                            <button class="op-btn op-btn-primary op-mt-2" (click)="showNoAgentModal = false;"
                                    [innerHTML]="'common.cancel' | translate"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-call-status-screen *ngIf="expiredLink" [iconName]="'session-link-expired'"
            [callStatus]="'inCall.callSessionLinkExpired'" [message]="'inCall.linkExpiredMsg'"></app-call-status-screen>
    </div>
</div>

<optimy-modal [openModal]="openModal.asObservable()" [closeOnOutsideClick]="false"
    [closeModal]="closeModal.asObservable()">
    <app-schedule-appointment-edit-cancel *ngIf="showCancelBookingModal" [bookingStatusParams]="bookingStatus"
        (closeModal)="checkCloseModalEvent($event)"></app-schedule-appointment-edit-cancel>
    <app-leave-message-modal *ngIf="showLeaveMessage" [queueId]="queueId"
                             (closeModal)="checkCloseModalEvent($event)"></app-leave-message-modal>
</optimy-modal>
<app-cobrowse-alert *ngIf="languageObj"></app-cobrowse-alert>
