import { Component } from '@angular/core';
import { Constants } from '../../core/constants/constant';
import { environment } from '../../../environments/environment';
import { SnowplowService } from '../../core/services/snowplow/snowplow.service';
import { UtilsService } from '../../core/services/utils/utils.service';
import {
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels
} from '../../core/constants/trackerLabels';
import { EventKeys } from '../../core/constants/common.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  appVersion: string;
  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
  };

  constructor(private snowplowService: SnowplowService,
              private utils: UtilsService) {
    this.appVersion = environment.production ? Constants.pluginVersion :
      `${Constants.pluginVersion} - (${environment.appVersion.split('.')[2]})`;
  }

  public openOptimy(): void {
    const eventKeys: EventKeys = this.utils.checkAndSetEvent();
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.actions.optimy,
        eventKeys.label);
    window.open(Constants.optimyUrl, '_blank')
  }

  reportBug() {
    const eventKeys: EventKeys = this.utils.checkAndSetEvent();
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.actions.report,
        eventKeys.label);
  }
}
