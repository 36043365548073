import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AutoQueueInfoStates,
  CallDropOptions,
  PluginStates,
  RoomInfoStates
} from '../../../core/constants/common.enum';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';
import {
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels, SnowplowTrackerProperties
} from '../../../core/constants/trackerLabels';
@Component({
  selector: 'app-queue-form',
  templateUrl: './queue-form.component.html',
  styleUrls: ['./queue-form.component.scss']
})
export class QueueFormComponent implements OnInit{
  @Output() emitQueueFormNext = new EventEmitter();

  callDropOptions = CallDropOptions;
  language: string;
  queueForm: UntypedFormGroup;

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties
  };

  constructor(private formBuilder: UntypedFormBuilder,
              private snowplowService: SnowplowService,
              private utils: UtilsService) { }

  updateUserDetails() {
    let userInfo = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.userInfo);
    if (this.queueForm?.controls['phone']?.value) {
      userInfo = {
        ...userInfo,
        full_name :  this.queueForm?.controls['name']?.value,
        phone: this.queueForm?.controls['phone']?.value
      }
    } else {
      userInfo = {
        ...userInfo,
        full_name :  this.queueForm?.controls['name']?.value,
      }
    }
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.userInfo], [userInfo]);
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.actions.next,
        this.queueForm.get('callDropOpt')?.value === this.callDropOptions.yesTextMe ?
            this.spTracker.labels.contactFormPhone : this.spTracker.labels.contactFormNoPhone);
    this.emitQueueFormNext.emit();
  }

  ngOnInit() {
    this.language = this.utils.getLocalVal(PluginStates.language);
    const contactOptSelected = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.contactFormSelectionOpt);
    this.queueForm = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      callDropOpt:  new UntypedFormControl(contactOptSelected ? contactOptSelected : this.callDropOptions.yesTextMe, [Validators.required]),
      phone: new UntypedFormControl('', [Validators.required]),
    });
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.contactFormSelectionOpt], [this.callDropOptions.yesTextMe]);
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.screenShown,
        (contactOptSelected && contactOptSelected === this.callDropOptions.noThanks) ? this.spTracker.labels.contactFormNoPhone : this.spTracker.labels.contactFormPhone, this.spTracker.labels.version, 2);
  }

  setPhoneNumberValue(value: string){
    this.queueForm.get('phone')?.setValue(value);
  }

  optionChange() {
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.contactFormSelectionOpt], [this.queueForm.get('callDropOpt')?.value])
    if(this.queueForm.get('callDropOpt')?.value === this.callDropOptions.yesTextMe) {
      this.queueForm.get('phone')?.setValidators([Validators.required])
      this.queueForm.get('phone')?.updateValueAndValidity();
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.actions.text,
          this.spTracker.labels.contactFormPhone, this.spTracker.properties.textMe, 0);
    } else {
      this.queueForm.get('phone')?.clearValidators();
      this.queueForm.get('phone')?.updateValueAndValidity();
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.actions.text,
          this.spTracker.labels.contactFormNoPhone, this.spTracker.properties.textMe, 1);
    }
  }

}
