<div class="call-status-screen-container">
    <div class="op-d-flex op-align-items-center header">
        <svg-icon name="mobile-fab" class="chat-icon op-mr-3">
        </svg-icon>
        <label [innerHTML]="'common.liveVideoSupport' | translate" class="live-support"></label>
    </div>
    <div class="call-status-container op-d-flex op-flex-column">
        <svg-icon name="{{iconName}}" class="op-d-flex op-justify-content-center op-mt-5"></svg-icon>
        <div class="op-d-flex op-flex-column op-mt-3 op-mb-4">
            <span class="info op-mb-3" [innerHTML]="callStatus | translate"></span>
            <span class="message" [innerHTML]="message | translate"></span>
        </div>
    </div>
    <div *ngIf="waitingTime" class="op-d-flex op-justify-content-center op-mt-4 op-mb-4 loading-icon"><object
            type="image/svg+xml"
            data="https://dx9457ojp328v.cloudfront.net/icons/waiting-animated-three-dots.svg"></object>
    </div>
    <div class="op-d-flex op-mb-4">
        <button class="op-btn op-btn-flat op-btn-primary op-flex-1" *ngIf="!hideButton" [innerHTML]="'common.close' | translate"
            (click)="close()"></button>
    </div>
</div>