import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptimyModalComponent } from './components/optimy-modal/optimy-modal.component';
import { AddSnowplowEventDirective } from './directives/addSnowplowEvent/add-snowplow-event.directive';
import { IntelTellInputComponent } from '../../components/intel-tell-input/intel-tell-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

const components = [
  OptimyModalComponent,
  AddSnowplowEventDirective,
  IntelTellInputComponent
];


@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    ...components,
  ]
})
export class SharedModule { }
