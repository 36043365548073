import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LeaveMessageComponent } from '../leave-message/leave-message.component';
import { SharedService } from '../../../core/services/shared/shared.service';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { Constants } from '../../../core/constants/constant';
import { AgentResponseData } from '../../../core/constants/agent.modal';
import {
  ActiveScreen, AutoQueueInfoStates, EventKeys,
  PluginStates,
  RoomInfoStates,
  RoutesUrls
} from '../../../core/constants/common.enum';
import {
  SnowplowTrackerAction,
  SnowplowTrackerCategories, SnowplowTrackerLabels,
} from '../../../core/constants/trackerLabels';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';


@Component({
  selector: 'app-queue-header',
  templateUrl: './queue-header.component.html',
  styleUrls: ['./queue-header.component.scss']
})
export class QueueHeaderComponent {
  @ViewChild('leaveMessageCmp') leaveMessageCmp: LeaveMessageComponent;
  @Input() agentStatusInfo: AgentResponseData;
  @Input() introScreen: boolean = true;
  @Output() emitHeaderAction = new EventEmitter();

  activeScreen:string;
  activeScreenTemp = ActiveScreen;
  showCloseModal = false;

  queuePositionId: number;

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
  };

  constructor(private router: Router,
              private sharedService: SharedService,
              private snowplowService: SnowplowService,
              private utils: UtilsService) {
  }

  initiateClose() {
    this.activeScreen = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.activeScreenForHeader)
    if (this.activeScreen && !this.showCloseModal) {
      this.showCloseModal = true;
      if(this.activeScreen === this.activeScreenTemp.waitingScreen) {
        this.queuePositionId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
        this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue, this.spTracker.labels.screenShown,
            this.spTracker.labels.mainQueueLeave, this.spTracker.labels.version, 2);
      }
    } else {
      this.closeIntermediate();
    }
  }
  closeIntermediate() {
    const guestToken = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestToken);
    if (guestToken) {
      this.removeFromQueue(true)
    } else {
      this.submitTrackingEvent();
      this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.autoQueueInfo]);
      this.showCloseModal = false;
      this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }], {skipLocationChange: true});
    }
  }

  removeFromQueue(isNo: boolean) {
    if (isNo) {
      this.submitTrackingEvent();
    }
    this.showCloseModal = false;
    this.emitHeaderAction.emit(isNo);
  }

  submitTrackingEvent() {
    const eventKeys: EventKeys =  this.utils.checkAndSetEvent();
    if(eventKeys?.property) {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.close,
          eventKeys.label, eventKeys.property);
    } else {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.close,
          eventKeys.label);
    }
  }

}
