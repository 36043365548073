import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AgentResponseData } from '../../constants/agent.modal';
import { DeviceStatus, FabClickState, PermissionError } from '../../constants/call.modal';
import { RoomCreatedData } from '../../constants/common.enum';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  showLauncher$ = new Subject<boolean>();
  disconnectCall$ = new Subject();
  isMinimized$ = new Subject<boolean>();
  timeLeft$ = new BehaviorSubject<number | any>(null);
  checkState$ = new Subject<boolean>();
  showBookingAndCheckPreviousState$ = new Subject<boolean>();
  closeBookingModal$ = new Subject<boolean>();
  hideFab$ = new Subject<boolean>();
  outboundCall$ = new Subject<boolean>();
  closeCall$ = new Subject<boolean>();
  hideHeaderAndFooter$ = new Subject<boolean>();
  updatedAgentInfo$ = new Subject<AgentResponseData>();
  rejectOutboundCall$ = new Subject<boolean>();
  displayMinimize$ =  new Subject<boolean>();
  enableDevices$ = new Subject<DeviceStatus>();
  showPermissionError$ = new Subject<PermissionError>();
  agentJoiningCall$ =  new Subject<boolean>();
  userJoinedQueue$ =  new Subject<boolean>();
  executeFabAction$ =  new Subject<string>();
  fabOpenAction$ = new Subject<FabClickState>();
  agentJoiningVideoCall$ = new Subject<RoomCreatedData>();
  enteredRoutingState$ = new Subject<boolean>();
  joinedTheQueue$ = new Subject<boolean>();

  constructor() {
  }

  showAppLauncher(value: boolean) {
    this.showLauncher$.next(value);
  }

  dropCall() {
    this.disconnectCall$.next(true);
  }

  minimizeApp(val: boolean) {
    this.isMinimized$.next(val);
  }

  setTimeLeft(val: number) {
    this.timeLeft$.next(val);
  }

  checkCurrentState(value: boolean) {
    this.checkState$.next(value);
  }

  showBookingAndCheckPreviousState(value: boolean) {
    this.showBookingAndCheckPreviousState$.next(value);
  }

  closeBookingModal(value: boolean) {
    this.closeBookingModal$.next(value);
  }

  hideFab(value: boolean) {
    this.hideFab$.next(value);
  }

  updatedAgentInfo(agentInfo: AgentResponseData) {
    this.updatedAgentInfo$.next(agentInfo);
  }

  sendOutboundCall(value: boolean) {
    this.outboundCall$.next(value);
  }

  closeCall() {
    this.closeCall$.next(true);
  }

  hideHeaderAndFooter(value: boolean) {
    this.hideHeaderAndFooter$.next(value);
  }

  enableDevices(deviceStatus: DeviceStatus) {
    this.enableDevices$.next(deviceStatus);
  }

  showPermissionError(permissionError: PermissionError) {
    this.showPermissionError$.next(permissionError);
  }

  rejectOutboundCall(value: boolean) {
    this.rejectOutboundCall$.next(value);
  }

  displayMinimizeIcon(value: boolean) {
    this.displayMinimize$.next(value);
  }

  userJoinedQueue(value: boolean) {
    this.userJoinedQueue$.next(value);
  }

  executeFabAction(value: string) {
    this.executeFabAction$.next(value);
  }

  fabOpenAction(value: FabClickState) {
    this.fabOpenAction$.next(value);
  }

  enteredRouting(state: boolean) {
    this.enteredRoutingState$.next(state);
  }

  joinedTheQueue(state: boolean) {
    this.joinedTheQueue$.next(state);
  }
}
