import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, interval, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  GtmTrackers,
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels,
  SnowplowTrackerProperties
} from 'src/app/core/constants/trackerLabels';
import { ScreenOrientationService } from 'src/app/core/services/utils/screen-orientation.service';
import {
  connect,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalParticipant,
  RemoteParticipant,
  RemoteVideoTrack,
  RemoteVideoTrackPublication,
  Room
} from 'twilio-video';
import { AgentResponseData, AgentStatus , PluginPosition} from '../../../core/constants/agent.modal';
import {
  CallStates,
  CallStatus,
  EventTypes,
  MicCameraPermission, RouteSate,
  ScreenShareType
} from '../../../core/constants/call.modal';
import {
  ConversationWebHookMessage,
  MessageCreateReq, MessageType,
  MsgUserType,
  TypingInfo
} from '../../../core/constants/chat.modal';
import {
  CallInfo,
  CallInfoStates, Context,
  DeviceInfo,
  DeviceInfoStates,
  GuestStatus,
  OutboundAcceptRes,
  PluginStates, RoomCreatedData,
  RoomInfo,
  RoomInfoStates,
  RoutesUrls,
  UserInfo
} from '../../../core/constants/common.enum';
import { Constants } from '../../../core/constants/constant';
import { ChatService } from '../../../core/services/chat/chat.service';
import { RecordingService } from '../../../core/services/recording/recording.service';
import { ResizeService } from '../../../core/services/resize/resize.service';
import { SharedService } from '../../../core/services/shared/shared.service';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';
import { SocketService } from '../../../core/services/socket/socket.service';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { VideoCallService } from '../../../core/services/video-call/video-call.service';
import { Participant } from '../video-call.types';
import { InviteCodeParams } from '../../../core/constants/common.enum';
import { Menu } from './menu/menu';
import { ANIMATION_TIME, DELAYED_ANIMATION_TIME } from './types';
import { LocalVideoTrack } from 'twilio-video/tsdef/LocalVideoTrack';
import { LocalAudioTrack } from 'twilio-video/tsdef/LocalAudioTrack';
import * as moment from 'moment';
import { GtmService } from '../../../core/services/gtm/gtm.service';

let appHeight = document.documentElement.style.cssText;
appHeight = appHeight.substring(appHeight.indexOf('--app') + 1, appHeight.lastIndexOf('px;')).split(':')[1];

enum CobrowseActions {
  grant = 'grant',
  declined = 'declined',
  close = 'close',
  terms = 'terms',
  screenShown = 'screen_shown'
}

@Component({
  selector: 'app-in-call',
  templateUrl: './in-call.component.html',
  styleUrls: ['./in-call.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        animate(`${DELAYED_ANIMATION_TIME}`, keyframes([
          style({ opacity: 0.5 }),
          style({ opacity: 1 }),
        ]))
      ]),
      transition(':leave', [
        animate(`${ANIMATION_TIME}`, keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 }),
        ]))
      ])
    ]),
    trigger('fadeAnimationActionButtons', [
      transition(':enter', [
        animate(`${DELAYED_ANIMATION_TIME}`, keyframes([
          style({ opacity: 0, offset: 0 }),
          style({ opacity: 0, offset: 0.9 }),
          style({ opacity: 1, offset: 1 }),
        ]))
      ]),
      transition(':leave', [
        animate(`${ANIMATION_TIME}`, keyframes([
          style({ opacity: 1, offset: 0 }),
          style({ opacity: 0, offset: 0.1 }),
          style({ opacity: 0, offset: 1 }),
        ]))
      ])
    ])
  ]
})
export class InCallComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() roomName: string;
  @Input() guestToken: string;
  @Input() isOutboundCall: boolean;


  @Output() outboundCallAnswer: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('local') elLocal: ElementRef;
  @ViewChild('agentVideoContainer') agentVideoContainer: ElementRef;
  @ViewChild('agentScreenShare') agentScreenShare: ElementRef;
  @ViewChild('guestVideoContainer') guestVideoContainer: ElementRef;
  @ViewChild('videoDiv') videoDiv: ElementRef;
  @ViewChild('mainVideoContainer') mainVideoContainer: ElementRef;

  isUserInQueue: boolean;

  pluginPosition: PluginPosition;
  localGuestIdentity: string;
  showInviteForm = false;

  showIntermediate = false;

  isChatOpen: boolean = true;
  showNotification = false;
  isOffline = true;
  userInfo: UserInfo;
  public isCameraOn = false;
  isMicOn = false;
  agentDetails: AgentResponseData;
  time: number = 0;
  interval: any;
  roomObj: Room | undefined;
  videoDevices: any;
  audioDevices: any;
  callDuration: string;
  roomInfo: RoomInfo;
  guestUserId: string;
  showCamError = false;
  showMicError = false;
  showReload = false;
  showSettingsModal = false;
  deviceInfo: DeviceInfo;
  audioDeviceId: string;
  videoDeviceId: string;
  audioOutDeviceId: string;
  membersOnCall: number = 0;
  agentsOnCall: number = 0;
  unreadMessageCount: number = 0;
  deviceList: MediaDeviceInfo[];
  agentVideoReady = false

  hasGrantedMicPermissionOnce: boolean = false;
  hasGrantedCameraPermissionOnce: boolean = false;
  showPermissionPopup = false;
  deviceName: string;
  disableNotNow = false;
  showReplyButton: boolean;
  isFirstInitiated: boolean;
  showMobileChat: boolean;

  sidsInTheCall: Set<string> = new Set();
  participantDivList: { [key in string]: HTMLElement } = {};
  callState = 'normalView'
  leaveCallButtonClicked = false;
  lastCallState: string;
  previousCallState: string = CallStates.default;
  newMessage: {
    body: string;
    author: string;
    index: string;
  } | null;

  public participants: Participant[];

  isLandscape = this.screenOrientationService.isLandscapeAndMobile;

  currentCategory: string;
  context: Context[];

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties
  };

  public maximize = false;
  public minimize = false;
  public isMenuOpen = false;
  public menuList: Menu[];
  maximizeFull = false;
  isMobile = false;
  showMaximizeActions = false;
  disableParticipantClick = false;

  currentTypingUsers: string[] = [];
  videoStream: LocalVideoTrack;
  audioStream: LocalAudioTrack;

  agentOnTheVideoCall = false;
  totalNumberOfAgents: Participant[];
  agentName: any;

  destroy$: Subject<boolean> = new Subject<boolean>();
  openModalSub: Subject<void> = new Subject<void>();
  closeModalSub: Subject<void> = new Subject<void>();

  constructor(private utils: UtilsService,
    private videoCallService: VideoCallService,
    private resizeService: ResizeService,
    private sharedService: SharedService,
    private socketService: SocketService,
    private chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private translate: TranslateService,
    private snowplowService: SnowplowService,
    private recordingService: RecordingService,
    private screenOrientationService: ScreenOrientationService,
    private gtmService: GtmService) {
    this.socketService.messageData
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: ConversationWebHookMessage) => {
          if (this.roomName === data.ConversationSid &&
              (data.EventType === EventTypes.messageAdded || data.EventType === EventTypes.messageUpdated)
              && data?.Author !== this.guestUserId) {
            this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
            if (typeof data.Attributes === 'string') {
              data.Attributes = JSON.parse(data.Attributes);
            }
            const lastIndex = data?.Attributes?.index;
            this.unreadMessageCount = ((lastIndex ?? 0) - this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.unConsumedIndex));
            this.getTotalMessageCount(this.isChatOpen);
            this.getAdditionalContextForJoinQueueQuestion(this.roomInfo?.queuePositionId);
            if (!this.isChatOpen) {
              this.utils.playAlertAudio();
              this.showNotification = true;
              this.newMessage = {
                body: data.Body,
                author: this.chatService.getParticipantName(data.Author),
                index: data.Index
              }
              this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.actions.shown,
                `${this.spTracker.labels.message}${data.Index}`, document.hidden ? this.spTracker.properties.guestInactive :
                this.spTracker.properties.guestActive, this.roomInfo.queuePositionId, this.context);
            } else {
              this.utils.playAlertAudio();
              if (data?.Attributes?.custom_message?.response_value) {
                this.snowplowService.trackStructEvent(this.currentCategory, data.Attributes.custom_message.response_value,
                  `${this.spTracker.labels.message}${data.Index}`, data.Attributes.custom_message.event_key,
                  this.roomInfo.queuePositionId, this.context);
              } else {
                this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.actions.shown,
                  `${this.spTracker.labels.message}${data.Index}`, document.hidden ? this.spTracker.properties.guestInactive :
                  this.spTracker.properties.guestActive, this.roomInfo.queuePositionId, this.context);
                if (data.Author === 'concierge') {
                  this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.actions.shown,
                    `${this.spTracker.labels.message}${data.Index}`, data?.Attributes?.custom_message?.event_key || '',
                    this.roomInfo.queuePositionId, this.context);
                }
              }
            }
          }
        }
      });
    this.socketService.roomClose
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          if (this.roomName === data.channelSid) {
            const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
            const callInfo = this.utils.getLocalVal(PluginStates.callInfo);
            if ((callInfo?.hasOwnProperty(CallInfoStates.isCobrowseHidden) && !callInfo?.isCobrowseHidden)) {
              this.utils.setSessionVal([RoomInfoStates.guestId], [roomInfo?.guestId]);
              this.utils.setSessionVal([RoomInfoStates.roomName], [roomInfo?.roomName]);
            }
            this.utils.setSessionStorage();
            this.utils.checkAndSetGuestStatus(GuestStatus.available, this.guestToken);
            this.endCall();
            if (roomInfo && !roomInfo.isOutboundCall) {
              this.gtmService.sendGtm(GtmTrackers.optimyCallEnded);
              this.utils.checkStatusAndRedirect();
            } else {
              if (roomInfo?.isOutboundCall) {
                this.gtmService.sendGtm(GtmTrackers.optimyOutboundCancelled);
              }
              this.utils.removeSessionStorage();
              this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }], { skipLocationChange: true});
            }
            this.utils.removeSessionStoreVal(Constants.optimySessionStore, [PluginStates.callStatus]);
            this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.outboundFirstInitiated]);
          }
        }
      });

    this.socketService.typingInfo
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: (data: TypingInfo) => {
            this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
            if (data.identity !== this.guestUserId && this.roomName === data.channelId) {
              if (data.isTyping && (!this.currentTypingUsers?.length || this.currentTypingUsers?.indexOf(data.identity) === -1)) {
                this.currentTypingUsers.push(data.identity);
              } else if (!data.isTyping) {
                const i = this.currentTypingUsers.indexOf(data.identity);
                this.currentTypingUsers.splice(i, 1);
              }
            }
          }
        });

    this.socketService.outboundAccepted
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: OutboundAcceptRes) => {
          if (data?.room_name === this.roomName) {
            this.disableNotNow = true;
          }
        }
      });
    this.sharedService.rejectOutboundCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: boolean) => {
          if (data) {
            this.declineOutboundCall();
          }
        }
      });

    this.sharedService.agentJoiningCall$
        .pipe(takeUntil(this.destroy$))
        .subscribe((agentJoiningCall: boolean) => {
          if (agentJoiningCall) {
            this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.roomStatus, RoomInfoStates.routeState],
                [CallStatus.in_progress, RouteSate.onGoing]);
            this.utils.checkAndSetGuestStatus(GuestStatus.inCall, this.guestToken);
            this.updateParticipantList();
          }
        });

    this.sharedService.agentJoiningVideoCall$
        .pipe(takeUntil(this.destroy$))
        .subscribe((agentJoiningVideoCall: RoomCreatedData) => {
          if (agentJoiningVideoCall?.starting) {
            this.updateParticipantList().then(()=>{
              this.agentOnTheVideoCall = true;
              this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.agentOnTheVideoCall], [this.agentOnTheVideoCall]);
              this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.isVideoCallStarted], [true]);
                this.selectRoom();
            });
          } else {
            if(this.isMicOn) {
              this.toggleMicrophone(!this.isMicOn)
            }
            if(this.isCameraOn) {
              this.toggleCamera(!this.isCameraOn)
            }
            this.agentOnTheVideoCall = false;
            this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.agentOnTheVideoCall], [this.agentOnTheVideoCall]);
          }
        });

    this.socketService.memberUpdatedSocket
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => {
            if (this.roomName === data.channelSid) {
                this.updateParticipantList();
            }
          }
        });

    this.sharedService.disconnectCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.endCall();
      });


    this.sharedService.closeCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const memberSid = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.memberSid);
        this.disconnectCall(memberSid);
      });

    // Giving to the window object access to the function and the snowplow service.
    (window as any).cobrowseFunction = this.cobrowseFunction;
    (window as any).snowplowService = this.snowplowService;
  }

  // This function is called outside of the Angular context, the 'this' will not work here.
  private cobrowseFunction(status: CobrowseActions): void {
    const isOnMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const isLandscape = !window.matchMedia('(orientation: portrait)').matches && isOnMobile;
    const category = isLandscape ? 'call_cobrowse_landscape' : 'call_cobrowse';
    if (status === CobrowseActions.screenShown) {
      (window as any).snowplowService.trackStructEvent(category, status, 'request_screen', 'version', 1, this.context);
    } else {
      (window as any).snowplowService.trackStructEvent(category, status, 'request_screen', 'click', '', []);
    }
  }

  public minimizePlugin(): void {
    this.showNotification = false;
    this.resizeService.resizeSub.next({
      isMinimized: true,
      isMaximized: false
    });
  }

  private subscribeToResizeSub(): void {
    this.resizeService.resizeSub.pipe(takeUntil(this.destroy$)).subscribe((resp) => {
      if (!this.isOutboundCall) {
        this.maximize = !!resp.isMaximized;
        this.minimize = !!resp.isMinimized;
        if (this.minimize) {
          this.leaveCallButtonClicked = false;
          this.isChatOpen = false;
          this.previousCallState = this.lastCallState;
          this.lastCallState = CallStates.minimize;
          this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastCallState], [CallStates.minimize]);
          this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.chat], [this.isChatOpen]);
        } else if (this.maximize) {
          this.isChatOpen = false;
          this.previousCallState = this.lastCallState;
          this.lastCallState = this.maximizeFull ? CallStates.maximizeFull : CallStates.maximize;
          this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastCallState],
            [this.maximizeFull ? CallStates.maximizeFull : CallStates.maximize]);
        } else {
          this.previousCallState = this.lastCallState;
          this.lastCallState = CallStates.default;
          this.isChatOpen = true;
          this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastCallState], [CallStates.default]);
          this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.chat], [this.isChatOpen]);

        }
      }
      this.updateLandscapeInformation();
      this.snowplowService.trackStructEvent(this.currentCategory,
        this.spTracker.labels.screenShown, this.spTracker.labels.genericScreen, this.spTracker.labels.version,
        this.maximize ? 1 : (this.minimize ? 2 : (this.currentCategory === this.spTracker.categories.callChat && !this.isMobile ? 4 : 3)), this.context);

    });
  }

  public toggleMenu(menuToggled: { isMenuOpen: boolean, isInternalCommand: boolean }): void {
    if (!menuToggled.isInternalCommand) {
      if (menuToggled.isMenuOpen) {
        this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.open,
          this.spTracker.labels.overflow, '', '', this.context);
      } else {
        this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.close,
          this.spTracker.labels.overflow, '', '', this.context);
      }
    }
    this.isMenuOpen = menuToggled.isMenuOpen;
  }

  ngOnInit(): void {
    const currentLocale = this.utils.checkAndGetCurrentLang();
    moment.locale(currentLocale);
    this.agentOnTheVideoCall = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.agentOnTheVideoCall) || false;
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.pluginPosition = this.agentDetails?.fab_config?.position_config;
    this.localGuestIdentity = this.translate.instant('common.you');
    this.context = this.utils.getCallSchemaContext();
    this.isUserInQueue = true;
    this.isMobile = this.screenOrientationService.isOnMobile();
    this.updateLandscapeInformation();
    this.videoCallService.inCall$.next({ inCall: true });
    this.isFirstInitiated = this.utils.getLocalVal(PluginStates.outboundFirstInitiated);
    this.subscribeToResizeSub();
    this.createMenu();
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    this.userInfo = this.roomInfo?.userInfo;
    this.guestUserId = this.roomInfo?.guestId;
    const callInfo: CallInfo = this.utils.getLocalVal(PluginStates.callInfo);
    if (callInfo) {
      this.isCameraOn = callInfo.video;
      this.isMicOn = callInfo.audio;
    }
    this.deviceInfo = this.utils.getLocalVal(PluginStates.deviceInfo);
    this.audioDeviceId = this.deviceInfo?.audioInDevice;
    this.videoDeviceId = this.deviceInfo?.videoInDevice;
    this.audioOutDeviceId = this.deviceInfo?.audiOutDevice;
    this.lastCallState = this.roomInfo?.lastCallState || CallStates.default;
    this.setCallStates(callInfo);
    this.setCamMic()
    this.setOnMobileAndOutbound();
    this._checkAndConnectCall();

    this.screenOrientationService.orientationChanged$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateLandscapeInformation();
      });
  }

  ngAfterViewInit() {
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    if (this.isUserInQueue && (this.roomInfo?.roomStatus !== CallStatus.in_progress)) {
      this._checkLastState();
    }
    this.removeParametersFromUrl();
  }

  private removeParametersFromUrl() {
    const url = location.href;
    let urlArray = url.split(InviteCodeParams.optimyCode);
    let newUrl;
    if (urlArray.length > 1) {
      newUrl = urlArray[0];
    } else {
      urlArray = url.split(InviteCodeParams.code);
      newUrl = urlArray[0];
    }
    if (newUrl) {
      window.history.pushState('','',newUrl);
    }
  }

  private _checkAndConnectCall(): void {
    if (this.roomInfo.roomStatus === CallStatus.in_progress) {
      const isVideoRoomCreated = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.isVideoCallStarted);
      this.updateParticipantList().then(()=>{
        if(isVideoRoomCreated){
          this.selectRoom();
        }
      });
    }
  }

  getTotalMessageCount(openChat: boolean) {
    if (!this.roomName) return;
    this.chatService.getChatMessage(this.roomName).subscribe((res: any) => {
      const unconsumedIndex = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.unConsumedIndex);
      this.unreadMessageCount = res?.message[res?.message?.length - 1]?.attributes.index - unconsumedIndex;
      this.showNotification = openChat ? false : this.unreadMessageCount > 0;
      if (openChat) {
        this.isChatOpen = openChat;
        this.newMessage = null;
        this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.chat], [this.isChatOpen]);
      }
    });
  }

  getAdditionalContextForJoinQueueQuestion(queueId?: number){
    this.context = this.utils.getCallSchemaContext(queueId);
    if(this.currentCategory?.includes('waiting')){
      let additionalContext = this.utils.getJoinQueueQuestionSchema();
      if(additionalContext){
        this.context = [...this.context, additionalContext]
      }
    }
  }

  updateUnreadCount(count: number) {
    this.unreadMessageCount = count;
    this.utils.updateBrowserTitle(false, true);
  }

  setCallStates(callInfo: CallInfo) {
    if (this.lastCallState === CallStates.minimize) {
      this.minimize = true;
      this.maximize = false;
      this.minimizePlugin();
    } else if (this.lastCallState === CallStates.maximize || this.lastCallState === CallStates.maximizeFull) {
      this.minimize = false;
      this.maximize = true;
      this.isChatOpen = false;
      this.maximizeFull = this.lastCallState === CallStates.maximizeFull;
    } else if (this.lastCallState === CallStates.default) {
      this.minimize = false;
      this.maximize = false;
      this.getTotalMessageCount(('chat' in callInfo) ? callInfo?.chat : true);
    }
  }

  setCamMic() {
    if (this.isMicOn) {
      this.startMic(this.audioDeviceId);
    }
    if (this.isCameraOn) {
      this.startLocalVideo(this.videoDeviceId);
    }
  }

  private updateLandscapeInformation(): void {
    if (this.screenOrientationService.isLandscapeAndMobile) {
      this.isLandscape = true;
      if (this.isUserInQueue) {
        this.currentCategory = this.maximize ? this.spTracker.categories.callFullWaitingLandscape :
          (this.minimize ? this.spTracker.categories.callMiniWaitingLandscape : this.spTracker.categories.callChatWaitingLandscape);
      } else {
        this.currentCategory = this.maximize ? this.spTracker.categories.callFullLandscape :
          (this.minimize ? this.spTracker.categories.callMiniLandscape : this.spTracker.categories.callChatLandscape);
      }
    } else {
      this.isLandscape = false;
      if (this.isUserInQueue) {
        this.currentCategory = this.maximize ? this.spTracker.categories.callFullWaiting :
          (this.minimize ? this.spTracker.categories.callMiniWaiting : this.spTracker.categories.callChatWaiting);
      } else {
        this.currentCategory = this.maximize ? this.spTracker.categories.callFull :
          (this.minimize ? this.spTracker.categories.callMini : this.spTracker.categories.callChat);
      }
    }
    this.getAdditionalContextForJoinQueueQuestion();
  }


  executePluginInsideClick(event: any) {
    if (!this.isChatOpen && !this.maximize) {
      if (event) {
        this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.click,
          this.spTracker.labels.genericScreen, '', '', this.context);
      }
      this.getTotalMessageCount(true);
      this.resizeService.resizeSub.next({
        isMinimized: false,
        isMaximized: false,
        isDefault: true
      });
      this.showNotification = false;
      this.newMessage = null;
    } else if (this.maximize || this.isChatOpen) {
      if (this.isChatOpen) {
        this.resizeService.resizeSub.next({
          isMinimized: false,
          isMaximized: true,
          isDefault: false
        });
      }
      if (event.target?.classList.contains('participant') && !event.target?.classList.contains('maximizedParticipant')) {
        const userId = event?.target?.getAttribute('data-user');
        this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.click,
          this.spTracker.labels.avatar, this.spTracker.properties.userId, userId, this.context);
        this.animateTheParticipantDiv(event.target?.id);
      }
    }
  }

  animateTheParticipantDiv(id: string) {
    this.showMaximizeActions = false;
    if (!this.disableParticipantClick) {
      this.disableParticipantClick = true;
      const selectedParticipantId = id;
      const elem = this.mainVideoContainer.nativeElement.querySelector(`#${selectedParticipantId}`);
      let parentPos = this.videoDiv.nativeElement.getBoundingClientRect(),
        childPos = elem.getBoundingClientRect(),
        relativePos: any = {};
      relativePos.right = parentPos.right - childPos.right;
      relativePos.bottom = parentPos.bottom - childPos.bottom;
      const currentParticipant = this.mainVideoContainer.nativeElement.querySelectorAll('.maximizedParticipant');
      const fullscreenParticipant = this.mainVideoContainer.nativeElement.querySelector(`#${selectedParticipantId}`);
      if (currentParticipant.length) {
        currentParticipant.forEach((participant: any) => {
          participant.style.cssText += 'position: relative;bottom: unset; right: unset';
          participant?.classList.remove('maximizedParticipant');
        })
      }
      if (fullscreenParticipant) {
        fullscreenParticipant.style.cssText += `position: absolute;bottom: 48px; right: ${relativePos.right - 16}px`;
        setTimeout(() => {
          fullscreenParticipant.classList.add('maximizedParticipant');
          fullscreenParticipant.style.cssText += 'position: absolute;bottom: 0; right: 0';
        }, 50)
      }
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastParticipantInFull], [selectedParticipantId])
      setTimeout(() => {
        this.showMaximizeActions = true;
        this.disableParticipantClick = false;
      }, 1000)
    }
  }

  closeNewMessage(event: Event) {
    event.stopPropagation();
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.close,
      `${this.spTracker.labels.message}${this.newMessage?.index}`, document.hidden ? this.spTracker.properties.guestInactive :
      this.spTracker.properties.guestActive, this.roomInfo.queuePositionId, this.context)
    this.newMessage = null;
  }

  previewClick() {
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.click,
      `${this.spTracker.labels.message}${this.newMessage?.index}`, document.hidden ? this.spTracker.properties.guestInactive :
      this.spTracker.properties.guestActive, this.roomInfo.queuePositionId, this.context);
    this.newMessage = null;
    if (this.minimize) {
      this.executePluginInsideClick(null);
    } else {
      this.toggleFullScreen(false);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  public acceptOutboundCall(): void {
    this.gtmService.sendGtm(GtmTrackers.optimyOutboundAccepted);
    this.isChatOpen = true;
    this.isOutboundCall = false;
    this.outboundCallAnswer.emit(true);
    this.removeAttributeFromRemoteAgent();
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.screenShown,
      this.spTracker.labels.genericScreen, this.spTracker.labels.version, 1, this.context);
  }

  public declineOutboundCall(): void {
    this.isOutboundCall = false;
    this.isOffline = true;
    this.updateMember('', true);
    this.outboundCallAnswer.emit(false);
    this.removeAttributeFromRemoteAgent();
  }

  removeAttributeFromRemoteAgent() {
    this.agentVideoContainer.nativeElement.removeAttribute('agent-live');
  }

  showChatForMobile() {
    this.getTotalMessageCount(false)
    if (!this.isFirstInitiated) {
      interval(2000).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.showMobileChat = true;
      });
    } else {
      this.showMobileChat = true;
    }
  }

  selectRoom() {
    this.isOffline = false;
    this.updateMember();
    this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
    if (this.guestUserId) {
      this.videoCallService.getToken(this.guestUserId).subscribe((token: string) => {
        this.joinRoom(token);
        navigator.mediaDevices.addEventListener('devicechange', () => {
          this.changeDeviceToDefault();
        });
      });
    } else {
      setTimeout(() => {
        this.selectRoom();
      }, 1000);
    }
  }

  joinRoom(token: string) {
    this.utils.setSessionVal([PluginStates.callStatus], [true]);
    this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.audio, CallInfoStates.video, CallInfoStates.chat],
        [this.isMicOn, this.isCameraOn, this.isChatOpen]);
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.roomName], [this.roomName]);
    connect(token, {
      name: this.roomName, audio: false,
      video: false,
      dominantSpeaker: true
    }).then((room: Room) => {
      this.isUserInQueue = false;
      this.updateLandscapeInformation();
      this.roomObj = room;
      this.startTimer();
      this.setCoBrowseCustomData(false);
      if (this.isMicOn) {
        this.startMic(this.audioDeviceId);
      }
      if (this.isCameraOn) {
        this.startLocalVideo(this.videoDeviceId);
      }
      this.elLocal?.nativeElement.setAttribute('data-user', this.guestUserId);

      room.participants.forEach((participant: RemoteParticipant) => {
        this.publishTrack(participant);
      });
      room.on('participantConnected', (participant: RemoteParticipant) => {
          this.publishTrack(participant);
      });
      room.on('dominantSpeakerChanged', (participant: RemoteParticipant) => {
        this.setDominantSpeaker(participant);
      });
    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  }

  private setDominantSpeaker(participant: RemoteParticipant): void {
    if (!participant) return;
    this.sidsInTheCall.forEach((identity: string) => {
      const videoEl = this.participantDivList[identity];
      videoEl.className = videoEl?.className.replace('dominant-speaker', '');
      const participantIdentity = ((participant.identity as string).startsWith('guest')) ? participant.identity : `_${participant.identity.split('_')[0]}`;
      if (identity === participantIdentity) {
        videoEl.className += ' dominant-speaker';

        this.removeDominantSpeakerClasses();
        if (videoEl.className.includes('agent')) {
          this.agentVideoContainer.nativeElement.className += ' dominant-speaker';
        } else {
          this.guestVideoContainer.nativeElement.className += ' dominant-speaker';
        }
      }
    })
  }

  private removeDominantSpeakerClasses(): void {
    this.guestVideoContainer.nativeElement.className = this.guestVideoContainer.nativeElement.className.replace('dominant-speaker', '');
    this.agentVideoContainer.nativeElement.className = this.agentVideoContainer.nativeElement.className.replace('dominant-speaker', '');
  }

   participantConnected(participant: any, isJoinRoom?: boolean){
    const { identity } = participant;
    this.sidsInTheCall.add(identity);
     const participantDiv = this.getParticipantDiv(identity);
     this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
    if (!participantDiv && identity !== this.guestUserId) {
      this.checkParticipantHasAnotherDiv(participant);
      if(isJoinRoom){
        this.membersOnCall++;
      }
    } else {
      this.participantDivList[identity] = participantDiv;
    }
  }

  getParticipantDiv(identity: string){
    return (identity as string).startsWith('guest') ? this.videoDiv.nativeElement.querySelector(identity) :
        this.videoDiv.nativeElement.querySelector(`#_${identity}`)
  }

  publishTrack(participant: any){
    if (participant.tracks) {
      participant.tracks.forEach((publication: any) => {
        this.trackPublished(publication, participant);
      });
    }
    participant.on('trackPublished', (publication: RemoteVideoTrackPublication) => {
      this.trackPublished(publication, participant);
    });
  }

  checkParticipantHasAnotherDiv(participant: RemoteParticipant | LocalParticipant | any) {
    const { identity } = participant;
    const participantDiv = this.videoDiv.nativeElement.querySelector(`[data-user="${identity}"]`);
    if (participantDiv && identity !== this.guestUserId) {
      participantDiv?.remove();
    }
    if (this.maximize) {
      this.maximizeRecentOrNextParticipant();
    }
    this.createParticipantDiv(identity, participant?.attributes);
  }

  async updateParticipantList(): Promise<void> {
    this.participants = await firstValueFrom(this.videoCallService.getParticipantList(this.roomName));
    this.totalNumberOfAgents = this.participants?.filter((participant)=> !participant?.identity?.includes('guest') && !participant?.attributes?.disconnected_timestamp);
    const firstAgent = this.participants?.find((participant)=> !participant?.identity?.includes('guest') && !participant?.attributes?.disconnected_timestamp);
    if(this.totalNumberOfAgents?.length === 1){
      this.agentName = firstAgent?.attributes?.first_name;
    }
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.agentName], [firstAgent?.attributes?.first_name]);
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.participantList], [this.participants])
    this.participants?.forEach((participant: Participant)=>{
      const disconnectTimestamp = participant.attributes?.disconnected_timestamp;
      const isOnline = !disconnectTimestamp && !participant.attributes.inSmallRoom;
      const participantDiv = this.getParticipantDiv(participant?.identity);
      if(isOnline){
        this.participantConnected(participant);
      }
      else if((participant.attributes.inSmallRoom && participantDiv)
          || disconnectTimestamp){
        this.participantDisconnected(participant);
      }
    });
  }

  private _getParticipantIdentityForIcon(identity: string): string {
    identity = identity.toLowerCase();
    if (identity.includes('guest')) {
      return 'G';
    } else {
      const fullName = identity.split(' ');
      const initials = fullName.map(name => name.charAt(0)).join('');
      return initials.toUpperCase();
    }
  }

  createParticipantDiv(identity: string, attributes:any ) {
    const div = document.createElement('div');
    if (identity !== this.guestUserId) {
      if ((identity as string).startsWith('guest')) {
        div.id = identity;
        div.className = 'guest video-off participant';
        div.setAttribute('data-identity', this._getParticipantIdentityForIcon(identity));
        div.setAttribute('data-user', identity);
        this.guestVideoContainer?.nativeElement.appendChild(div);
      } else {
        div.id = `_${identity}`;
        this.agentsOnCall++;
        // TODO: line commented for now because we will not show more than two people on the video.
        // div.className = this.elRemote.nativeElement.hasChildNodes() ? 'secondary-agent video-off' : 'agent video-off';
        div.className = 'agent video-off participant real-agent';
        // One agent need to always be the dominant speaker
        if (this.agentsOnCall < 2) {
          this.agentVideoContainer.nativeElement.className += ' dominant-speaker';
          div.className += ' dominant-speaker';
        }
        if (!this.agentVideoContainer.nativeElement.hasChildNodes()) {
          const isOutboundCall = this.utils.getLocalVal(PluginStates.roomInfo)?.isOutboundCall;
          if (isOutboundCall) {
            this.agentVideoContainer?.nativeElement.setAttribute('agent-live', `${this.translate.instant('inCall.liveExpert')}`);
          }
          this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.agentName], [identity?.split('_')[1]]);
        }
        div.setAttribute('data-identity', attributes?.first_name);
        div.setAttribute('data-user', identity?.split('_')[0]);

        const participantAvatar = this._getAgentAvatar(identity?.split('_')[0]);
        if (participantAvatar) {
          div.className += ' participant-with-avatar';
          div.setAttribute('style', `background: url(${participantAvatar}) no-repeat center center; background-size: contain;background-color: #2c3e4a`);
        }
        this.agentVideoContainer.nativeElement.appendChild(div);
        this.isUserInQueue = false;
        this.agentVideoReady = true;
        this._checkLastState();
        this.showChatForMobile();
      }
      this.participantDivList[identity] = div;
      this.setDivMuted(div);
    }
  }

  private _checkLastState() {
    if (this.lastCallState === CallStates.maximize || this.lastCallState === CallStates.maximizeFull) {
      this.maximize = false;
      this.maximizeFull = this.lastCallState === CallStates.maximizeFull;
      this.isChatOpen = false;
      this.minimize = false;
      this.toggleFullScreen(false);
    } else if (this.lastCallState === CallStates.default) {
      const category = this.isLandscape ? (this.isUserInQueue ? this.spTracker.categories.callChatWaitingLandscape : this.spTracker.categories.callChatLandscape) :
          (this.isUserInQueue ? this.spTracker.categories.callChatWaiting : this.spTracker.categories.callChat);
      if(category.includes('waiting')){
        const context = this.utils.getJoinQueueQuestionSchema();
        if(context) {
          this.snowplowService.trackStructEvent(category, this.spTracker.labels.screenShown,
              this.spTracker.labels.genericScreen, this.spTracker.labels.version, 3, [context]);
          return;
        }
      }
      this.snowplowService.trackStructEvent(category, this.spTracker.labels.screenShown,
        this.spTracker.labels.genericScreen, this.spTracker.labels.version, category === this.spTracker.categories.callChat && !this.isMobile ? 4 : 3);
    }
  }

  private _getAgentAvatar(identity: string): string {
    if (!this.participants) return '';
    const participant = this.participants.find(participant => participant.identity === identity);
    return participant?.attributes.avatar || '';
  }

  private setDivMuted(div: Element): void {
    div.setAttribute('data-muted', ' Muted');
    div.classList.add('mic-off');
  }

  private removeMutedForDiv(div: Element): void {
    div.removeAttribute('data-muted');
    div.classList.remove('mic-off');
  }

  public getNumberOfExtraMembers(currentMembersOnCallCount: number): number {
    return currentMembersOnCallCount > 2 ? currentMembersOnCallCount - 2 : 0;
  }

  participantDisconnected(participant: any) {
    this.membersOnCall--;
    const identity = ((participant.identity as string).startsWith('guest')) ? participant.identity : `_${participant.identity.split('_')[0]}`;
    const div = this.mainVideoContainer.nativeElement.querySelector(`#${identity}`);
    const fullParticipantId = this.mainVideoContainer.nativeElement.querySelector('.maximizedParticipant')
    if (identity === fullParticipantId?.id && this.maximize) {
      this.maximizeRecentOrNextParticipant()
    }
    div?.remove();
  }

  trackPublished(publication: RemoteVideoTrackPublication, participant: RemoteParticipant | LocalParticipant) {
    if (publication.track) {
      this.attachTrack(publication.track, participant);
    }
    publication.on('subscribed', (track: RemoteVideoTrack) => {
      this.attachTrack(track, participant);
    });
    publication.on('unsubscribed', (track: RemoteVideoTrack) => {
      this.detachTrack(track, participant);
    });
  }

  attachTrack(track: RemoteVideoTrack, participant: RemoteParticipant | LocalParticipant) {
    const { identity } = participant;
    this.audioOutDeviceId = this.utils.getLocalVal(PluginStates.deviceInfo)?.audiOutDevice;
    if (identity !== this.guestUserId) {
      if ((identity as string).startsWith('guest')) {
        this.attachGuestTrack(track, identity);
      } else {
        if (track.name.split('_')[0] === ScreenShareType.actual) {
          this.attachScreenShare(track, identity);
        } else if (track.name !== ScreenShareType.default && track.name !== ScreenShareType.actual) {
          this.attachAgentTrack(track, identity);
        }
      }
    }
  }

  detachTrack(track: any, participant: any) {
    const { identity } = participant;
    if (identity !== this.guestUserId) {
      if ((identity as string).startsWith('guest')) {
        this.detachGuestTrack(track, identity);
      } else {
        if (track.name.split('_')[0] === ScreenShareType.actual) {
          this.detachScreenShare(identity);
        } else if (track.name !== ScreenShareType.default && track.name !== ScreenShareType.actual) {
          this.detachAgentTrack(track, identity);
        }
      }
    }
  }

  attachScreenShare(track: any, identity: string) {
    const div = document.createElement('div');
    const id = `_${identity.split('_')[0]}-screen`;
    div.id = id;
    div.setAttribute('data-identity', ' Screen Share');
    div.setAttribute('data-text', `${track.name.split('_')[1]} is sharing`);
    div.className = 'agent-screen participant agent';
    div.appendChild(track.attach());
    this.agentScreenShare.nativeElement.appendChild(div);
    if (this.maximize) {
      this.animateTheParticipantDiv(id)
    } else {
      this.toggleFullScreen(false, id)
    }
    const promise = this.agentScreenShare.nativeElement.querySelector(`${track.kind}`)?.play();
    if (promise !== undefined) {
      this.observeConnection(promise);
    }
  }

  detachScreenShare(identity: string) {
    const fullParticipantId = this.mainVideoContainer.nativeElement.querySelector('.maximizedParticipant');
    const id = `_${identity.split('_')[0]}-screen`;
    this.agentScreenShare.nativeElement.querySelector(`#${id}`).remove();
    if (id === fullParticipantId?.id && this.maximize) {
      this.maximizeRecentOrNextParticipant();
    }
  }

  attachGuestTrack(track: any, identity: string) {
    const guestDiv = this.guestVideoContainer.nativeElement.querySelector(`#${identity}`);
    if (guestDiv) {
      guestDiv.querySelector(`${track.kind}`)?.remove();
      guestDiv.appendChild(track.attach());
      if (track?.kind === 'audio' && this.audioOutDeviceId) {
        this.setSpeakerDevice(guestDiv, this.audioOutDeviceId);
      }
      this.setRemoveAttributeToDiv(track, guestDiv, false);
      let promise = this.guestVideoContainer.nativeElement.querySelector(`${track.kind}`)?.play();
      if (promise !== undefined) {
        this.observeConnection(promise);
      }
    }
  }

  setSpeakerDevice(div: any, deviceId: string) {
    div.querySelector('audio').setSinkId(deviceId).then(() => {
      console.log(`Success, audio output device attached: ${deviceId}`);
    }).catch((error: any) => {
      console.error(error);
    });
  }


  detachGuestTrack(track: any, identity: string) {
    const guestDiv = this.guestVideoContainer.nativeElement.querySelector(`#${identity}`);
    if (guestDiv) {
      guestDiv.querySelector(`${track.kind}`)?.remove();
      this.setRemoveAttributeToDiv(track, guestDiv, true);
    }
  }

  attachAgentTrack(track: any, identity: string) {
    const agentDiv = this.agentVideoContainer.nativeElement.querySelector(`#_${identity.split('_')[0]}`);
    if (agentDiv) {
      agentDiv.querySelector(`${track.kind}`)?.remove();
      agentDiv.appendChild(track.attach());
      if (track?.kind === 'audio' && this.audioOutDeviceId) {
        this.setSpeakerDevice(agentDiv, this.audioOutDeviceId);
      }
      this.setRemoveAttributeToDiv(track, agentDiv, false);
      const promise = this.agentVideoContainer.nativeElement.querySelector(`${track.kind}`)?.play();
      if (promise !== undefined) {
        this.observeConnection(promise);
      }
    }
  }

  private observeConnection(promise: Promise<any>): void {
    promise.then(() => {
      setTimeout(() => {
        this.showReload = false;
      }, 3 * 1000 /** 3 seconds */);
    }).catch(() => {
      setTimeout(() => {
        this.showReload = true;
      }, 3 * 1000 /** 3 seconds */);
    });
  }

  detachAgentTrack(track: any, identity: string) {
    const agentDiv = this.agentVideoContainer.nativeElement.querySelector(`#_${identity.split('_')[0]}`);
    if (agentDiv) {
      agentDiv.querySelector(`${track.kind}`)?.remove();
      this.setRemoveAttributeToDiv(track, agentDiv, true);
    }
  }

  setRemoveAttributeToDiv(track: any, div: Element, value: boolean) {
    if (track?.kind === 'audio') {
      if (value) {
        this.setDivMuted(div);
      } else {
        this.removeMutedForDiv(div);
      }
    } else if (track?.kind === 'video') {
      value ? div.className += ' video-off' : div?.classList?.remove('video-off');
    }
  }

  playAllTracks() {
    this.videoDiv.nativeElement.querySelectorAll('audio').forEach((audio: any) => {
      audio.play();
    });
    this.videoDiv.nativeElement.querySelectorAll('video').forEach((video: any) => {
      video.play();
    });
    this.showReload = false;
  }

  onClickOnPermissionModal() {
    this.closeModalSub.next();
  }

  startLocalVideo(deviceId: string, isSwitchCamera?: boolean) {
    (async () => {
      let canOpenModal = true;
      setTimeout(() => {
        if (canOpenModal && !this.showPermissionPopup) {
          this.deviceName = MicCameraPermission.camera;
          this.setCommonModalFlags(true, false, false);
          this.openModalSub.next();
        }
      }, 1200);
      await navigator.mediaDevices.getUserMedia({ video: true }).then(mediaStream => {
        mediaStream.getTracks()[0]?.stop();
        navigator.mediaDevices.enumerateDevices().then(devices => {
          this.deviceList = devices;
          this.videoDevices = devices.filter(device => device.kind === 'videoinput');
          const deviceExist = this.videoDevices.findIndex((device: any) => device.deviceId === deviceId);
          const currentDeviceId = (deviceId && deviceExist !== -1) ? deviceId : this.videoDevices[0]?.deviceId;
          createLocalVideoTrack({
            width: 320,
            deviceId: { exact: currentDeviceId }
          }).then((track: LocalVideoTrack) => {
            this.videoStream = track;
            this.utils.setLocalVal(PluginStates.deviceInfo,
              [DeviceInfoStates.videoInDevice],
              [currentDeviceId]);
            this.elLocal.nativeElement.querySelector('video')?.remove();
            this.elLocal.nativeElement.appendChild(track.attach());
            if (isSwitchCamera) {
              this.sharedService.enableDevices({ switchCamUpdated: true });
            } else {
              this.sharedService.enableDevices({ cameraUpdated: true });
            }
            this.sendSnowplowTrackerForCamera(currentDeviceId);
            return this.roomObj?.localParticipant.publishTrack(track);
          });
        });
      }).catch((err: DOMException) => {
        this.sendSnowplowEventForMediaAccess(err.message, this.spTracker.labels.camera);
        this.updateCamStatus(false);
        if (isSwitchCamera) {
          this.sharedService.enableDevices({ switchCamUpdated: true });
        } else {
          this.sharedService.enableDevices({ cameraUpdated: true });
        }
        this.sharedService.showPermissionError({ camError: true, micError: false });
        this.cdr.detectChanges();
      }).finally(() => {
        canOpenModal = false;
        this.callOnFinally();
      });
    })();
  }

  private sendSnowplowTrackerForCamera(deviceId: string): void {
    if (!this.hasGrantedCameraPermissionOnce) {
      this.hasGrantedCameraPermissionOnce = true;
      this.snowplowService.trackStructEvent(this.currentCategory,
        this.spTracker.actions.permissionGrant, this.spTracker.labels.camera, '', '', this.context);
    }
  }

  startMic(deviceId: any) {
    (async () => {
      let canOpenModal = true;
      setTimeout(() => {
        if (canOpenModal && !this.showPermissionPopup) {
          this.deviceName = MicCameraPermission.mic;
          this.setCommonModalFlags(true, false, false);
          this.openModalSub.next();
        }
      }, 500);

      await navigator.mediaDevices.getUserMedia({ audio: true }).then(mediaStream => {
        if (!this.hasGrantedMicPermissionOnce) {
          this.snowplowService.trackStructEvent(this.currentCategory,
            this.spTracker.actions.permissionGrant, this.spTracker.labels.microphone, '', '', this.context);
          this.hasGrantedMicPermissionOnce = true;
        }
        mediaStream.getTracks()[0]?.stop();
        navigator.mediaDevices.enumerateDevices().then(devices => {
          this.deviceList = devices;
          this.audioDevices = devices.filter(device => device.kind === 'audioinput');
          const deviceExist = this.audioDevices.findIndex((device: any) => device.deviceId === deviceId);
          createLocalAudioTrack({
            deviceId: { exact: (deviceId && deviceExist !== -1) ? deviceId : this.audioDevices[0]?.deviceId }
          }).then((track: LocalAudioTrack) => {
            this.audioStream = track;
            this.sharedService.enableDevices({ micUpdated: true });
            return this.roomObj?.localParticipant.publishTrack(track);
          });
        });
        this.updateMicStatus(true);
      }).catch((err: DOMException) => {
        this.sendSnowplowEventForMediaAccess(err.message, this.spTracker.labels.microphone);
        this.sharedService.showPermissionError({ camError: false, micError: true });
        this.sharedService.enableDevices({ micUpdated: true });
        this.updateMicStatus(false);
      }).finally(() => {
        canOpenModal = false;
        this.callOnFinally();
      });
    })();
  }

  private sendSnowplowEventForMediaAccess(errorMessage: string, label: string) {
    if (errorMessage.includes('denied')) {
      this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.actions.permissionDeny,
        label, '', '', this.context);
    } else if (errorMessage.includes('dismissed')) {
      this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.actions.permissionDismiss,
        label, '', '', this.context);
    }
  }

  callOnFinally() {
    this.showPermissionPopup = false;
    this.onClickOnPermissionModal();
  }

  setCommonModalFlags(permission: boolean, deviceSelect: boolean, inviteForm: boolean) {
    if (!this.utils.isOnMobile()) {
      this.showPermissionPopup = permission;
    }
    this.showSettingsModal = deviceSelect;
    this.showInviteForm = inviteForm;
  }

  public toggleFullScreen(fromMenu: boolean, screenShareId?: string): void {
    if (fromMenu) {
      this.executeTrackingForMenuClick();
    }
    this.leaveCallButtonClicked = false;
    this.resizeService.resizeSub.next({
      isMinimized: false,
      isMaximized: !this.maximize,
      isDefault: this.maximize
    });
    this.showNotification = false;
    this.newMessage = null;
    this.utils.updateBrowserTitle(false, true);
    if (this.maximize) {
      this.maximizeRecentOrNextParticipant(screenShareId);
    } else {
      const fullViewParticipant = this.mainVideoContainer.nativeElement.querySelector('.maximizedParticipant');
      if (fullViewParticipant) {
        fullViewParticipant.style.cssText += 'position: relative;bottom: unset; right: unset';
        fullViewParticipant?.classList.remove('maximizedParticipant');
      }
    }
  }

  executeTrackingForMenuClick() {
    let trackLabel;
    if (this.isUserInQueue) {
      trackLabel = this.maximize ? (this.isLandscape ? this.spTracker.categories.callChatWaitingLandscape : this.spTracker.categories.callChatWaiting) :
        (this.isLandscape ? this.spTracker.categories.callFullWaitingLandscape : this.spTracker.categories.callFullWaiting)
    } else {
      trackLabel = this.maximize ? (this.isLandscape ? this.spTracker.categories.callChat : this.spTracker.categories.callChat) :
        (this.isLandscape ? this.spTracker.categories.callFullLandscape : this.spTracker.categories.callFull)
    }
    this.snowplowService.trackStructEvent(this.currentCategory,
      this.spTracker.labels.click, trackLabel, this.spTracker.properties.unreadMsg,
      this.unreadMessageCount ? this.unreadMessageCount : 0, this.context);
  }

  maximizeRecentOrNextParticipant(screeShareId?: string) {
    const lastParticipantInFullId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.lastParticipantInFull);
    const lastParticipantInFull = screeShareId ? this.mainVideoContainer?.nativeElement.querySelector(`#${screeShareId}`) :
      ((lastParticipantInFullId === 'connecting' && !this.isUserInQueue) ? null :
        this.mainVideoContainer?.nativeElement.querySelector(`#${lastParticipantInFullId}`));
    this.showMaximizeActions = false;
    const fullViewParticipant = lastParticipantInFull ? lastParticipantInFull :
      this.isUserInQueue ? this.agentVideoContainer?.nativeElement.querySelector('#connecting') :
        this.agentVideoContainer?.nativeElement.querySelector('.real-agent');
    if (fullViewParticipant) {
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastParticipantInFull],
        [fullViewParticipant.id])
      fullViewParticipant.classList.add('maximizedParticipant');
      fullViewParticipant.style.cssText += 'position: absolute;bottom: 0; right: 0';
    } else {
      const currentFullViewParticipant = this.mainVideoContainer.nativeElement.querySelector('.maximizedParticipant');
      if (currentFullViewParticipant) {
        currentFullViewParticipant.style.cssText += 'position: relative;bottom: unset; right: unset';
        currentFullViewParticipant?.classList.remove('maximizedParticipant');
      }
    }
    setTimeout(() => {
      this.showMaximizeActions = true;
    }, 1000);
  }

  desktopMax() {
    this.showMaximizeActions = false;
    this.maximizeFull = !this.maximizeFull;
    setTimeout(() => {
      this.showMaximizeActions = true;
    }, 1000);
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.lastCallState],
      [this.maximizeFull ? CallStates.maximizeFull : CallStates.maximize]);
  }

  public toggleCamera(cam: boolean): void {
    if (this.isCameraOn) {
      this.videoStream?.stop();
      this.roomObj?.localParticipant.videoTracks.forEach((videoTracks: any) => {
        const trackConst = [videoTracks][0].track;
        trackConst.stop();
        return this.roomObj?.localParticipant.unpublishTrack(trackConst);
      });
      this.elLocal.nativeElement.querySelector('video')?.remove();
      this.sharedService.enableDevices({ cameraUpdated: true });
    } else {
      this.startLocalVideo(this.videoDeviceId);
    }
    this.updateCamStatus(cam);
  }

  toggleMicrophone(mic: boolean) {
    if (this.isMicOn) {
      this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.close,
        this.spTracker.labels.microphone, '', '', this.context);
      this.audioStream.stop();
      this.roomObj?.localParticipant.audioTracks.forEach((audioTrack: any) => {
        const trackConst = [audioTrack][0].track;
        trackConst.stop();
        return this.roomObj?.localParticipant.unpublishTrack(trackConst);
      });
      this.sharedService.enableDevices({ micUpdated: true });
    } else {
      this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.open,
        this.spTracker.labels.microphone, '', '', this.context);
      this.startMic(this.audioDeviceId);
    }
    this.updateMicStatus(mic);
  }

  updateMicStatus(status: boolean) {
    this.isMicOn = status;
    this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.audio], [this.isMicOn]);
    this.updateMember();
  }

  updateCamStatus(status: boolean) {
    this.isCameraOn = status;
    this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.video], [this.isCameraOn]);
    this.updateMember();
    this.sendSnowplowEventForCamera();
  }

  private sendSnowplowEventForCamera(): void {
    if (this.isCameraOn) {
      this.snowplowService.trackStructEvent(
        this.currentCategory,
        this.spTracker.labels.open,
        this.spTracker.labels.camera,
        this.isCameraOn ? JSON.stringify(this.videoDeviceId) : '', '', this.context);
    } else {
      this.snowplowService.trackStructEvent(
        this.currentCategory,
        this.spTracker.labels.close,
        this.spTracker.labels.camera, '', '', this.context);
    }
  }

  closeMediaPopover(type: string, event: Event) {
    event.stopPropagation();
    if (type === 'cam') {
      this.showCamError = false;
    } else {
      this.showMicError = false;
    }
    this.cdr.detectChanges();
  }

  openInviteForm() {
    this.setCommonModalFlags(false, false, true);
    this.openModalSub.next();
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.actions.invite,
      this.spTracker.labels.controlMenu, '', '', this.context);
  }

  public modalClosed(): void {
    this.setCommonModalFlags(false, false, false);
  }

  closeInviteForm() {
    this.showInviteForm = false;
    this.closeModalSub.next();
  }

  maximizePlugin() {
    this.maximizeFull = true;
  }

  private updateMember(memberId?: string, isLeaveCall?: boolean): void {
    let req: any = {
      ...this.userInfo,
      roomName: this.roomName,
      micOff: !this.isMicOn,
      cameraOff: !this.isCameraOn,
      isOffline: this.isOffline,
      domain_userid: this.snowplowService.getSnowplowUserId()
    };
    if(isLeaveCall){
      req['disconnected_timestamp'] = moment().utc();
    }
    const memberSid = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.memberSid);
    if (memberSid || memberId) {
      const id = memberSid || memberId;
      this.chatService.updateMember(req, id).subscribe(() => {
        this.chatService.memberUpdated(this.roomName).subscribe(() => {
          console.log('member updated successfully');
        });
        if(isLeaveCall) {
          const participantId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.participantList)?.find((res: Participant)=> res?.identity.includes('guest'))?.identity;
          const guestId = participantId ? participantId : (this.guestUserId ? this.guestUserId : '');
          const req: MessageCreateReq = {
            channelSid: this.roomName,
            message: '',
            identity: 'system',
            attributes: {
              message_type: 'joined_left',
              user_type: MsgUserType.agent,
              user_id: +this.guestUserId?.split('_')[1],
              action: MessageType.left,
              user_name: `Guest ${this.guestUserId?.split('_')[1]}`
            }
          };
          this.chatService.sendChatMessages(req).subscribe(() => {
            console.log('guest left successfully');
          });
        }
      });
    }
  }

  private endCall() {
    this.disableNotNow = false;
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.roomStatus], [CallStatus.closed]);
    this.endCobrowseSession();
    this.setCoBrowseCustomData(true);
    const memberSid = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.memberSid);
    this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.deviceInfo, PluginStates.outboundFirstInitiated]);
    this.disconnectCall(memberSid);
  }

  @HostListener('window:beforeunload')
  disconnectCall(memberSid?: string) {
    this.extraMethodToReleaseCam();
    if (this.roomObj) {
      this.roomObj?.localParticipant?.tracks?.forEach((track: any) => {
        track.track.stop();
      });
      this.roomObj?.localParticipant?.videoTracks?.forEach((video: any) => {
        const trackConst = [video][0].track;
        trackConst.stop();
        this.roomObj?.localParticipant?.unpublishTrack(trackConst);
      });
      this.roomObj?.disconnect();
      this.roomObj = undefined;
      this.isOffline = true;
    }
    if (memberSid) {
      this.updateMember(memberSid, true);
    }

    this.ngOnDestroy();
  }

  extraMethodToReleaseCam() {
    let camObj = this.elLocal?.nativeElement.querySelector('video')?.srcObject;
    if (camObj) {
      const tracks = camObj.getTracks();
      tracks.forEach((track: any) => {
        track.stop();
      });
      camObj = null;
    }
  }

  setCoBrowseCustomData(reset: any) {
    const customData: any = {
      user_id: !reset ? this.guestUserId : null,
      user_email: null,
      device_id: null,
      device_name: !reset ? this.guestUserId : null,
      room_name: !reset ? this.roomName : null
    };
    if (this.userInfo.full_name) {
      customData.user_name = !reset ? this.userInfo.full_name : null;
    }
    (<any>window).CobrowseIO.customData = customData;
  }

  endCobrowseSession() {
    if ((<any>window)?.CobrowseIO?.currentSession) {
      (<any>window).CobrowseIO.currentSession.end();
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.callDuration = this.transform(this.time);
    }, 1000);
  }

  transform(value: number) {
    const sec_num = value;
    const hours: any = (`00${Math.floor(sec_num / 3600)}`).slice(-2);
    const minutes: any = (`00${Math.floor((sec_num - (hours * 3600)) / 60)}`).slice(-2);
    const seconds = (`00${Math.floor(value - minutes * 60)}`).slice(-2);
    return `${hours}:${minutes}:${seconds}`;
  }

  openConfigModal() {
    this.showSettingsModal = true;
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.actions.setting,
      this.spTracker.labels.controlMenu, '', '', this.context);
  }

  stopOldTrack() {
    this.roomObj?.localParticipant.videoTracks.forEach((videoTracks: any) => {
      const trackConst = [videoTracks][0].track;
      trackConst.stop();
      return this.roomObj?.localParticipant.unpublishTrack(trackConst);
    });
  }

  closeModalEvent(e: any) {
    this.showSettingsModal = false;
    if (Object.keys(e).length !== 0 && e.constructor === Object) {
      const deviceList: DeviceInfo = this.utils.getLocalVal(PluginStates.deviceInfo);
      if (this.isCameraOn && deviceList?.videoInDevice !== e?.videoInput) {
        this.stopOldTrack();
        this.startLocalVideo(e?.videoInput);
      }
      if (this.isMicOn && deviceList?.audioInDevice !== e?.audioInput) {
        this.startMic(e?.audioInput);
      }
      if (e?.audioOutput && deviceList?.audiOutDevice !== e?.audioOutput) {
        this.changeMicroPhone(e?.audioOutput);
      }
      this.audioDeviceId = e?.audioInput;
      this.videoDeviceId = e?.videoInput;
      this.utils.setLocalVal(PluginStates.deviceInfo,
        [DeviceInfoStates.audioInDevice, DeviceInfoStates.audiOutDevice, DeviceInfoStates.videoInDevice],
        [e?.audioInput, e?.audioOutput, e?.videoInput])
    }
  }

  switchCamera(videoInputId: string) {
    if (this.isCameraOn) {
      this.stopOldTrack();
      this.startLocalVideo(videoInputId, true);
    }
    this.videoDeviceId = videoInputId;
    this.utils.setLocalVal(PluginStates.deviceInfo,
      [DeviceInfoStates.videoInDevice],
      [videoInputId]);
  }

  changeDeviceToDefault() {
    const deviceList: DeviceInfo = this.utils.getLocalVal(PluginStates.deviceInfo);
    if (this.isMicOn) {
      this.updateAudioAndVideo(true, deviceList?.audioInDevice);
    }
    if (this.isCameraOn) {
      this.updateAudioAndVideo(false, deviceList?.videoInDevice);
    }
    if (deviceList?.audiOutDevice) {
      this.changeMicroPhone(deviceList?.audiOutDevice);
    } else {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        const outputDevice = devices.filter(device => device.kind === 'audiooutput');
        this.changeMicroPhone(outputDevice[0]?.deviceId);
      });
    }
  }

  updateAudioAndVideo(isAudio: boolean, deviceId: string) {
    if (isAudio) {
      this.roomObj?.localParticipant.audioTracks.forEach((audioTrack: any) => {
        const trackConst = [audioTrack][0].track;
        trackConst.stop();
        this.roomObj?.localParticipant.unpublishTrack(trackConst);
      });
      this.startMic(deviceId);
    } else {
      this.roomObj?.localParticipant.videoTracks.forEach((videoTracks: any) => {
        const trackConst = [videoTracks][0].track;
        trackConst.stop();
        this.elLocal.nativeElement.querySelector('video')?.remove();
        this.roomObj?.localParticipant.unpublishTrack(trackConst);
        this.startLocalVideo(deviceId);
      });
    }
  }

  changeMicroPhone(deviceId: string) {
    this.audioOutDeviceId = deviceId;
    this.videoDiv.nativeElement.querySelectorAll('audio').forEach((audio: any) => {
      audio.setSinkId(deviceId)
        .then(() => {
          console.log(`Success, audio output device attached: ${deviceId}`);
        })
        .catch((error: any) => {
          console.error(error);
        });
    });
  }

  public leaveCall(): void {
    const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    if (roomInfo?.roomStatus === CallStatus.in_progress) {
      this.gtmService.sendGtm(GtmTrackers.optimyCallLeft);
    }
    this.sharedService.closeCall();
    this.videoCallService.inCall$.next({ inCall: false });
    this.recordingService.stopRecordInteractionAction$.next(true);
  }

  setOnMobileAndOutbound() {
    if (this.isOutboundCall) {
      this.sharedService.displayMinimizeIcon(true);
      this.snowplowService.trackStructEvent(this.spTracker.labels.outboundRequest, this.spTracker.labels.screenShown,
        this.spTracker.labels.requestPage, this.spTracker.labels.version, 2, this.context);
    }
  }

  private createMenu(): void {
    this.menuList = [
      {
        title: 'inCall.menu.settings',
        active: false,
        iconName: 'settings',
        action: () => {
          this.openConfigModal();
        }
      },
      {
        title: 'inCall.menu.invite',
        active: false,
        iconName: 'people-invite',
        action: () => {
          this.openInviteForm();
        }
      },
    ];
  }
}
