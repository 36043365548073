/* eslint-disable max-len */
export const English = {
  agentNotAvailable: {
    dontWait: 'Don\'t want to wait?',
    notAvailable: 'Sorry! There is no live agent at this time'
  },
  bookingAppointment: {
    availableTime: 'Available only at the time of the session',
    cancelled: 'Cancelled',
    cancelSessionText: 'Your live video session cancelled successfuly',
    cellPhone: 'Invalid entry. Please enter all 10 numerical digits.',
    charactersLimit: 'Character limit {{characterCount}}/{{maxCharactersLimit}}',
    confirmationText: 'Your live video session is scheduled for',
    confirmationText1: 'We\'ve sent a confirmation letter to your email with the session link. Please check your junk mail if you don\'t see it.You can also add to your own calendar from the ways shown below.',
    confirmationUpdatedText: 'Your updated live video session is scheduled for',
    conflictErrorMsg: 'Your selected date and time is no longer available. Please choose a new date and/or time.',
    contactInfo: 'Please provide your contact information',
    contactInformation: 'Contact Info',
    continueEditSession: 'Continue to Reschedule Session',
    continueToCancel: 'Are you sure you want to cancel this scheduled session?',
    dateSelectionText: 'Please select a date and an available timeslot for your {{duration}}-minutes live session',
    dateTime: 'Date & Time',
    editOrConfirmInformation: 'Please edit or confirm your contact information',
    editSessionText: 'You can also',
    editSessionText1: 'this session',
    email: 'Invalid entry. Please enter a valid email xxxxx@xxxx.xx.',
    finalConfirmation: 'You are about to schedule a live session. Please review the details below and confirm your request:',
    firstName: 'Invalid entry. Please enter alphabetic characters only.',
    googleCalendar: 'Add to Google Calendar',
    icsDescription1: 'We are excited to meet with you in your live video session. You can join the meeting using this link',
    icsDescription2: 'If you need to make a change to your session, you can modify the session using this link',
    icsDescription3: 'or cancel the session by using this link',
    icsFile: 'Download Calendar File (.ics)',
    icsTitle: 'Your booking with',
    isConfirmed: 'is confirmed',
    keepSession: 'Keep Session',
    lastName: 'Invalid entry. Please enter alphabetic characters only.',
    message: 'Message',
    noSlotsAvailable: 'Sorry. There are currently no available slots.',
    postalCode: 'Postal Code *',
    postalCodeLabel: 'Postal code',
    previousSessionCancelled: 'Your previous session has been cancelled.',
    rescheduleMessage: 'You can start rescheduling by select another available date and/or timeslot. Your existing scheduled session will not change until you submitted the new schedule',
    rescheduleSession: 'Reschedule Session',
    responseRequired: 'Response required',
    salesAgencyEmailError: 'Please enter a valid company email address',
    scheduleDate: 'Session is currently scheduled on <strong>{{cancelDate}}</strong>',
    scheduledBy: 'Scheduled by',
    scheduledCallText: 'Your live video session is currently scheduled for',
    scheduledLiveSessionInfo: 'The scheduled session is on',
    scheduledLiveSessionSuccessText1: 'A confirmation email has been sent to your mailbox. Please check your spam or trash folder if you don\'t receive it.',
    scheduleLiveSession: 'Schedule a Live Session',
    scheduleLiveSessionConfirmText: 'Please check the schedule details and submit when you\'re ready to schedule',
    scheduleLiveSessionFormText: 'Please tell us who you are and how we can help',
    segmentName: 'Organization',
    selectDate: 'Select Date',
    selectedInfo: 'Your {{duration}} minute session will be scheduled for',
    sessionInfo: 'This session link will be opened for you to join at the time of the session.',
    sessionLink: 'Your session link will become active at this time and is available below.',
    sessionSchedule: 'Session Schedule',
    successText: 'Your Session is Scheduled',
    successText1: 'A confirmation email has been sent with the session link. We encourage you to check your junk mail if you don\'t see it in your inbox.',
    successText2: 'We also recommend that you add the session to your calendar of choice using the links below.',
    thanksRescheduleSession: 'Thanks! Your session has been rescheduled',
    thanksScheduleSession: 'Thanks! Your Live Session has been scheduled!',
    userInfoRescheduleText: 'You are rescheduling your session',
    yesCancelSession: 'Yes, Cancel Session'
  },
  callInFuture: {
    callScheduled: 'Hi! This call is scheduled for',
    callStartShortly: 'Hello! This live call is initiating',
    excitedToSpeak: 'We are excited to speak with you. Your sales agent is scheduled to meet with you at',
    pleaseCheckTime: 'Please check in to your session at the scheduled time and we will be ready for you.',
    talkToYouSoon: 'Talk to you soon!',
    yourVideoIsComing: 'Your session is coming soon!'
  },
  common: {
    and: 'and',
    andOther: 'and {{count}} other are',
    andOthers: 'and {{count}} others are',
    at: 'at',
    back: 'Back',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    close: 'Close',
    cobrowseOn: 'Cobrowse is on.',
    confirm: 'Confirm',
    connecting: 'Connecting...',
    continue: 'Continue',
    edit: 'Edit',
    employeeAssistance: 'Would you like the assistance of one of our live video employees?',
    from: 'From',
    goBack: 'Go Back',
    guest: 'Guest',
    internationalPhoneNumberError: 'Invalid phone number',
    is: 'is',
    leave: 'Leave',
    live: 'Live',
    liveVideoSupport: 'Live Video Support',
    newMessage: 'new message!',
    newMessages: 'new messages!',
    no: 'No',
    noThanks: 'No, Thanks',
    okay: 'Okay',
    open: 'Open',
    optional: 'Optional',
    or: 'or',
    poweredBy: 'Powered by Optimy',
    reply: 'Reply',
    required: 'Required',
    reschedule: 'Reschedule',
    save: 'Save',
    scheduleSession: 'Schedule Online Session',
    sendMsg: 'Send a Message',
    startLiveVideo: 'Start Live Video',
    stop: 'Stop',
    stopCobrowse: 'Stop Cobrowse',
    submit: 'Submit',
    time: 'Time',
    to: 'To',
    unavailable: 'Unavailable',
    version: 'Version {{pluginVersion}}',
    videoChat: 'Video Chat',
    specialistLive: 'Meet a Specialist Live!',
    fabIconMessage: 'Don\'t worry, your audio and video is turned off',
    notNow: 'Not now',
    tomorrow: 'Tomorrow',
    today: 'Today',
    typing: 'typing',
    yes: 'Yes',
    you: 'You'
  },
  inCall: {
    addAnotherParticipant: 'Add Participant',
    areYouSureAboutLeave: 'Are you sure you want to leave the live call?',
    atConvenience: 'at your convenience.',
    atFutureTime: ' at a future convenient time.',
    audioDisruption: 'You may be experiencing video or audio disruptions.',
    audioOption: 'Audio Option',
    callCanceled: 'Sorry! This live call has been cancelled',
    callCancelledOrEndedMsg: 'To receive further assistance, please close this message and click on the live call icon.',
    callEnded: 'Sorry! This live call has ended',
    callNotStartedMsg: 'Our agent will be right with you',
    callSessionLinkExpired: 'Oops! This live call link has expired',
    camera: 'Camera',
    cameraPermissionLabel: 'Please grant permissions to access your camera',
    cameraPermissionText: 'This Live Support tool needs your camera so that other participants can see you. It will ask you to confirm this decision on each browser and computer that you use. To help protecting your privacy, the video will be turned off by default.',
    camError: 'Camera access is denied. Please grant access in your browser settings.',
    chat: {
      retryMessage: 'Message failed to send. Tap to retry',
      joinMessage: '{{userName}} has joined the chat',
      leftMessage: '{{userName}} has left the chat',
      privacyMsg: 'By continuing to use this product, you agree to have your data processed as described in our',
      privacyPolicy: 'Privacy Policy.'
    },
    continueBrowseMsg: 'While you are waiting in the Queue, you can continue to browse.',
    continuInvite: 'Continue to Invite',
    copyInvite: 'Copy Invite Link',
    copyLink: 'Copy Link',
    displayPreview: 'This display is preview only. Tap <strong>SAVE SETTINGS</strong> to apply.',
    emailPhoneError: 'Invalid input. Please try again with valid Email format or 10 digits only.',
    enterName: 'Enter name (optional)',
    ifYouPrefer: 'If you prefer, you can also',
    inputOutput: 'Input / Output',
    inTimeLeft: 'in {{timeLeft}} minutes',
    inviteDirectly: 'or invite them directly via email or phone',
    inviteError: 'One or more email and/or phone number is not valid',
    inviteInfo: 'Separate each invite\'s contact using comma (,) or Enter or Space',
    inviteInfoShort: 'Email or Phone Number',
    inviteOption1: 'Copy or share this call link with others that you want in the call',
    inviteOption2: 'You can also Invite guests via email or phone number',
    invitePeople: 'Invite',
    invitesSent: 'Invites sent',
    leave: 'Leave',
    leaveThisCall: 'Leave this call',
    leaveThisChat: 'Leave this chat',
    linkCopied: 'Invite Link Copied',
    linkExpiredMsg: 'If you wish to join a live call with an expert, close this message and click on the live call icon.',
    liveChatWith: 'Live chat with',
    numberOfAgents: '{{agentNum}} others',
    liveExpert: 'Live expert',
    menu: {
      audioVideo: 'Audio & Video',
      corner: 'Corner (Default)',
      fullScreen: 'Full Screen',
      invite: 'Invite',
      minimized: 'Minimized',
      sessionSettings: 'Session Setting',
      settings: 'Settings',
      windowSize: 'Window Size'
    },
    micError: 'Microphone access is denied. Please grant access in your browser settings.',
    micPermissionLabel: 'Please grant permissions to access your microphone',
    micPermissionText: 'This Live Support tool needs your microphone so that other participants can hear you. It will ask you to confirm this decision on each browser and computer that you use.',
    microphone: 'Microphone',
    minuteLeft: 'less than <b>{{timeLeft}} min</b>',
    minimize: 'Minimize',
    noAgentAtMoment: 'There is no available agent at the moment',
    notifyMsg: 'and we will notify you when an agent is available, or',
    ok: 'OK',
    oneOfTheAgent: 'or one of our other agents',
    outboundPrivacy: 'Your Camera and microphone are <b>Turned Off.</b>',
    outboundPrivacyTooltip: 'In order to protect your privacy and security, your camera and microphone are turned off by default.<br/><br/>If you click <b>"Reply"</b>, your camera and microphone will remain off.',
    permissionError: 'Camera/Microphone access is denied. Please grant access in your browser settings.',
    pleaseSelect: 'Please select',
    positionOnQueue: {
      youAreCurrently: 'You\'re currently',
      inLine: '{{position}} in line'
    },
    reconnect: 'Reconnect',
    settings: 'Settings',
    scheduleAnAppointment: 'Schedule an Appointment',
    scheduleAppointment: 'Schedule Appointment',
    sendInvitation: 'Send Invitation',
    sendInvite: 'Send Invite',
    sendInviteGuest: 'Invites Successfully sent out to guest',
    sendInviteGuests: 'Invites Successfully sent out to {{totalGuests}} guests',
    sendInviteQuestion: 'Send invite to the following guests?',
    shareInvite: 'Share Invite',
    sorryNoAgent: 'We\'re sorry! There is no available agent at this moment.',
    speaker: 'Speaker',
    stayInQueue: 'Stay In Queue',
    stayOnTheCall: 'Stay on the call',
    stayOnTheChat: 'Stay on the chat',
    textBack: 'Text Back',
    timeLeft: '{{timeLeft}} minutes',
    typeMessage: 'Type your message here',
    untilNextHelp: 'Until next available help',
    videoSettings: 'Video Settings',
    welcomeMessage: 'Welcome! An agent will be with you in approximately',
    willBeWithYouShortly: 'will be with you shortly'
  },
  intermediateScreen : {
    agentCanServe: 'This agent can serve you in',
    agentName: 'Your agent today is ',
    callStartIn: 'Your call will start in',
    guestAhead: 'Guest ahead of you',
    hangOnSec: 'Hang on just a sec',
    howAwkward: 'We’re sorry!',
    introTitle: 'Hi there',
    introText: 'You have questions and we have answers. Our team is ready to speak with you!',
    leaveUsMessage: 'Leave us a message',
    longerThanUsualTime: 'It’s taking longer than expected.<br/>Instead, we will have an agent reach out via text as soon as possible.',
    next: 'Next',
    noMobileHeading: 'Apologies, no agents are available.',
    noMobilePara: 'You are important to us and what we will do next is have an agent contact you by text message.',
    ourLiveTeam: 'Our Live Team',
    pickingBestTeamMember: 'We\'re picking the best agent for the job.',
    reportProblem: 'Report a problem',
    skipButton: 'Connect me to an agent now',
    startConversation: 'Start a new conversation',
    timeKillerMsg1: 'Putting on a fresh pot of coffee',
    timeKillerMsg2: 'Releasing scent of freshly brewed coffee',
    warningPopOver: 'Wait! You have not left the required information for us to address your questions. If you choose to exit we are unable to help.',
    waitCloseMsg: 'Wait! Do you mean to leave your place in line?',
    yesLeaveMessage: 'I’d like to switch to TEXT',
    yesLikeToSwitch: 'Yes, text me!'
  },
  leaveMessage: {
    email: 'Email',
    emailError: 'Please try again with valid Email format',
    emailPhoneError: 'Please enter either Email or Phone number',
    getBackYou: 'Thanks! <br/>Our team will text you soon.',
    howCanHelp: 'How can we help?',
    leaveFormTitle: 'Fill in your information and our Agent will contact you as soon as possible.',
    leaveMessageTitle: 'Let\'s connect over text. Please provide your details and we\'ll be in touch shortly.',
    leaveMessageTitle2: 'How can we help you?',
    leaveQueue: 'Leave the queue?',
    leaveQueueMessage: 'You will lose your spot to speak to a Agent.',
    message: 'Enter a few details about your inquiry',
    messageDelivered: 'Message delivered',
    messageMe: 'Call Me Back',
    mobileNumber: 'Mobile number',
    mobileNumberError: 'Mobile number is required',
    name: 'Name',
    nameError: 'Name is required',
    phone: 'Phone',
    phoneError: 'Phone is required',
  },
  messageUs: {
    enterInfo: 'Please enter your information, preferred method of contact and how we can help',
    email: 'Email (Required)',
    howCanIHelp: 'How can we help? (Optional)',
    name: 'Name (Required)',
    phone: 'Phone (Required)',
    replyMethod: 'How would you like us to reply?',
    send: 'Send',
    sendAMessage: 'Send a Message',
    thanksMessage: 'Thanks! Your message has been received!',
    contactMessage: 'One of our agents will get back to you within',
    twoBusiness: '2 business days.'
  },
  noAgentModal: {
    noAgent: 'There are no live agents',
    scheduleCall: 'Schedule a Call',
    leaveMessage: 'Submit'
  },
  placeholders: {
    cellPhone: 'Phone Number *',
    companyName: 'Company Name',
    defaultField: 'Name (required)',
    emailId: 'Email *',
    firstName: 'First Name *',
    lastName: 'Last Name *',
    fullName: 'Full Name *',
    postalCode: 'Postal Code (required)',
    roleYouAreHiringFor: 'Role you are hiring for',
    specifyRequest: 'Help us to prepare for the session by sharing details about specific questions or products of interest.'
  },
  queueForm: {
    callDropMessage: 'If we disconnect for any reason, we can continue our conversation via text. Would you like to do that?',
    nameField: 'In order to begin your conversation we’ll need to know who we’re speaking with:',
    noThanks: 'No, Thank you',
    yesTextMe: 'Yes, Text me'
  },
  userFeedback: {
    improveQuestion: 'How can we improve? (optional)',
    liveSessionEndedText: 'The Live Session with <strong>{{agentName}}</strong> has ended',
    liveSessionEndedTextNoAgentInfo: 'The Live Session has ended',
    rateText: 'Please rate your overall experience',
    sendFeedback: 'Send Feedback',
    skitAndExit: 'Skip & Exit',
    thanksForFeedback: 'Thank you for your feedback!'
  },
  userForm: {
    agreementEnd: ' Your video is automatically set to off when the call starts.',
    agreementStart: 'By clicking Join, you agree to the ',
    categoryError: 'Invalid entry. Please select a Category.',
    categoryRequired: 'Category (required)',
    connectWithAgent: 'Connect with an Agent',
    email: 'Email',
    joinCall: 'Join Call (video off)',
    joinOngoingCall: 'Join an ongoing call',
    name: 'Name',
    optional: '(Optional)',
    phone: 'Phone',
    policy: 'Privacy Policy.',
    required: '(Required)'
  },
  userFormErrors: {
    email: 'Invalid input. Please try again with valid Email format',
    name: 'Invalid entry. Please enter alphabetic characters only.',
    phone: 'Invalid input. Please try again with 10 digits only.',
    postalCode: 'Invalid input. Please try again with valid Canadian Postal Code format.'
  }
};
