import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MicCameraPermission } from '../../../core/constants/call.modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-permission-popup',
  templateUrl: './permission-popup.component.html',
  styleUrls: ['./permission-popup.component.scss']
})
export class PermissionPopupComponent implements OnInit {
  @Input() deviceName: string;
  @Output() onBack = new EventEmitter();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  cancel(){
    this.onBack.emit(true);
  }

  isMic(){
    return this.deviceName === MicCameraPermission.mic;
  }

  getPermissionText(value?: boolean){
    if(value){
      return this.deviceName === MicCameraPermission.mic ?
      this.translate.instant('inCall.micPermissionLabel') : this.translate.instant('inCall.cameraPermissionLabel');
    }
    return this.deviceName === MicCameraPermission.mic ?
    this.translate.instant('inCall.micPermissionText') : this.translate.instant('inCall.cameraPermissionText');
  }

}
