import { Injectable } from '@angular/core';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { AgentResponseData, AgentStatus } from '../../constants/agent.modal';
import { PluginStates } from '../../constants/common.enum';
import { SocketService } from '../socket/socket.service';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor(
      private socketService: SocketService,
      private utilsService: UtilsService) { }


  initializeColorSystem() {
    return new Promise((resolve, reject) => {
      const agentDetails = this.utilsService.getSessionVal(AgentStatus.agentStatusInfo);
      const fabColorsConfig = agentDetails?.fab_config?.colors;
      if (agentDetails) {
        if (fabColorsConfig?.length) {
          this.utilsService.setSessionVal([PluginStates.colorConfig], [fabColorsConfig]);
          this.updateColorSystem().then(() => {
            resolve(0);
          });
        } else {
          resolve(0);
        }
      }
      this.utilsService.getAgentStatusFormRedisCaChe().subscribe((res: AgentResponseData) => {
          this.updateSessionStorageAndColor(res, fabColorsConfig).then(() => resolve(0));
      }, error => {
        console.log('agent status error', error);
      });
    });
  }

  async updateSessionStorageAndColor(res: any, fabColorsConfig: any) {
    this.socketService.joinRoom(`guest${res?.tenant_id}`);
    this.utilsService.setSessionVal([AgentStatus.agentStatusInfo], [res]);
    this.utilsService.setCobrowse(res?.outbound_config?.cobrowse_licensekey);
    if (!fabColorsConfig?.length) {
      const colors = res?.fab_config?.colors ? res?.fab_config?.colors : [];
      this.utilsService.setSessionVal([PluginStates.colorConfig], [colors]);
      await this.updateColorSystem();
    }
  }

  public updateColorSystem() {
    return new Promise((resolve, reject) => {
      let colorConfig = this._getDefaultColorConfig();
      const systemColorConfig = this.utilsService.getSessionVal(PluginStates.colorConfig);

      if (systemColorConfig?.length) {
        colorConfig.push(...systemColorConfig);
      }
      colorConfig.forEach((color: any) => {
        document.documentElement.style.setProperty(`--${color.name}`, color.value);
        document.documentElement.style.setProperty(`--${color.name}-decimal`, this._transformHexToDecimalColor(color.value?.trim()));
        document.documentElement.style.setProperty(`--${color.name}-hue`, this._getHueColorFromHex(color.value?.trim()));
        document.documentElement.style.setProperty(`--${color.name}-opacity-50`, this._getHexWithOpacity50(color.value?.trim()));

      });
      resolve(0);
    });
  }

  private _getDefaultColorConfig(): { name: string, value: string }[] {
    return [
      { name: 'client-color', value: '#D2360F' },
      { name: 'button-color', value: '#147BB3' }
    ];
  }

  private _transformHexToDecimalColor(color: string): string {
    let decimalVersion = '';
    if (color.length === 7) {
      decimalVersion = parseInt(color.slice(1, 3), 16).toString() + ', ';
      decimalVersion += parseInt(color.slice(3, 5), 16).toString() + ', ';
      decimalVersion += parseInt(color.slice(5), 16).toString();
    } else if (color.length === 4) {
      decimalVersion = parseInt(color.slice(1, 2) + color.slice(0, 1), 16).toString() + ', ';
      decimalVersion += parseInt(color.slice(2, 3) + color.slice(1, 2), 16).toString() + ', ';
      decimalVersion += parseInt(color.slice(3) + color.slice(2), 16).toString();
    }
    return decimalVersion;
  }

  // get hue color from hex color
  private _getHueColorFromHex(hexColor: string): string {
    let { red, green, blue } = this._getRGBFromHex(hexColor);
    let hue = this._getHueColor(red, green, blue);
    return hue.toString();
  }

  private _getHexWithOpacity50(hexColor: string): string {
    return hexColor + '80';
  }

  private _getRGBFromHex(hexColor: string): { red: number, green: number, blue: number } {
    let red: any = 0
    let green: any = 0
    let blue: any = 0;
    if (hexColor.length === 4) {
      red = '0x' + hexColor[1] + hexColor[1];
      green = '0x' + hexColor[2] + hexColor[2];
      blue = '0x' + hexColor[3] + hexColor[3];
    } else if (hexColor.length === 7) {
      red = '0x' + hexColor[1] + hexColor[2];
      green = '0x' + hexColor[3] + hexColor[4];
      blue = '0x' + hexColor[5] + hexColor[6];
    }
    return { red, green, blue };
  }

  // private _hexToHSL(hexColor: string): string {
  //   // Convert hex to RGB first
  //   let { red, green, blue } = this._getRGBFromHex(hexColor);

  //   // Then to HSL
  //   red /= 255;
  //   green /= 255;
  //   blue /= 255;

  //   let colorMin = Math.min(red, green, blue);
  //   let colorMax = Math.max(red, green, blue);
  //   let delta = colorMax - colorMin;
  //   let hue = this._getHueColor(red, green, blue);
  //   let saturation = 0;
  //   let lightness = 0;

  //   lightness = (colorMax + colorMin) / 2;
  //   saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));
  //   saturation = +(saturation * 100).toFixed(1);
  //   lightness = +(lightness * 100).toFixed(1);

  //   return 'hsl(' + hue + ',' + saturation + '%,' + lightness + '%)';
  // }

  private _getHueColor(red: any, green: any, blue: any): number {
    // to HSL
    red /= 255;
    green /= 255;
    blue /= 255;

    let colorMin = Math.min(red, green, blue);
    let colorMax = Math.max(red, green, blue);
    let delta = colorMax - colorMin;
    let hue = 0;

    if (delta === 0)
      hue = 0;
    else if (colorMax === red)
      hue = ((green - blue) / delta) % 6;
    else if (colorMax === green)
      hue = (blue - red) / delta + 2;
    else
      hue = (red - green) / delta + 4;

    hue = Math.round(hue * 60);

    if (hue < 0) {
      hue += 360;
    }

    return hue;
  }
}
