<div class="messageus">
    <div class="messageus-header op-p-4 op-d-flex op-align-items-center">
        <svg-icon name="chat-off"></svg-icon>
        <span class="op-ml-4" [innerHTML]="'messageUs.sendAMessage' | translate"></span>
    </div>
    <div class="messageus-body op-px-4 op-pt-3">
        <ng-container *ngIf="!messageSend">
            <p class="op-m-0" [innerHTML]="'messageUs.enterInfo' | translate"></p>
            <form [formGroup]="messageUsForm">
                <div class="form-field op-my-3">
                    <input formControlName="name" [placeholder]="'messageUs.name' | translate">
                </div>
                <div class="option-buttons op-my-3" ngbRadioGroup name="radioBasic" formControlName="options">
                    <span class="op-d-flex" [innerHTML]="'messageUs.replyMethod' | translate"></span>
                    <div class="op-d-flex op-justify-content-between op-mt-3">
                        <label class="op-d-flex op-flex-column op-justify-content-center op-align-items-center pointer"
                               ngbButtonLabel *ngFor="let option of messageOptions">
                            <input ngbButton type="radio" [value]="option">
                            <svg-icon name="{{option}}"></svg-icon>
                            <span>{{option}}</span>
                        </label>
                    </div>
                </div>
                <div class="form-field op-my-3" *ngIf="messageType === messageOptions[0]">
                    <input formControlName="email" [placeholder]="'messageUs.email' | translate">
                    <small class="text-danger"
                           *ngIf="messageUsForm.get('email').errors &&
                            messageUsForm.get('email').invalid &&
                            messageUsForm.get('email').touched" [innerHTML]="'userFormErrors.email' | translate">
                    </small>
                </div>
                <div class="form-field op-my-3"  *ngIf="messageType !== messageOptions[0]">
                    <input formControlName="phone" [placeholder]="'messageUs.phone' | translate"
                           [imask]="{mask: '(000) 000-0000'}">
                    <small class="text-danger"
                           *ngIf="messageUsForm.get('phone').errors &&
                            messageUsForm.get('phone').invalid &&
                            messageUsForm.get('phone').touched" [innerHTML]="'userFormErrors.phone' | translate">
                    </small>
                </div>
                <div class="form-field op-mt-3">
                    <textarea formControlName="message" [placeholder]="'messageUs.howCanIHelp' | translate"></textarea>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="messageSend">
            <div class="success">
                <div class="op-d-flex op-justify-content-center">
                    <svg-icon name="success" class="op-m-3"></svg-icon>
                </div>
                <h3 class="op-mt-3" [innerHTML]="'messageUs.thanksMessage' | translate"></h3>
                <div class="op-my-3">
                    <span [innerHTML]="'messageUs.contactMessage' | translate"></span>
                    <b class="op-ml-1" [innerHTML]="'messageUs.twoBusiness' | translate"></b>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="messageus-footer op-p-4 op-d-flex op-justify-content-between op-align-items-center">
        <ng-container *ngIf="!messageSend">
            <button class="op-btn op-btn-danger op-btn-basic op-w-50" [innerHTML]="'common.cancel' | translate" (click)="close()"></button>
            <button class="op-btn op-btn-primary op-w-50"
                    [disabled]="messageUsForm.invalid"
                    (click)="createGuest()"
                    [innerHTML]="'messageUs.send' | translate"></button>
        </ng-container>
        <ng-container *ngIf="messageSend">
            <button class="op-btn op-btn-primary op-w-100"
                    [innerHTML]="'common.close' | translate" (click)="close()"></button>
        </ng-container>
    </div>
</div>
