import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Participant, ParticipantRes } from 'src/app/components/video-call/video-call.types';
import { ScheduledInfoCreate, SubmitBookingSession } from 'src/app/core/constants/booking-appointment.modal';
import { FeedbackReq } from 'src/app/core/constants/feedback.modal';
import { AgentResponseData, BookingConfig, GetAgentInfoResponse } from '../../constants/agent.modal';
import { CreateGuestRes, CreateReq, NewChannelData, OutbounRejectReq, Queue } from '../../constants/call.modal';
import { InviteReq } from '../../constants/chat.modal';
import {
  CommonResponse,
  InCallState,
  InviteData, OminiReq, RoomEndSocket
} from '../../constants/common.enum';
import { Constants } from '../../constants/constant';
import { HttpWrapperService } from '../http-service/http-wrapper.service';
import { SnowplowService } from '../snowplow/snowplow.service';
import { SocketService } from '../socket/socket.service';
@Injectable({
  providedIn: 'root'
})
export class VideoCallService {
  licenseKey: string;

  public inCall$ = new Subject<InCallState>();
  public closePluginWithoutFeedback$ = new Subject<boolean>();

  constructor(private httpClientWrapper: HttpWrapperService,
    private snowplowService: SnowplowService,
    private socketService: SocketService,
    @Inject(Constants.licenseKey) licenseKey: string) {
    this.licenseKey = licenseKey;
  }

  getAgentStatus(): Observable<AgentResponseData | any> {
    const sessionId: string = this.snowplowService.getSnowplowSessionId();
    const headers = new HttpHeaders().set('licensekey', this.licenseKey);
    return this.httpClientWrapper.get(Constants.apiPaths.getAgentStatus, {domain_sessionid: sessionId}, { headers })
      .pipe(map((obj: GetAgentInfoResponse) => obj.data))
      .pipe(catchError(e => throwError(e)));
  }

  getRoomFromInvite(inviteId: string): Observable<InviteData> {
    return this.httpClientWrapper.get(`${Constants.apiPaths.getRoomFromInvite}/${inviteId}`, {}, {})
      .pipe(map((obj: InviteData | any) => {
        this.socketService.joinRoom(obj?.data?.room_name);
        return obj?.data
      }))
      .pipe(catchError(e => throwError(e)));
  }

  createGuest(req: CreateReq): Observable<CreateGuestRes> {
    return this.httpClientWrapper.post(Constants.apiPaths.createGuest, req, {})
      .pipe(map((obj: CreateGuestRes | any) => obj))
      .pipe(catchError(e => throwError(e)));
  }

  createChannel(): Observable<NewChannelData | any> {
    return this.httpClientWrapper.post(Constants.apiPaths.createChannel, {}, {})
      .pipe(map((obj: any) => obj?.channel))
      .pipe(catchError(e => throwError(e)));
  }

  addUserToQueue(guestToken: string, queueId: number, req: any): Observable<Queue | any> {
    const headers = new HttpHeaders().set('guesttoken', guestToken)
      .set('licensekey', this.licenseKey);
    return this.httpClientWrapper.post(`${Constants.apiPaths.addUserToQueue}/${queueId}`, req, { headers })
      .pipe(map((obj: any) => obj?.data))
      .pipe(catchError(e => throwError(e)));
  }

  checkQueueProgress(guestToken: string, queueId: number): Observable<NewChannelData | any> {
    const headers = new HttpHeaders().set('guesttoken', guestToken);
    return this.httpClientWrapper.get(`${Constants.apiPaths.checkQueue}/${queueId}`, {}, { headers })
      .pipe(map((obj: any) => obj?.data))
      .pipe(catchError(e => throwError(e)));
  }

  getToken(identity: string): Observable<string | any> {
    return this.httpClientWrapper.post(`${Constants.apiPaths.getToken}?identity=${identity}`, {}, {})
      .pipe(map((obj: any) => obj.token))
      .pipe(catchError(e => throwError(e)));
  }

  sendInitiation(inviteReq: InviteReq): Observable<string | null> {
    return this.httpClientWrapper.post(Constants.apiPaths.sendInvite, inviteReq)
      .pipe(map((obj: any) => obj))
      .pipe(catchError(e => throwError(e)));
  }

  submitLiveChatInfo(liveChatData: SubmitBookingSession): Observable<ScheduledInfoCreate | any> {
    const headers = new HttpHeaders().set('licensekey', this.licenseKey);
    return this.httpClientWrapper.post(`${Constants.apiPaths.submitBookingInfo}`, liveChatData, { headers })
      .pipe(map((obj: any) => obj?.data))
      .pipe(catchError(e => throwError(e)));
  }

  updateScheduleInfo(inviteCode: string, body: any): Observable<any> {
    const headers = new HttpHeaders().set('licensekey', this.licenseKey);
    return this.httpClientWrapper.post(`${Constants.apiPaths.updateScheduleInfo}/${inviteCode}`, body, { headers })
      .pipe(map((obj: any) => obj?.data))
      .pipe(catchError(e => throwError(e)));

  }

  queuePositionUpdate(queuePositionId: number, guestToken: string, body: any): Observable<any> {
    const headers = new HttpHeaders().set('licensekey', this.licenseKey).set('guesttoken', guestToken);
    if (queuePositionId) {
      return this.httpClientWrapper.post(`${Constants.apiPaths.queuePositionUpdate}/${queuePositionId}`, body, { headers })
          .pipe(map((obj: any) => obj?.data))
          .pipe(catchError(e => throwError(e)));
    } else {
      console.log('queue position id is missing', queuePositionId);
      return of ('error')
    }
  }

  getRoomStatus(roomName: string): Observable<any> {
    return this.httpClientWrapper.post(`${Constants.apiPaths.videoStatus}?roomName=${roomName}`, {})
      .pipe(map((obj: any) => obj?.room))
      .pipe(catchError(e => throwError(e)));
  }

  getUnconsumedMessageIndex(roomName: string, identity: string): Observable<any> {
    return this.httpClientWrapper.post(Constants.apiPaths.getUnconsumedMessageIndex,
      { channelSid: roomName, identity: identity })
      .pipe(map((obj: any) => obj?.lastIndex))
      .pipe(catchError(e => throwError(e)));
  }

  acceptOutbound(guestToken: string, roomName: string): Observable<any> {
    const headers = new HttpHeaders().set('guesttoken', guestToken);
    return this.httpClientWrapper.post(Constants.apiPaths.outboundAccept, { room_name: roomName }, { headers })
      .pipe(map((resp: any) => resp))
      .pipe(catchError(e => throwError(e)));
  }

  rejectedOutbound(guestToken: string, req: OutbounRejectReq): Observable<RoomEndSocket> {
    const headers = new HttpHeaders().set('guesttoken', guestToken);
    return this.httpClientWrapper.post(Constants.apiPaths.outboundReject, req, { headers })
      .pipe(map((resp: RoomEndSocket) => resp))
      .pipe(catchError(e => throwError(e)));
  }

  submitFeedback(req: FeedbackReq, guestToken: string) {
    const headers = new HttpHeaders().set('guesttoken', guestToken);
    return this.httpClientWrapper.post(Constants.apiPaths.feedbackCreate, req, { headers })
      .pipe(map((res: CommonResponse) => {
        return res;
      })
      ).pipe(
        catchError(e => {
          return throwError(e);
        })
      );
  }

  ominiChannelSend(OminiReq: OminiReq, guestToken: string): Observable<string | any> {
    const headers = new HttpHeaders({ 'licensekey': this.licenseKey, guesttoken: guestToken });
    return this.httpClientWrapper.post(Constants.apiPaths.ominiChannelSend, OminiReq, { headers })
      .pipe(map((obj: any) => obj))
      .pipe(catchError(e => throwError(e)));
  }

  getBookingConfig(): Observable<BookingConfig | any> {
    const headers = new HttpHeaders().set('licensekey', this.licenseKey);
    return this.httpClientWrapper.get(Constants.apiPaths.readBookingConfig, {}, { headers })
      .pipe(map((obj: any) => obj.data.booking_config))
      .pipe(catchError(e => throwError(e)));
  }

  getParticipantList(roomName: string): Observable<Participant[]> {
    return this.httpClientWrapper
      .post(`${Constants.apiPaths.getMembers}?channelSid=${roomName}`, {})
      .pipe(map((res: ParticipantRes) => { return res.members; }))
      .pipe(catchError((e) => { return throwError(e); }));
  }

}
