<button class="control-button-container" [ngClass]="{'maximize': maximize}" (click)="toggleButton()"
  [disabled]="isDisabled">
  <svg-icon [name]="iconNameOff" *ngIf="!isOn"></svg-icon>
  <svg-icon [name]="iconNameOn" *ngIf="isOn"></svg-icon>
  <ng-container *ngIf="showError">
    <div class="op-popover">
      <div class="op-popover-body op-d-flex op-justify-content-between op-align-items-center">
        <span class="op-popover-arrow"></span>
        <span [innerHTML]="errorMessage | translate"></span>
        <button class="op-btn op-btn-basic op-btn-primary" (click)="closeMediaPopover()"
          [innerHTML]="'inCall.ok' | translate"> </button>
      </div>
    </div>
  </ng-container>
  <ng-content></ng-content>
</button>
