import * as appActions from './app.actions';
import * as AppState from './app.states';
import { ActionReducer, MetaReducer } from '@ngrx/store';

const initialState: AppState.State = {
    optimyLocalStore: null,
    optimySessionStore: null,
};

export const metaReducer = (
    reducer: ActionReducer<AppState.State>
): ActionReducer<AppState.State> => {
    return (state, action) => {
        return reducer(state, action);
    };
};

export const metaReducers: MetaReducer<AppState.State>[] = [metaReducer];

export function appReducer(
    state = initialState,
    action: appActions.Action
): AppState.State {
    switch (action.type) {
        case appActions.SET_LOCAL_STORE_INFO: {
            return {
                ...state,
                optimyLocalStore: action.optimyLocalStore,
            };
        }
        case appActions.SET_SESSION_STORE_INFO: {
            return {
                ...state,
                optimySessionStore: action.optimySessionStore,
            };
        }
        default:
            return state;
    }
}
