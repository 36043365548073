import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from "@angular/core";
import { UtilsService } from "../services/utils/utils.service";
import { PluginStates } from "./common.enum";
import * as moment from 'moment';

@Injectable({providedIn: 'root'})

export class DatepickerI18n extends NgbDatepickerI18n {
  currentLanguage: string;
  i18n_values: any;

  constructor(private utils: UtilsService) {
    super();
    this.currentLanguage = this.utils.checkAndGetCurrentLang();
    moment.updateLocale(this.currentLanguage, { week: {
        dow: 1,
        doy: 7
      }});
    this.i18n_values = {
      weekdays: moment.weekdaysMin(true),
      months: moment.monthsShort()
    }
  }

  getWeekdayShortName(weekday: number): string {
    return this.i18n_values.weekdays[weekday - 1];
  }
  getWeekLabel(): string { return this.i18n_values.weekLabel; }
  getMonthShortName(month: number): string { return this.i18n_values.months[month - 1]; }
  getMonthFullName(month: number): string { return this.getMonthShortName(month); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
}
