import {
  AfterViewInit,
  Component,
  ViewChild,
  OnDestroy,
  ElementRef, Output,
  EventEmitter,
  Input
} from '@angular/core';
import * as intlTelInput from 'intl-tel-input';
declare var intlTelInputUtils: any;
import 'node_modules/intl-tel-input/build/js/utils.js';
import { PluginStates } from '../../core/constants/common.enum';
import { UtilsService } from '../../core/services/utils/utils.service';

@Component({
  selector: 'app-intel-tell-input',
  templateUrl: './intel-tell-input.component.html',
  styleUrls: ['./intel-tell-input.component.scss']
})
export class IntelTellInputComponent implements AfterViewInit, OnDestroy {
  @Input() errorMessage: boolean = false;
  @ViewChild('telInput') public telInput: ElementRef;
  @Output() sendPhoneNumber = new EventEmitter<string>();

  iti: any;
  isInvalid = false;
  phoneNumber = '';
  submit = false;

  constructor(private utils: UtilsService){
  }

  ngAfterViewInit(){
    const userInformation = this.utils?.getLocalVal(PluginStates.roomInfo, 'userInfo')?.phone;
    this.iti = intlTelInput(this.telInput.nativeElement, {
      utilsScript: 'node_modules/intl-tel-input/build/js/utils.js',
      nationalMode: false,
      formatOnDisplay: true,
      preferredCountries: ['ca','us']
    });
    this.phoneNumber = userInformation ? userInformation : `+${this.iti.getSelectedCountryData().dialCode}`;
    if(userInformation){
      this.iti.setNumber(this.phoneNumber);
    }
  }

  ngOnDestroy(){
    this.iti.destroy();
  }

  onBlur() {
    this.isInvalid = false;
    this.submit = true;
    if(this.phoneNumber != undefined && this.phoneNumber.trim()){
      if(this.iti.isValidNumber()){
        this.isInvalid = false;
        this.sendPhoneNumber.emit(this.getFormattedPhoneNumber());
      }
      else{
        this.sendPhoneNumber.emit('');
        this.isInvalid = true;
      }
    }
  }

  onInputKeyPress(event: KeyboardEvent) {
    const allowedChars = /[0-9\+\-\ ]/;
    const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
    const allowedOtherKeys = [
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
      'Home',
      'End',
      'Insert',
      'Delete',
      'Backspace',
    ];

    if (
      !allowedChars.test(event.key) &&
      !(event.ctrlKey && allowedCtrlChars.test(event.key)) &&
      !allowedOtherKeys.includes(event.key)
    ) {
      event.preventDefault();
    }
  }

  formatIntlTelInput() {
    if (typeof intlTelInputUtils !== 'undefined') {
      const currentText = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
      if (typeof currentText === 'string') {
        this.iti.setNumber(currentText);
      }
    }
  }

  onPhoneNumberChange() {
    this.formatIntlTelInput();
    if(this.phoneNumber != undefined && this.phoneNumber.trim() && this.iti.isValidNumber()){
      this.sendPhoneNumber.emit(this.getFormattedPhoneNumber());
    } else {
      this.sendPhoneNumber.emit('');
    }
    if(this.submit){
      this.onBlur();
    }
  }

  getFormattedPhoneNumber(){
    return this.iti.getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL);
  }
}
