<div [formGroup]="bookingForm" class="op-mb-3">
    <div class="form-field op-mb-3">
        <input type="text" class="op-mb-2" [placeholder]="'placeholders.fullName' | translate" [formControlName]="'full_name'" [ngClass]="{ 'is-invalid' : getFormControlInfo('full_name')}">
        <small class="text-danger"
               *ngIf="getFormControlInfo('full_name')" [innerHTML]="'bookingAppointment.firstName' | translate">
        </small>
    </div>
    <div class="form-field op-mb-4 op-mt-3">
    <app-intel-tell-input (sendPhoneNumber)="setPhoneNumberValue($event)"></app-intel-tell-input>
    </div>
    <div class="form-field op-my-3">
        <input aria-label="email" class="op-mb-2" [placeholder]="'placeholders.emailId' | translate" type="text" [formControlName]="'email'" [ngClass]="{ 'is-invalid' : getFormControlInfo('email')}">
        <small class="text-danger"
               *ngIf="getFormControlInfo('email') && !getFormControlForEmailRegex()" [innerHTML]="'bookingAppointment.email' | translate">
        </small>
        <small class="text-danger" *ngIf="getFormControlForEmailRegex()" [innerHTML]="'bookingAppointment.salesAgencyEmailError'| translate"></small>
    </div>
    <div class="form-field op-my-3">
        <input type="text" class="op-mb-2" [placeholder]="'placeholders.companyName' | translate" [formControlName]="'company_name'" [ngClass]="{ 'is-invalid' : getFormControlInfo('company_name')}">
        <small class="text-danger"
               *ngIf="getFormControlInfo('company_name')" [innerHTML]="'bookingAppointment.responseRequired' | translate">
        </small>
    </div>
    <div class="form-field op-my-3">
        <input type="text" class="op-mb-2" [placeholder]="'placeholders.roleYouAreHiringFor' | translate" [formControlName]="'hiring_for'" [ngClass]="{ 'is-invalid' : getFormControlInfo('hiring_for')}">
        <small class="text-danger"
               *ngIf="getFormControlInfo('hiring_for')" [innerHTML]="'bookingAppointment.responseRequired' | translate">
        </small>
    </div>
    <div class="form-field op-my-3">
      <textarea aria-label="specifyRequest" [placeholder]="'placeholders.specifyRequest' | translate" [(ngModel)]="characterCount" maxlength="1500" type="text" [formControlName]="'comment'"
                [ngClass]="{ 'is-invalid' : getFormControlInfo('comment')}" rows="4" cols="50"></textarea>
        <div class="op-d-flex op-justify-content-end remaining-char" [innerHTML]="'bookingAppointment.charactersLimit' | translate : {characterCount: characterCount?.length, maxCharactersLimit: maxCharactersLimit }"></div>
    </div>
</div>
