import { Component, OnInit, Input } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../../../core/constants/constant';
import {PluginStates} from '../../../../core/constants/common.enum';
import {UtilsService} from '../../../../core/services/utils/utils.service';
import {FormControlNames, MaxCharLength} from '../../../../core/constants/booking-appointment.modal';

@Component({
  selector: 'app-sales-talent-booking-form',
  templateUrl: './sales-talent-booking-form.component.html',
  styleUrls: ['./sales-talent-booking-form.component.scss']
})
export class SalesTalentBookingFormComponent implements OnInit {
  @Input() bookingForm: any;

  isSubmitted = false;
  characterCount = '';
  formControlNamesForSalesTalent = ['full_name', 'email', 'phone', 'company_name', 'hiring_for', 'comment'];
  maxCharactersLimit = MaxCharLength.maxCharactersLimit;

  constructor(private formBuilder: FormBuilder,
              private utils: UtilsService) { }

  ngOnInit(): void {
    const userInformation = this.utils?.getLocalVal(PluginStates.roomInfo, 'userInfo');
    this.setValues(userInformation);
  }

  setValues(userInfo: any) {
    if (userInfo) {
      let i = 0;
      while (i < this.formControlNamesForSalesTalent?.length) {
        const value = this.formControlNamesForSalesTalent[i];
        this.bookingForm?.controls[value]?.setValue(userInfo[value]);
        i++;
      }
    }
    this.characterCount = userInfo?.comment ? userInfo?.comment : '';
  }

  getFormControlInfo(name: string) {
    return (this.bookingForm?.controls[name]?.touched && this.bookingForm?.controls[name]?.errors) ||
        (this.bookingForm?.controls[name]?.errors && this.isSubmitted);
  }

  getFormControlForEmailRegex(){
    return (this.bookingForm?.controls['email']?.touched && this.bookingForm?.controls['email']?.errors?.pattern) ||
        (this.bookingForm?.controls['email']?.errors?.pattern && this.isSubmitted);
  }

  setPhoneNumberValue(value: string){
    this.bookingForm?.get('phone')?.setValue(value);
  }

}
