import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ColorSet } from '../../../core/constants/call.modal';
import { Participant } from '../video-call.types';

@Component({
  selector: 'app-agent-on-call-info',
  templateUrl: './agent-on-call-info.component.html',
  styleUrls: ['./agent-on-call-info.component.scss']
})
export class AgentOnCallInfoComponent implements OnInit, OnChanges {
  @Input() participants: Participant[];

  agentParticipants: Participant[] = [];

  colorList = ColorSet;

  constructor() { }

  ngOnInit(): void {
    this.updatedCallAgents();
  }
  ngOnChanges(){
    this.updatedCallAgents();
  }

  updatedCallAgents() {
    this.agentParticipants = [];
    this.participants?.length ?
    this.participants.map((item: any)=>{
      if(!item?.identity?.includes('guest') && !item?.attributes?.disconnected_timestamp){
          this.agentParticipants.push({
              ...item,
              shortName: item?.attributes.first_name
                  ? `${item?.attributes.first_name?.charAt(
                      0
                  )}${item?.attributes.last_name?.charAt(0)}`
                  : item?.identity
                      ? item?.identity?.charAt(0)
                      : ''
          })
      }
    }) : this.agentParticipants = [];
  }
}
