<div class="call-container" [ngClass]="{
  'maximize': maximize,
  'minimize': minimize,
  'default' : isChatOpen,
  'is-mobile': isMobile || maximizeFull,
  'outbound-video': isOutboundCall}" [hidden]="!agentVideoReady"  #videoDiv>
  <div class="video-div">
    <div class="reload" *ngIf="showReload && !isOutboundCall">
      <span [innerHTML]="'inCall.audioDisruption' | translate"></span>
      <button class="op-btn op-btn-stroked op-btn-primary op-ml-2" (click)="playAllTracks()" appAddSnowplowEvent
              [category]="currentCategory" [action]="spTracker.labels.click"
              [label]="spTracker.labels.reconnect" [context]="context">
        {{'inCall.reconnect' | translate}}
      </button>
    </div>
    <div class="video-section" id="video-section" [ngClass]="{'hide-intermediate-video': showIntermediate, 'video-off' : !agentOnTheVideoCall}">
      <div class="video-header">
        <div class="d-flex mobile-outbound-chat" *ngIf="isOutboundCall">
          <app-chat [identity]="guestUserId" [channelId]="roomName" *ngIf="showMobileChat"
            [isOutboundCall]="isOutboundCall" (showReplyButton)="showReplyButton = true">
          </app-chat>
          <div class="op-d-flex op-flex-column reply-reconnect">
            <button class="op-btn op-btn-flat op-mt-1 op-mb-1" (click)="acceptOutboundCall()" appAddSnowplowEvent
              [category]="spTracker.labels.outboundRequest" [action]="spTracker.labels.reply"
              [label]="spTracker.labels.requestPage" [property]="spTracker.labels.callId"
              [value]="roomInfo?.queuePositionId" [context]="context" *ngIf="showReplyButton">
              {{ 'common.reply' | translate}}</button>
            <button class="op-btn op-btn-danger op-btn-stroked op-mt-1 op-mb-1" (click)="playAllTracks()"
              [innerHTML]="'inCall.reconnect' | translate" *ngIf="showReload" appAddSnowplowEvent
              [category]="currentCategory" [action]="spTracker.labels.click"
              [label]="spTracker.labels.reconnect" [context]="context"></button>
          </div>
        </div>
        <div class="action-buttons-fullscreen" *ngIf="maximize && showMaximizeActions">
          <button class="op-btn action-button maximize" (click)="desktopMax()" *ngIf="!isMobile"
                  appAddSnowplowEvent [category]="currentCategory" [action]="spTracker.labels.click"
                  [label]="maximizeFull ? spTracker.labels.minimize : spTracker.labels.maximize" [context]="context">
            <svg-icon name="minimize" *ngIf="maximizeFull"></svg-icon>
            <svg-icon name="maximize" *ngIf="!maximizeFull"></svg-icon>
          </button>
          <button class="op-btn action-button leave-call" (click)="leaveCallButtonClicked = true"
                  appAddSnowplowEvent [category]="currentCategory" [action]="spTracker.labels.click"
                  [label]="spTracker.labels.endCall" [context]="context">
            <svg-icon name="phone-hangup"></svg-icon>
          </button>
          <ng-container *ngTemplateOutlet="leaveCallPopUp"></ng-container>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="inCall"></ng-container>
      <ng-container *ngIf="!isOutboundCall">
        <div class="app-chat-container" [ngClass]="{'fade-out': !isChatOpen}">
          <div class="action-buttons-container" *ngIf="isChatOpen && isMobile" [@fadeAnimationActionButtons]>
            <div class="action-buttons">
              <button class="op-btn action-button minimize-plugin" (click)="minimizePlugin()" appAddSnowplowEvent
                      [category]="currentCategory" [action]="spTracker.labels.click"
                      [label]="spTracker.labels.minimize" [context]="context">
                <svg-icon name="window-minimize"></svg-icon>
              </button>
              <button class="op-btn action-button leave-call close" (click)="leaveCallButtonClicked = true"
                appAddSnowplowEvent [category]="currentCategory" [action]="spTracker.labels.click"
                [label]="spTracker.labels.endCall" [context]="context">
                <svg-icon name="close-menu"></svg-icon>
              </button>
              <ng-container *ngTemplateOutlet="leaveCallPopUp"></ng-container>
            </div>
          </div>
          <app-chat [@fadeAnimation] [identity]="guestUserId" [channelId]="roomName" *ngIf="isChatOpen" [unreadMessageCount]="unreadMessageCount"
            [isOutboundCall]="isOutboundCall" [currentTypingUsers]="currentTypingUsers" [isUserInQueue]="isUserInQueue"
            [isMobile]="isMobile" [participants]="participants" [totalNumberOfAgents]="totalNumberOfAgents" [category]="currentCategory"
                    [agentName]="agentName" (minimize)="minimizePlugin()" (updateUnreadMsgCount)="updateUnreadCount($event)" (closeMenu)="leaveCallButtonClicked = true">
          </app-chat>
          <div *ngIf="!isMobile">
            <ng-container *ngTemplateOutlet="leaveCallPopUp"></ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- New Modals begin -->
<app-settings-modal *ngIf="showSettingsModal" (closeModalEvent)="closeModalEvent($event)">
</app-settings-modal>
<!-- New Modals end -->

<optimy-modal [openModal]="openModalSub.asObservable()" [closeOnOutsideClick]="true"
  [closeModal]="closeModalSub.asObservable()" (modalClosed)="modalClosed()">
  <app-invite-guest *ngIf="showInviteForm" (inviteFeedBack)="closeInviteForm()">
  </app-invite-guest>
  <app-permission-popup [deviceName]="deviceName" (onBack)="onClickOnPermissionModal()" *ngIf="showPermissionPopup">
  </app-permission-popup>
</optimy-modal>

<ng-template #leaveCallPopUp>
  <div class="op-overlay" *ngIf="leaveCallButtonClicked" (click)="leaveCallButtonClicked = false;"></div>
  <div class="leave-call-popup-container op-d-flex op-flex-column" [ngClass]="{'leave-desktop': !isMobile}"*ngIf="leaveCallButtonClicked"
    (click)="leaveCallButtonClicked = false">
    <button class="op-btn op-btn-danger op-btn-flat op-btn-round op-mb-2 op-flex-1" appAddSnowplowEvent
      [category]="currentCategory" [action]="spTracker.labels.confirm" [label]="spTracker.labels.endCall"
      [property]="spTracker.properties.callId" [value]="roomInfo.queuePositionId" [context]="context" (click)="leaveCall()">
      {{ 'inCall.leaveThisChat'| translate}}
    </button>
    <button class="op-btn op-btn-stroked op-btn-light op-btn-round op-flex-1" appAddSnowplowEvent
      [category]="currentCategory" [action]="spTracker.actions.cancelButton"
      [label]="spTracker.labels.endCall" [context]="context">
      {{ 'inCall.stayOnTheChat'| translate}}
    </button>
  </div>
</ng-template>

<ng-template #inCall>
  <div class="participant-container" id="guest-container">
    <div class="video-participants-container" #mainVideoContainer [ngClass]="{'fade': isMenuOpen, 'click-disabled': disableParticipantClick}"
         (click)="executePluginInsideClick($event)">
      <div #agentScreenShare class="screen-share"></div>
      <div class="remote-agent-video" #agentVideoContainer
           [ngClass]="{'remote-agent-outbound': isOutboundCall && agentVideoReady && !isFirstInitiated }">
        <div class="agent participant op-d-flex op-justify-content-center op-align-items-center"
             *ngIf="isUserInQueue" id="connecting">
          <div class="agent-connecting-icon op-d-flex op-justify-content-center">
            <object type="image/svg+xml"
                    data="https://dx9457ojp328v.cloudfront.net/icons/waiting-animated-three-dots-white.svg">
            </object>
          </div>
        </div>
      </div>
      <div #guestVideoContainer *ngIf="!isOutboundCall" class="guests-video"
        [attr.data-members]="getNumberOfExtraMembers(membersOnCall)">
        <div class="guest local participant" id="local-participant" [attr.data-identity]="localGuestIdentity"
          [ngClass]="{'mic-off': !this.isMicOn, 'video-off': !this.isCameraOn}" #local>
        </div>
      </div>
    </div>
    <div class="call-menu">
      <ng-container *ngIf="!isOutboundCall">
      <span class="new-message" *ngIf="(showNotification || (unreadMessageCount && unreadMessageCount > 0))
        && !this.isChatOpen && !isMenuOpen">{{unreadMessageCount}}</span>
        <div class="last-message" *ngIf="newMessage && !isChatOpen && newMessage?.author !== 'system'" [@fadeAnimation] (click)="previewClick()">
          <div class="message-close op-d-flex op-justify-content-end">
            <svg-icon name="bubble-close" (click)="closeNewMessage($event)"></svg-icon>
          </div>
          <b>{{newMessage.author}}</b>
          <p [innerHTML]="newMessage.body"></p>
        </div>
      </ng-container>
      <app-controls [menuList]="menuList" [isMicOn]="isMicOn" [isCameraOn]="isCameraOn"
        [showNotification]="showNotification" [deviceList]="deviceList" [unreadMessageCount]="unreadMessageCount"
        [isOutboundCallOrMinimize]="isOutboundCall || minimize" (toggleMicrophoneEvent)="toggleMicrophone($event)"
        (toggleCameraEvent)="toggleCamera($event)" [isUserInQueue]="isUserInQueue" (switchCameraEvent)="switchCamera($event)"
        (inviteEvent)="openInviteForm()" (settingsEvent)="openConfigModal()" (toggleMenuEvent)="toggleMenu($event)"
        (toggleFullScreenEvent)="toggleFullScreen(true)">
      </app-controls>
    </div>
  </div>
</ng-template>
