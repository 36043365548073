<div class="agent-list">
    <h5>{{'intermediateScreen.ourLiveTeam' | translate}}</h5>
    <div class="op-d-flex op-align-items-center op-justify-content-around">
        <ng-container *ngFor="let agent of agentList; let i = index;">
            <div class="agent op-d-flex op-align-items-center op-flex-column" *ngIf="i <= 2">
                <div class="agent-profile op-d-flex op-align-items-center op-justify-content-center"
                     [ngStyle]="{'background-color' : colorList[i]?.bgColor, 'color': colorList[i]?.textColor, 'opacity': agent?.agent_status === 'Offline' ? 0.5 : 1}" >
                    <img *ngIf="agent?.agent_avatar" [src]="agent?.agent_avatar" alt="Agent Profile">
                    <span *ngIf="!agent?.agent_avatar">{{agent?.agentShortName}}</span>
                </div>
                <div class="name">{{agent?.agent_first_name}}</div>
                <span class="indicator" [ngStyle]="{'background-color' : agent?.agent_status === 'Offline' ? statusColor.offlineColor : statusColor.onlineColor}"></span>
            </div>
        </ng-container>
    </div>
</div>
