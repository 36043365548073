import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Feedback, FeedbackBodyParams, FeedbackReq, MaxCharLengthFeedback, Rating } from 'src/app/core/constants/feedback.modal';
import { AgentStatus } from '../../core/constants/agent.modal';
import { PluginStates, RoomInfoStates, RoutesUrls } from '../../core/constants/common.enum';
import { Constants } from '../../core/constants/constant';
import { UtilsService } from '../../core/services/utils/utils.service';
import { VideoCallService } from '../../core/services/video-call/video-call.service';
import { SnowplowService } from '../../core/services/snowplow/snowplow.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss']
})
export class UserFeedbackComponent implements OnInit {
  rating: number;
  hoverRating: number;
  characterCount = '';
  maxCharactersLimit = MaxCharLengthFeedback.maxCharactersLimit;
  comments: string;
  isSubmitted = false;
  agentName: string;
  spTrackerLabels = Constants.spTrackerLabels;

  constructor(private utils: UtilsService,
    private videoCallService: VideoCallService,
    private router: Router,
    private snowplowService: SnowplowService,
    private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.agentName = this.utils.getSessionVal(RoomInfoStates.agentName);
    this.snowplowService.trackStructEvent(this.spTrackerLabels.callRating,
        this.spTrackerLabels.screenShown, this.spTrackerLabels.rate);
  }

  @HostListener('click')
  enableStar(i: number, event: any) {
    if (i) {
      event?.stopPropagation();
      this.rating = i;
    }
  }

  @HostListener('mouseover')
  starHover(i: number, event: any) {
    if (i) {
      event?.stopPropagation();
      this.hoverRating = i;
    }
  }

  startLeave(i: number, event: any) {
    if (i) {
      event?.stopPropagation();
      this.hoverRating = 0;
    }
  }

  counter(i: number) {
    return new Array(i);
  }

  onSubmitForm() {
    const agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    const guestToken = this.utils.getSessionVal(RoomInfoStates.guestToken);
    const rating: Rating = {
      type: FeedbackBodyParams.star,
      value: `${this.rating}`,
      max_value: FeedbackBodyParams.ratingMaxVal
    }
    let feedback: Feedback = {
      comment: this.comments ? this.comments : '',
      name: FeedbackBodyParams.saveCustomerFeedback,
      rating: rating
    };

    const req: FeedbackReq = {
      tenantid: agentDetails?.tenant_id,
      event_type: FeedbackBodyParams.save,
      event_id: `${this.utils.getSessionVal(RoomInfoStates.queuePositionId)}`,
      for_userid: 0,
      feedback: JSON.stringify(feedback)
    };
    this.videoCallService.submitFeedback(req, guestToken).subscribe(() => {
      this.isSubmitted = true;
      this.toastr.success(this.translate.instant('userFeedback.thanksForFeedback'));
      this.onCloseClick();
      this.removeLocalStorage();
      this.snowplowService.trackStructEvent(this.spTrackerLabels.callRating,
          this.spTrackerLabels.screenShown, this.spTrackerLabels.thank);
    });
  }

  skip() {
    this.removeLocalStorage();
    this.onCloseClick();
  }

  onCloseClick() {
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }], { skipLocationChange: true});
  }

  removeLocalStorage() {
    this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo]);
    this.utils.removeSessionStorage();
  }

  getComments(event: any) {
    this.comments = event;
  }

}
