import { Injectable } from '@angular/core';

declare global {
  interface Window { dataLayer: any; }
}

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  constructor() { }

  sendGtm(key: string) {
    if (window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': key
      });
    }
  }
}
