<div class="booking-cancel" *ngIf="showCancelConfirmation">
  <div class="op-mt-3 op-mb-3 booking-cancel-title">
    {{ 'bookingAppointment.continueToCancel' | translate}}
  </div>
  <div class="op-mt-3 op-mb-3" [innerHTML]="'bookingAppointment.scheduleDate' | translate: {cancelDate} "></div>
  <div class="op-d-flex op-flex-column">
    <button class="op-btn op-btn-flat op-btn-primary op-flex-1 op-mb-4"
      [innerHTML]="'bookingAppointment.keepSession' | translate" appAddSnowplowEvent
      [category]="spTrackerLabels.bookingCancel" [label]=" spTrackerLabels.cancelConfirmation"
      [action]="spTrackerLabels.keepSession" (click)="onBackClick()"></button>
    <button class="op-btn op-btn-danger op-btn-basic op-flex-1" (click)="onCancelSession()" appAddSnowplowEvent
      [category]="spTrackerLabels.bookingCancel" [label]=" spTrackerLabels.cancelConfirmation"
      [action]="spTrackerLabels.cancelSession" [innerHTML]="'bookingAppointment.yesCancelSession' | translate"></button>
  </div>
</div>