<input type="tel"
       (blur)="onBlur()"
       (keypress)="onInputKeyPress($event)"
       [(ngModel)]="phoneNumber"
       (ngModelChange)="onPhoneNumberChange()"
       [placeholder]="'placeholders.cellPhone' | translate"
       [ngClass]="{ 'is-invalid' : isInvalid}"
       #telInput>
<small class="error op-mt-2"
       *ngIf="isInvalid && !errorMessage" [innerHTML]="'common.internationalPhoneNumberError' | translate">
</small>
<small class="error op-mt-2"
       *ngIf="errorMessage && isInvalid" [innerHTML]="'leaveMessage.mobileNumberError' | translate">
</small>
