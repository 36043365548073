import {
  Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllInputFields } from 'src/app/core/constants/booking-appointment.modal';
import { InviteCodeParams, PluginStates, RoomInfoStates } from 'src/app/core/constants/common.enum';
import { Constants } from 'src/app/core/constants/constant';
import { SnowplowService } from 'src/app/core/services/snowplow/snowplow.service';
import { environment } from 'src/environments/environment';
import { PostMessageData } from '../models/models';
import { UtilsService } from '../../../../core/services/utils/utils.service';

@Component({
  selector: 'app-iframe-config',
  templateUrl: './iframe-config.component.html',
  styleUrls: ['./iframe-config.component.scss']
})
export class IframeConfigComponent implements OnInit, OnChanges {
  @Input() btnValue: string;
  @Input() configData: any = {};
  @Input() allInputFields: AllInputFields[] = [];
  @Input() roomInfo: RoomInfoStates;
  @Input() formVersion: number;
  @Input() isBooking: boolean;
  @Input() modalToShow: number;
  @Output() submitIFrameFormEvent = new EventEmitter();
  @ViewChild('iframe') iframe: ElementRef;

  configIframeUrl: SafeResourceUrl;
  postMessageData: PostMessageData;
  s3BaseUrl = ' https://d34dprqztr098w.cloudfront.net';
  spTrackerLabels = Constants.spTrackerLabels;

  hasLoadValues: boolean = false;

  withInvite = false;

  constructor(private sanitizer: DomSanitizer,
              private snowPlowService: SnowplowService,
              private spinner: NgxSpinnerService,
              private utils: UtilsService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.configIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.s3BaseUrl}/${environment.environmentName}/survey.html`);
    const currentLocation = location.href;
    this.withInvite = currentLocation.includes(InviteCodeParams.code) || currentLocation.includes(InviteCodeParams.optimyCode);
    if (this.withInvite) {
      this.snowPlowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.screenShown,
        this.spTrackerLabels.customForm, this.spTrackerLabels.version, 1);
    } else {
      this.snowPlowService.trackStructEvent(this.isBooking ? this.spTrackerLabels.bookingForm :
        this.spTrackerLabels.inboundForm, this.spTrackerLabels.screenShown,
        this.spTrackerLabels.customForm, this.spTrackerLabels.version, 1);
    }
  }

  ngOnChanges() {
    if(this.modalToShow === 2){
      this.onLoad();
    }
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    if (event?.data) {
      const formValues = typeof event.data === 'string' ? JSON.parse(event.data) : {};
      if (typeof event.data === 'string' && !formValues?.height) {
        this.submitIFrameFormEvent.emit(formValues);
      } else {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
        this.iframe.nativeElement.style.height = `${formValues?.height}px`;
      }
    }
  }

  onLoad() {
    let nativeEl = this.iframe?.nativeElement?.contentWindow;
    const colors = {
      mainColor: document.documentElement.style.getPropertyValue('--button-background-color'),
      borderColor: document.documentElement.style.getPropertyValue('--button-border-color'),
      buttonTextColor: document.documentElement.style.getPropertyValue('--button-foreground-color'),
      highLightTextColor: document.documentElement.style.getPropertyValue('--client-color'),
    };

    this.postMessageData = { formconfig: this.configData, colors: colors, btnValue: this.btnValue, windowHeight: window.innerHeight };
    const userInformation = this.utils?.getLocalVal(PluginStates.roomInfo, 'userInfo');
    if (userInformation) {
      this.postMessageData['userInfo'] = userInformation;
    }
    this.hasLoadValues = true;
    setTimeout(() => {
      this.spinner.hide();
    }, 500);

    nativeEl?.postMessage(JSON.stringify(this.postMessageData), '*');
  }

}
