<div class="queue-question">
    <div class="queue-question-content">
       <p class="op-mb-2">{{currentQuestion?.message}}</p>
        <div class="back op-mt-3 op-d-flex op-justify-content-start op-align-items-center pointer" (click)="goBackToPrevious()" *ngIf="currentQuestionIndex !== 0">
            <svg-icon name="arrow-left"></svg-icon>
            <ng-container  *ngFor="let answer of answeredQuestions;let i = index;">
                <span *ngIf="answer?.questionIndex !== currentQuestionIndex" class="op-ml-2">{{answer?.text}}</span><span *ngIf="i < answeredQuestions?.length -1">,</span>
            </ng-container>
        </div>
        <div class="op-d-flex op-flex-row op-flex-wrap op-justify-content-between op-mt-3 normal" [ngClass]="{'first': currentQuestionIndex === 0}">
            <button class="op-btn op-btn-basic op-mb-3" [ngClass]="{'active': choice?.text === selectedAnswer?.text}"
                    *ngFor="let choice of currentQuestion?.choices; let i = index;" (click)="executeBtnAction(choice, currentQuestionIndex)">
                {{choice?.text}}
            </button>
        </div>
    </div>
    <div class="queue-question-skip pointer" (click)="skipQuestions();">
        <span [innerHTML]="'intermediateScreen.skipButton' | translate"></span>
    </div>
</div>
