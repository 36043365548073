import { Directive, HostListener, Input } from '@angular/core';
import { SnowplowService } from '../../../../core/services/snowplow/snowplow.service';
import { Context } from '../../../../core/constants/common.enum';

@Directive({
    selector: '[appAddSnowplowEvent]'
})
export class AddSnowplowEventDirective {

    @Input()
    public category: string;

    // TODO: Rename this to snowplowLabel to avoid issues on places that might have labels
    @Input()
    public label: string;

    @Input()
    public action: string;

    @Input()
    public property: string;

    @Input()
    public value: number;

    @Input()
    public context: Context[];

    constructor(
        private snowplowService: SnowplowService) {
    }

    @HostListener('click')
    public addGA(): void {
        this.snowplowService.trackStructEvent(this.category, this.action, this.label, this.property, this.value ? this.value : 0, this.context);
    }
}
