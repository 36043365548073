<div class="schedule-live-session-success">
  <div class="success-container op-mt-5">
    <div class="op-d-flex op-justify-content-center op-align-items-center success-text op-flex-column op-mb-2">
      <svg-icon class="success-icon" name="schedule-succsess"></svg-icon>
      <span class="op-mt-2 op-mb-3">{{ (editForm ? 'bookingAppointment.thanksRescheduleSession' :
        'bookingAppointment.thanksScheduleSession') | translate}}</span>
    </div>
    <div class="confirmation-text op-mb-2">
      <span class="session op-mr-2">{{ (editForm ? 'bookingAppointment.confirmationUpdatedText' :
        'bookingAppointment.scheduledLiveSessionInfo') | translate}}</span>
      <span class="appointment-date">{{getDateAndTime()}}</span>
    </div>
    <div *ngIf="editForm" [innerHTML]="'bookingAppointment.previousSessionCancelled' | translate"></div>
    <div class="op-d-flex op-flex-row session-link op-mb-3">
      <span class="op-mr-2" [innerHTML]="'bookingAppointment.scheduledLiveSessionSuccessText1' | translate"></span>
    </div>
    <!-- <div class="session-info op-mb-3 op-mt-2"> -->
    <!-- <div class="op-d-block op-justify-content-start">
        <a class="video-link " (click)="onLinkClick(sessionInfo?.guest_link)">{{sessionInfo?.guest_link}}</a>
      </div> -->
    <!--      <div class="op-d-flex flex-row mb-3 justify-content-start op-align-items-center">-->
    <!--        <i class="op-common-icon warning-icon mr-2"></i>-->
    <!--        <span class="open-link">{{bookingAppointment.sessionInfo}}</span>-->
    <!--    </div>-->
    <!-- // to do -->
    <!-- </div> -->
    <!-- // To do -->
    <!-- <div class="op-d-flex success-info op-mb-3">
      <span class="op-mr-2" [innerHTML]="'bookingAppointment.editSessionText' | translate"></span>
      <a class="pointer reschedule op-mr-2" (click)="onEditSession()" appAddSnowplowEvent [eventLabel]="trackerLabels.bookingRescheduleConfirmationScreen" [innerHTML]="'common.reschedule' | translate"></a>
      <span class="op-mr-2" [innerHTML]="'common.or' | translate"></span>
      <a class="pointer cancel op-mr-2" (click)="onCancelSession('cancel')" appAddSnowplowEvent [eventLabel]="trackerLabels.bookingCancelConfirmationScreen" [innerHTML]="'common.cancel' | translate"></a>
      <span [innerHTML]="'bookingAppointment.editSessionText1' | translate"></span>
    </div> -->
    <div class="op-d-flex op-flex-column download-file op-justify-content-between op-mb-5">
      <button class="op-btn op-btn-stroked op-btn-primary" (click)="addToGoogle()"
        appAddSnowplowEvent [category]="spTrackerLabels.bookingConfirmation"
        [label]="editForm ? spTrackerLabels.confirmationPageReschedule : spTrackerLabels.confirmationPage"
        [action]="spTrackerLabels.addGoogleCalendar">
        <svg-icon name="schedule-add-to-google" class="common-download-icon op-mr-2"></svg-icon>
        <span [innerHTML]="'bookingAppointment.googleCalendar' | translate"></span>
      </button>
      <button class="op-btn op-btn-stroked op-btn-primary op-mt-2"
        (click)="generateIcs()" appAddSnowplowEvent [category]="spTrackerLabels.bookingConfirmation"
        [label]="editForm ? spTrackerLabels.confirmationPageReschedule : spTrackerLabels.confirmationPage"
        [action]="spTrackerLabels.downloadIcs">
        <svg-icon name="schedule-download-ics-file" class="common-download-icon op-mr-2"></svg-icon>
        <span [innerHTML]="'bookingAppointment.icsFile' | translate"></span>
      </button>
    </div>
    <div class="op-d-flex">
      <button class="op-btn op-flex-1 op-btn-flat op-btn-primary" appAddSnowplowEvent
        [category]="spTrackerLabels.bookingConfirmation"
        [label]="editForm ? spTrackerLabels.confirmationPageReschedule : spTrackerLabels.confirmationPage"
        [action]="spTrackerLabels.close" [innerHTML]="'common.close' | translate" (click)="onCloseClick()"></button>
    </div>
  </div>
</div>
