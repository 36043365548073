import { ScreenOrientationService } from 'src/app/core/services/utils/screen-orientation.service';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DeviceList, DeviceTypes, IgnoreDeviceIdTypes } from 'src/app/core/constants/call.modal';
import { Context, DeviceInfo, PluginStates } from 'src/app/core/constants/common.enum';
import { SnowplowTrackerCategories, SnowplowTrackerLabels } from 'src/app/core/constants/trackerLabels';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { createLocalVideoTrack } from 'twilio-video';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class SettingsModalComponent implements OnInit, OnDestroy {

  @Output() closeModalEvent = new EventEmitter();
  @ViewChild('elLocal') elLocal: ElementRef;

  openModalSub: Subject<void> = new Subject<void>();
  closeModalSub: Subject<void> = new Subject<void>();

  deviceList: MediaDeviceInfo[];
  selectedDeviceList: DeviceInfo | any;

  deviceSelectForm: UntypedFormGroup = this.formBuilder.group({
    audioInput: [''],
    audioOutput: [''],
    videoInput: ['']
  });

  audioInputs: DeviceList[] = [];
  videoInputs: DeviceList[] = [];
  audioOutputs: DeviceList[] = [];
  audPermissionDenied = false;
  camPermissionDenied = false;
  deviceInfo: DeviceInfo;
  noOutputDeviceFound = false;
  deviceName = [{
    key: 'audioInDevice',
    controlName: 'audioInput'
  }, {
    key: 'audiOutDevice',
    controlName: 'audioOutput'
  }, {
    key: 'videoInDevice',
    controlName: 'videoInput'
  }];

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories
  };

  currentCategory: string;
  context: Context[];

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: UntypedFormBuilder,
    private snowplowService: SnowplowService,
    private screenOrientationService: ScreenOrientationService,
    private utils: UtilsService) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.context = this.utils.getCallSchemaContext();
    this.updateCurrentCategoryValue();
    this.selectedDeviceList = this.utils.getLocalVal(PluginStates.deviceInfo);
    this.deviceSelectForm.get('videoInput')?.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
    ).subscribe(() => {
      if(!(/iPhone|iPad|iPod/i.test(navigator.userAgent))) {
        this.setCameraPreview();
      }
    });

    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        this.deviceList = devices;
        this.getAudioDeviceList();
        this.getVideoDeviceList();
      });

    navigator.mediaDevices.addEventListener('devicechange', () => {
      this.getAudioDeviceList();
    });
    this.deviceInfo = this.utils.getLocalVal(PluginStates.deviceInfo);
    if (this.selectedDeviceList) {
      this.setValueForDevice(this.selectedDeviceList);
    }
    // the timeout is here to give time for the modal to initialize before we open it.
    setTimeout(() => {
      this.openModalSub.next();
    }, 0);
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.screenShown,
      this.spTracker.labels.genericScreen, this.spTracker.labels.version, 1, this.context);

    this.screenOrientationService.orientationChanged$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateCurrentCategoryValue();
      });
  }

  private updateCurrentCategoryValue(): void {
    if (this.screenOrientationService.isLandscapeAndMobile) {
      this.currentCategory = this.spTracker.categories.callSettingLandscape;
    } else {
      this.currentCategory = this.spTracker.categories.callSetting;
    }
  }

  setValueForDevice(deviceList: any) {
    this.deviceName.forEach((device) => {
      if (this.selectedDeviceList[device.key]) {
        this.deviceSelectForm.controls[device.controlName].setValue(this.selectedDeviceList[device.key]);
      }
    });
  }

  getAudioDeviceList() {
    const allDeviceList = this.deviceList.filter(device => device.deviceId !== IgnoreDeviceIdTypes.default &&
      device.deviceId !== IgnoreDeviceIdTypes.communications);
    this.audioInputs = allDeviceList.filter(device => device.kind === DeviceTypes.audioInput && device.deviceId);
    this.audioOutputs = allDeviceList.filter(device => device.kind === DeviceTypes.audioOutput && device.deviceId);
    this.audPermissionDenied = this.audioInputs?.length === 0;
    this.setDefaultDevices(true, allDeviceList);
  }

  getVideoDeviceList() {
    this.videoInputs = this.deviceList.filter(device => device.kind === DeviceTypes.videoInput && device.deviceId &&
      device.deviceId !== IgnoreDeviceIdTypes.default &&
      device.deviceId !== IgnoreDeviceIdTypes.communications && !device.label.includes(' IR '));
    this.camPermissionDenied = this.videoInputs?.length === 0;
    this.setDefaultDevices(false);
  }

  setDefaultDevices(isAudio: boolean, allDeviceList?: DeviceList[]) {
    if (isAudio) {
      const defaultOutputDevice = allDeviceList &&
        allDeviceList.filter(device => device.kind === DeviceTypes.audioOutput);
      this.deviceSelectForm.patchValue({
        audioInput: this.setDevice(this.audioInputs, this.deviceInfo?.audioInDevice)
      });
      if (defaultOutputDevice?.length) {
        this.noOutputDeviceFound = false;
        this.deviceSelectForm.patchValue({
          audioOutput: this.audioOutputs.length === 0 ? defaultOutputDevice :
            this.setDevice(this.audioOutputs, this.deviceInfo?.audiOutDevice)
        });
      } else {
        this.noOutputDeviceFound = true;
      }
    } else {
      this.deviceSelectForm.patchValue({
        videoInput: this.setDevice(this.videoInputs, this.deviceInfo?.videoInDevice),
      });
    }
  }

  setCameraPreview() {
    createLocalVideoTrack({
      width: 320,
      deviceId: { exact: this.deviceSelectForm.get('videoInput')?.value }
    }).then((track: any) => {
      this.elLocal.nativeElement.querySelector('video')?.remove();
      this.elLocal.nativeElement.style.height = '220px';
      this.elLocal.nativeElement.appendChild(track.attach());
    }).catch(() => {
      console.log('selected camera is not working');
      this.deviceSelectForm.patchValue({
        videoInput: this.videoInputs[0]?.deviceId,
      });
    });
  }

  closeModal(val: any) {
    if (!this.camPermissionDenied) {
      this.elLocal?.nativeElement?.querySelector('video')?.srcObject.getTracks()[0].stop();
    }
    this.closeModalEvent.emit(val);
  }

  setDevice(inputList: DeviceList[], deviceId: string) {
    const index = inputList.findIndex(item => item.deviceId === deviceId);
    return index !== -1 ? inputList[index]?.deviceId : inputList[0]?.deviceId;
  }

  saveConfig() {
    this.closeModal(this.audPermissionDenied && this.camPermissionDenied ? true : {
      audioInput: this.deviceSelectForm.get('audioInput')?.value,
      audioOutput: this.deviceSelectForm.get('audioOutput')?.value,
      videoInput: this.deviceSelectForm.get('videoInput')?.value
    });
  }

}
