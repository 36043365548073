import { ScreenOrientationService } from 'src/app/core/services/utils/screen-orientation.service';
import { SnowplowService } from './../../../../core/services/snowplow/snowplow.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CallInfoStates, CobrowseInfo, PluginStates, RoomInfoStates, RoomInfo } from '../../../../core/constants/common.enum';
import { SocketService } from '../../../../core/services/socket/socket.service';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { Constants } from '../../../../core/constants/constant';
import { SnowplowTrackerAction, SnowplowTrackerCategories, SnowplowTrackerLabels } from 'src/app/core/constants/trackerLabels';
interface DragPosition {
  x: number;
  y: number;
}

@Component({
  selector: 'app-cobrowse-alert',
  templateUrl: './cobrowse-alert.component.html',
  styleUrls: ['./cobrowse-alert.component.scss']
})
export class CobrowseAlertComponent implements OnInit, OnDestroy {

  public dragPosition: DragPosition = { x: 0, y: 0 };
  public hidden = true;

  private componentSize: { height: number, width: number } = { height: 80, width: 250 };
  private roomInfo: RoomInfo;

  currentCategory: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction
  };

  constructor(
    private utils: UtilsService,
    private socketService: SocketService,
    private toastr: ToastrService,
    private snowplowService: SnowplowService,
    private screenOrientationService: ScreenOrientationService
  ) { }

  ngOnInit() {
    // This is related to drag and drop
    // this.dragPosition = this.utils.getSessionVal(PluginStates.cobrowsePosition);
    // this.hidden = !this.dragPosition;
    const callInfo = this.utils.getLocalVal(PluginStates.callInfo);
    if (callInfo?.hasOwnProperty(CallInfoStates.isCobrowseHidden)) {
      this.hidden = callInfo.isCobrowseHidden;
    }
    this.cobrowseInfo();

    this.socketService.roomClose.subscribe(() => {
      this.hidden = true;
    });

    this.screenOrientationService.orientationChanged$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateLandscapeInformation();
      });
    this.updateLandscapeInformation();
    this.sendCobrowseInitialized();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  private updateLandscapeInformation(): void {
    if (this.screenOrientationService.isLandscapeAndMobile) {
      this.currentCategory = this.spTracker.categories.callCobrowseLandscape;
    } else {
      this.currentCategory = this.spTracker.categories.callCobrowse;
    }
  }

  private cobrowseInfo(): void {
    this.socketService.cobrowseInitiated.pipe(takeUntil(this.destroy$)).subscribe((res: CobrowseInfo) => {
      this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
      const roomName = this.utils.getSessionVal(RoomInfoStates.roomName);
      if ((res.roomName === this.roomInfo?.roomName || res.roomName === roomName)
        && this._isCurrentGuestSession(res)) {
        this.hidden = res.cobrowseAction === 'ended';
        if (res.cobrowseAction === 'ended') {
          this.endCobrowseSession();
        }
        this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.isCobrowseHidden], [this.hidden]);
        this.utils.removeSessionStoreVal(Constants.optimySessionStore, [RoomInfoStates.guestId, RoomInfoStates.roomName]);
        if (this.hidden && this.utils.isGuestOnCall()) {
          this.toastr.info('Co-browsing has stopped');
          this.utils.setSessionVal([PluginStates.cobrowsePosition], [undefined]);
        }
      }
      this.sendCobrowseInitialized();
    });
  }

  private _isCurrentGuestSession(cobrowseSessionResponse: CobrowseInfo): boolean {
    return cobrowseSessionResponse?.clientIdentity === this.roomInfo?.guestId
  }

  private sendCobrowseInitialized(): void {
    if (!this.hidden) {
      this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.screenShown,
        this.spTracker.labels.cobrowseScreen, this.spTracker.labels.version, 1);
    }
  }

  public endCobrowseSession(): void {
    if ((<any>window)?.CobrowseIO?.currentSession) {
      (<any>window).CobrowseIO.currentSession.end();
      this.hidden = true;
    }
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.stop, this.spTracker.labels.cobrowseScreen)
  }

  // public dragEnd(event?: any): void {
  //   console.log(event);
  //   console.log(event.source.getFreeDragPosition());
  //   const currentPosition: DragPosition = event.source.getFreeDragPosition();
  //   const newDragPosition: DragPosition = { ...currentPosition };

  //   newDragPosition.x = this.getHorizontalPosition(currentPosition);
  //   newDragPosition.y = this.getVerticalPosition(currentPosition);

  //   this.dragPosition = newDragPosition;
  //   this.utils.setSessionVal([PluginStates.cobrowsePosition], [this.dragPosition]);
  // }

  // private getHorizontalPosition(currentPosition: DragPosition): number {
  //   let result = currentPosition.x;
  //   const intViewportWidth = window.innerWidth;
  //   if (currentPosition.x < ((this.componentSize.width - intViewportWidth))) {
  //     result = this.componentSize.width - intViewportWidth + 20;
  //   } else {
  //     if (currentPosition.x > 10) {
  //       result = 0;
  //     } else {
  //       result = currentPosition.x;
  //     }
  //   }
  //   return result;
  // }

  // private getVerticalPosition(currentPosition: DragPosition): number {
  //   let result = currentPosition.y;
  //   const intViewportHeight = window.innerHeight;
  //   if (currentPosition.y < ((this.componentSize.height - intViewportHeight))) {
  //     result = this.componentSize.height - intViewportHeight + 20;
  //   } else {
  //     if (currentPosition.y > 10) {
  //       result = 0;
  //     } else {
  //       result = currentPosition.y;
  //     }
  //   }
  //   return result;
  // }

}
