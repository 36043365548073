export interface FeedbackReq {
  tenantid: number;
  event_type: string;
  for_userid: number;
  event_id: string;
  feedback: string;
}

export interface Feedback {
  name: string;
  rating?: Rating;
  comment: string;
}

export interface Rating {
  type: string;
  value: string;
  max_value: string;
}

export enum MaxCharLengthFeedback {
  maxCharactersLimit = 1000
}

export const FeedbackBodyParams = {
  save: "save",
  star: "star",
  saveCustomerFeedback: "Save Customer Feedback",
  ratingMaxVal: "5"
}

export const FeedBackQueryParams: string[] = ['resumeWaiting', 'callCompleted'];
