import { Pipe, PipeTransform } from '@angular/core';
import * as marked from 'marked';

@Pipe({
  name: 'marked'
})

export class MarkedPipe implements PipeTransform {
  transform(value: any): any {
    if (value?.length > 0 && marked(value)?.includes('<a')) {
      return marked.parse(value);
      //once chat issue is resolved and passed QA will remove

      // const newDoc = new DOMParser().parseFromString(marked(value), 'text/xml');
      // newDoc.firstElementChild?.firstElementChild?.setAttribute('target', '_blank');
      // return new XMLSerializer().serializeToString(newDoc);
    }
    return value;
  }
}
