import { BookingAppointment, ScheduledInfoCreate } from 'src/app/core/constants/booking-appointment.modal';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { Constants } from 'src/app/core/constants/constant';
import { VideoCallService } from 'src/app/core/services/video-call/video-call.service';
import { SnowplowService } from 'src/app/core/services/snowplow/snowplow.service';
import { PluginStates, RoomInfoStates } from 'src/app/core/constants/common.enum';

@Component({
  selector: 'app-schedule-appointment-edit-cancel',
  templateUrl: './schedule-appointment-edit-cancel.component.html',
  styleUrls: ['./schedule-appointment-edit-cancel.component.scss']
})
export class ScheduleAppointmentEditCancelComponent implements OnInit {
  sessionInfo: ScheduledInfoCreate;
  showEditConfirmation = false;
  showCancelConfirmation = false;
  cancelledSchedule = false;
  currentLanguage: string;
  cancelDate: string;
  spTrackerLabels = Constants.spTrackerLabels;

  @Input() zone: string;
  @Input() bookingStatusParams: string;
  @Input() bookingEditCancel: string;
  @Input() isCallFuture = false;
  @Output() backClick = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  constructor(private utils: UtilsService,
    private videoUserService: VideoCallService,
    private toastService: ToastrService,
    private translate: TranslateService,
    private snowplowService: SnowplowService) {
    this.currentLanguage = this.utils.checkAndGetCurrentLang();
  }

  ngOnInit(): void {
    this.sessionInfo = this.utils.getLocalVal(PluginStates.bookingSessionInfo, 'response');
    this.cancelDate = this.getDateAndTime();
    if (this.bookingEditCancel) {
      this.checkAndSetBookingStatus(true);
      return;
    }
    const positionId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
    this.checkAndSetBookingStatus(false);
    this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingCancel, this.spTrackerLabels.screenShown,
        this.spTrackerLabels.cancelConfirmation, this.spTrackerLabels.version, 1);
    this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.cancelBooking,
        this.spTrackerLabels.externalLink, this.spTrackerLabels.callId, positionId);
  }

  checkAndSetBookingStatus(event: boolean) {
    if (this.checkIncludes('cancel', event)) {
      this.setValueForConfirmation(false, true);
    }
  }

  checkIncludes(value: string, event: boolean) {
    if (event) {
      return this.bookingEditCancel?.includes(value);
    }
    return !!this.bookingStatusParams?.includes(value);
  }

  setValueForConfirmation(editValue: boolean, cancelValue: boolean) {
    this.showEditConfirmation = editValue;
    this.showCancelConfirmation = cancelValue;
  }

  onCancelSession() {
    const inviteCode = this.utils.getCodeFromInviteLink(this.sessionInfo?.guest_link);
    const bodyParams = { status: BookingAppointment.cancelled };
    this.videoUserService.updateScheduleInfo(inviteCode, bodyParams).subscribe(() => {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingCancel, this.spTrackerLabels.cancelConfirmation,
          this.spTrackerLabels.confirmationToast);
      this.toastService.success(this.translate.instant('bookingAppointment.cancelSessionText'));
      this.utils?.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo]);
      this.utils.setLocalVal(null, ['isBookingConfirmed'], [true]);
      this.removeQueueInviteCode();
      this.closeModal.emit(true);
    });
  }

  onBackClick() {
    this.removeQueueInviteCode();
    this.closeModal.emit(true);
    if (this.bookingStatusParams && !this.bookingEditCancel) {
      if (!this.isCallFuture) {
        this.utils?.removeLocalStoreVal(Constants.optimyLocalStore, [RoomInfoStates.userInfo, PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo]);
      }
      this.backClick.emit(false);
      return;
    }
  }

  removeQueueInviteCode(){
    this.utils.removeSessionStorage();
  }

  getDateAndTime() {
    const dateInLocalLang = moment(this.sessionInfo?.start_dt).locale(this.currentLanguage);
    const activemonth = dateInLocalLang.format('MMM');
    const activeTime = dateInLocalLang.format('LT');
    const activeDate = dateInLocalLang.format('D');
    const activeYear = dateInLocalLang.format('YYYY');
    if (this.zone) {
      return `${activemonth} ${activeDate}, ${activeYear} ${this.translate.instant('common.at')} ${activeTime} ${this.zone}`;
    }
    return `${activemonth} ${activeDate}, ${activeYear} ${this.translate.instant('common.at')} ${activeTime}`;
  }
}
