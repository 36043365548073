<div class="permission_popup">
  <div class="permission_popup__header op-d-flex op-justify-content-end op-align-items-center">
    <svg-icon name="close" class="op-common-icon close-icon pointer op-mr-3" (click)="cancel()"></svg-icon>
  </div>
  <div class="permission_popup__section op-d-flex op-flex-column op-align-items-center">
      <svg-icon name="permission-{{deviceName}}" class="op-common-icon permission" [ngClass]="[isMic()? 'mic' : 'camera']"></svg-icon>
    <div class="op-mt-3 label">{{getPermissionText(true)}}</div>
    <div class="op-mt-3 text">{{getPermissionText(false)}}</div>
  </div>
  <div class="permission_popup__footer op-d-flex op-justify-content-center op-mb-2">
    <button class="op-btn op-btn-flat op-btn-round op-btn-primary" [innerHTML]="'inCall.ok'| translate" (click)="cancel()"></button>
  </div>
</div>
