import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from './../shared/shared.module';
// import { BookingConfigPluginComponent } from './components/booking-config-plugin/booking-config-plugin.component';
import { BookingModalComponent } from './components/booking-modal/booking-modal.component';
import { IframeConfigComponent } from './components/iframe-config/iframe-config.component';
// import { BookingRoutingModule } from './booking-routing.module';
import { ScheduleAppointmentConfirmationComponent } from './components/schedule-appointment-confirmation/schedule-appointment-confirmation.component';
import { ScheduleAppointmentDateTimeComponent } from './components/schedule-appointment-datetime/schedule-appointment-datetime.component';
import { ScheduleAppointmentEditCancelComponent } from './components/schedule-appointment-edit-cancel/schedule-appointment-edit-cancel.component';
import { ScheduleAppointmentFormComponent } from './components/schedule-appointment-form/schedule-appointment-form.component';
import { ScheduleAppointmentSuccessComponent } from './components/schedule-appointment-success/schedule-appointment-success.component';
import { SalesTalentBookingFormComponent } from './components/sales-talent-booking-form/sales-talent-booking-form.component';

const components = [
  IframeConfigComponent,
  ScheduleAppointmentConfirmationComponent,
  ScheduleAppointmentDateTimeComponent,
  ScheduleAppointmentEditCancelComponent,
  ScheduleAppointmentFormComponent,
  ScheduleAppointmentSuccessComponent,
  // BookingConfigPluginComponent,
  BookingModalComponent,
  SalesTalentBookingFormComponent
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    // BookingRoutingModule,
    AngularSvgIconModule,
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    TranslateModule.forChild(),
  ],
  exports: [
    ...components
  ]
})
export class BookingModule { }
