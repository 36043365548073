<div class="optimy-modal" [ngClass]="{'open': isOpen}" (click)="close($event)">
  <div class="modal-dialog">
    <div class="modal-content">
      <section class="modal-header">
        <ng-content select="[header]"></ng-content>
      </section>
      <section class="modal-body">
        <ng-content></ng-content>
      </section>
      <section class="modal-footer">
        <ng-content select="[footer]"></ng-content>
      </section>
    </div>
  </div>
</div>