import { SnowplowService } from '../../../../../core/services/snowplow/snowplow.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SnowplowTrackerLabels } from 'src/app/core/constants/trackerLabels';
import { takeUntil } from 'rxjs/operators';
import { ResizeState } from '../../../../../core/constants/common.enum';
import { ResizeService } from '../../../../../core/services/resize/resize.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'control-button',
  templateUrl: './control-button.component.html',
  styleUrls: ['./control-button.component.scss']
})
export class ControlButtonComponent implements OnInit {

  @Input() iconNameOn: string;
  @Input() iconNameOff: string;

  @Input() isOn: boolean;
  @Input() isDisabled: boolean;
  // @Input() removeActiveClass: boolean;
  @Input() showError: boolean;
  @Input() errorMessage: string;

  @Output() clickEvent: EventEmitter<boolean> = new EventEmitter();

  spTrackerLabels = SnowplowTrackerLabels;
  maximize = false;
  minimize = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private resizeService: ResizeService) { }

  ngOnInit(): void {
    if (!this.iconNameOff) {
      this.iconNameOff = this.iconNameOn;
    }

    this.resizeService.resizeSub.pipe(takeUntil(this.destroy$)).subscribe((resp: ResizeState) => {
      this.maximize = resp.isMaximized;
      this.minimize = resp.isMinimized;
    });
  }

  closeMediaPopover(): void {
    this.showError = false;
  }

  toggleButton(): void {
    this.isOn = !this.isOn;
    this.clickEvent.emit(this.isOn);
  }

}
