import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResizeState } from '../../constants/common.enum';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  public resizeSub = new Subject<ResizeState>();
  constructor() { }
}
