import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Participant } from '../../../components/video-call/video-call.types';
import {
  ChatRes, CustomButton, CustomMessage, CustomMessageOptions, MessageAttribute,
  MessageCreateReq,
  MessageUpdateReq,
  TypingInfo
} from '../../constants/chat.modal';
import { PluginStates, RoomInfoStates } from '../../constants/common.enum';
import { Constants } from '../../constants/constant';
import { SnowplowTrackerCategories, SnowplowTrackerLabels } from '../../constants/trackerLabels';
import { HttpWrapperService } from '../http-service/http-wrapper.service';
import { ScreenOrientationService } from '../utils/screen-orientation.service';
import { UtilsService } from '../utils/utils.service';
import { ChatMessage } from './../../constants/chat.modal';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories
  };
  executeButtonAction$: Subject<CustomButton> = new Subject<CustomButton>();
  startInterruptMessage$: Subject<boolean> = new Subject<boolean>();

  constructor(private httpClientWrapper: HttpWrapperService,
    private utils: UtilsService,
    private screenOrientationService: ScreenOrientationService,) { }

  createMember(identity: string, channelSid: string, attributes: any) {
    return this.httpClientWrapper.post(Constants.apiPaths.createMember, { channelSid, identity, attributes })
      .pipe(map((obj: any) => obj?.member))
      .pipe(catchError(e => throwError(e)));
  }

  updateMember(req: any, identity: string) {
    return this.httpClientWrapper.post(`${Constants.apiPaths.updateMember}${identity}`, req)
      .pipe(map((obj: any) => obj))
      .pipe(catchError(e => throwError(e)));
  }

  memberUpdated(roomName: string) {
    return this.httpClientWrapper.post(Constants.apiPaths.memberUpdated, { channelSid: roomName })
      .pipe(map((obj: any) => obj))
      .pipe(catchError(e => throwError(e)));
  }

  updateMemberIndex(identity: string, channelSid: string, index: number) {
    return this.httpClientWrapper.post(Constants.apiPaths.updateMemberIndex, { identity, channelSid, index })
      .pipe(map((obj: any) => obj))
      .pipe(catchError(e => throwError(e)));
  }

  updateLastConsumedMessageIndex(identity: string, channelSid: string, index: number) {
    return this.httpClientWrapper.post(Constants.apiPaths.updateLastConsumedMessageIndex, { identity, channelSid, index })
      .pipe(map((obj: any) => obj))
      .pipe(catchError(e => throwError(e)));
  }

  getChatMessage(channelSid: string, pageToken?: string): Observable<ChatRes> {
    return this.httpClientWrapper.post(pageToken ?
      `${Constants.apiPaths.getChantMessageWithToken}${pageToken}` : Constants.apiPaths.getChantMessage, { channelSid })
      .pipe(map((obj: ChatRes) => {
        const chatRes: ChatRes = {
          message: [],
          nextPageUrl: '',
        };
        obj.message.forEach((msg: any) => {
          const message = { ...msg };
          message.attributes = JSON.parse(msg.attributes);
          chatRes.message.push(message);
        });
        return chatRes;
      }))
      .pipe(catchError(e => throwError(e)));
  }

  updateChatMessage(req: MessageUpdateReq) {
    return this.httpClientWrapper.post(Constants.apiPaths.updateMessage, req)
      .pipe(map((obj: any) => obj))
      .pipe(catchError(e => throwError(e)));
  }

  sendChatMessages(req: MessageCreateReq): Observable<ChatMessage>{
    return this.httpClientWrapper.post(Constants.apiPaths.createMessage, req)
      .pipe(map((obj: any) => obj.message))
      .pipe(catchError(e => throwError(e)));
  }

  sendTypingStatus(req: TypingInfo) {
    return this.httpClientWrapper.post(Constants.apiPaths.typing, req)
      .pipe(map((obj: any) => obj))
      .pipe(catchError(e => throwError(e)));
  }

  showMessage(attribute: MessageAttribute, guestUserId: string): boolean {
    if (attribute && attribute?.private) {
      return !!(attribute.visible_to?.includes('guests') || attribute.visible_to?.includes(guestUserId) || attribute?.visible_to?.includes('guest_0'));
    } else {
      return true;
    }
  }

  getParticipantName(author: string): string {
    const participantsList = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.participantList);
    if (participantsList?.length) {
      const participant = participantsList?.find((participant: Participant) => participant.identity === author);
      if (participant?.attributes && (participant.attributes?.full_name || participant.attributes?.first_name)) {
        return participant?.attributes?.full_name ? participant?.attributes?.full_name : participant?.attributes?.first_name;
      } else {
        return author.replace('_', ' ');
      }
    } else {
      return author;
    }
  }

  showButtons(customMessage: CustomMessage, guestUserId: string): boolean {
    return customMessage?.response_from === guestUserId ||
      (customMessage?.type === CustomMessageOptions.cobrowse_permission ||
        customMessage?.type === CustomMessageOptions.multiple_choice)
  }

  updateCurrentCategoryValue(isUserInQueue: boolean, maximize: boolean): string {
    if (this.screenOrientationService.isLandscapeAndMobile) {
      if (isUserInQueue) {
        return maximize ? this.spTracker.categories.callFullWaitingLandscape : this.spTracker.categories.callChatWaitingLandscape;
      } else {
        return maximize ? this.spTracker.categories.callFullLandscape : this.spTracker.categories.callChatLandscape;
      }
    } else {
      if (isUserInQueue) {
        return maximize ? this.spTracker.categories.callFullWaiting : this.spTracker.categories.callChatWaiting;
      } else {
        return maximize ? this.spTracker.categories.callFull : this.spTracker.categories.callChat;
      }
    }
  }
}
