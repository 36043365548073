import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '../../../../core/services/shared/shared.service';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.scss']
})
export class BookingModalComponent implements OnInit {

  openBookingModal$: Subject<boolean> = new Subject<boolean>();
  closeBookingModal$: Subject<boolean> = new Subject<boolean>();

  public destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private sharedService: SharedService) {
    this.sharedService.closeBookingModal$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.closeBookingModal$.next(true);
        })
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.openBookingModal$.next(true);
    }, 0);
  }

}
