<div class="invite-guest-container">
  <div class="invite-guest-header">
    <div class="op-d-flex op-justify-content-start op-align-items-center">
      <span class="op-common-icon pointer" (click)="cancel()">
        <i class="close"></i>
      </span>
      <span class="settings-title" [innerHTML]="'inCall.invitePeople' | translate"></span>
    </div>
  </div>
  <div class="invite-guest" *ngIf="!sendInvite">
    <div class="op-mb-3">
      <span [innerHTML]="'inCall.inviteOption1' | translate"></span>
      <div class="invite-link" type="text" #userInput>{{inviteLink}}</div>
      <div class="op-d-flex op-justify-content-between op-mt-3">
        <button class="op-btn op-btn-stroked op-btn-light invite-link-button op-btn-round op-mr-3" (click)="copyLink()">
          {{ 'inCall.copyLink' | translate}}
        </button>
        <!-- <button class="op-btn op-btn-secondary share-link-button" (click)="shareLink()" *ngIf="isMobile()">
          {{ 'inCall.shareInvite' | translate}}
        </button> -->
      </div>
    </div>
    <div class="direct-invite op-mt-4">
      <span [innerHTML]="'inCall.inviteOption2' | translate"></span>
      <div class="tag-field op-mb-2" [ngClass]="{'error' : contactError, 'expanded': expandField}" (click)="snowplowEventToggle('contact'); expandField = true" >
        <tag-input [(ngModel)]="inviteList" class="op-mt-3" [placeholder]="'inCall.inviteInfoShort' | translate"
          [secondaryPlaceholder]="'inCall.inviteInfoShort' | translate" [separatorKeyCodes]="[32, 188, 44]" (onAdd)="onAdding($event)"
          [inputClass]="'handleError'" (onRemove)="onRemoving($event)"></tag-input>
        <div class="hint-text">
          {{ 'inCall.inviteInfo' | translate}}
        </div>
        <span *ngIf="contactError" [innerHTML]="'inCall.inviteError'| translate"></span>
      </div>
      <div class="op-d-flex op-justify-content-between op-align-items-start op-mt-3">
        <button class="op-btn op-btn-flat op-btn-primary op-btn-light send-link-button op-btn-round" (click)="inviteParticipants()"
          [disabled]="inviteList.length === 0 || contactError">
          {{ 'inCall.sendInvite' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>