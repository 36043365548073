import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenOrientationService {

  isLandscapeAndMobile = !window.matchMedia('(orientation: portrait)').matches && this.isOnMobile();
  orientationChanged$: Subject<boolean> = new Subject<boolean>();

  constructor() {
    window.addEventListener('resize', () => {
      this.isLandscapeAndMobile = !window.matchMedia('(orientation: portrait)').matches && this.isOnMobile();
      this.orientationChanged$.next(this.isLandscapeAndMobile);
    }, false);
  }

  isOnMobile(): boolean {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }
}
