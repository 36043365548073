<div class="call-in-future">
    <app-call-status-screen [iconName]="'call-scheduled-for-future'" [callStatus]="'callInFuture.callScheduled'" [hideButton]="true">
    </app-call-status-screen>
    <div class="schedule-call-info op-d-flex op-flex-column">
        <div class="op-d-flex op-flex-row op-mb-3">
            <span class="date op-mr-2">{{scheduledDate}}</span>
            <span class="op-mr-2">{{'common.at' | translate}}</span>
            <span class="time">{{scheduledTime}}</span>
        </div>
        <span class="op-mb-5" [innerHTML]="'callInFuture.talkToYouSoon' | translate"></span>
        <button class="op-btn op-flex-1 op-btn-primary op-btn-flat"
                appAddSnowplowEvent
                [category]="spTrackerLabels.inviteLink"
                [label]="spTrackerLabels.futureCall"
                [action]="spTrackerLabels.close"
                [innerHTML]="'common.close' | translate" (click)="close()"></button>
    </div>
    <!-- // To do -->
    <!-- <div class="op-d-flex op-flex-row op-justify-content-between op-mt-3 op-mb-2">
      <button class="reschedule-session op-btn" [innerHTML]="'common.reschedule' | translate"
              appAddSnowplowEvent [eventLabel]="trackerLabels.bookingRescheduleFutureCall"
              (click)="onButtonClick('editbooking')"></button>
      <button class="cancel-session op-btn" [innerHTML]="'common.cancel' | translate"
              appAddSnowplowEvent [eventLabel]="trackerLabels.bookingCancelFutureCall"
              (click)="onButtonClick('cancelbooking')"></button>
    </div> -->
</div>

<!-- <optimy-modal [openModal]="openBookingModal.asObservable()" [closeOnOutsideClick]="false" [closeModal]="closeBookingModal.asObservable()">
      <app-booking-appointment-modal *ngIf="showBooking" (closeModalEvent)="CheckCloseModalEvent($event)" [bookingStatus]="bookingStatus" [isCallFuture]="true"></app-booking-appointment-modal>
</optimy-modal> -->
