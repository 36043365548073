<div class="queue-intro-container">
    <div class="intro op-mt-3">
        <h2>
            {{'intermediateScreen.introTitle' | translate}}<span class="op-ml-2">👋</span>
        </h2>
        <p class="op-mt-3" [innerHTML]="'intermediateScreen.introText' | translate"></p>
    </div>
    <div class="action op-my-3">
        <app-agent-list></app-agent-list>
        <button class="intro-action-button op-btn op-btn-primary op-btn-flat op-btn-round" (click)="introAction()">
            <svg-icon class="op-mr-3" name="paper-plane"></svg-icon>
            <ng-container *ngIf="agentStatusInfo?.are_agents_online">{{'intermediateScreen.startConversation' | translate}}</ng-container>
            <ng-container *ngIf="!agentStatusInfo?.are_agents_online">{{'intermediateScreen.leaveUsMessage' | translate}}</ng-container>
        </button>
        <div class="op-d-flex op-justify-content-center op-align-items-center guest-ahead" *ngIf="agentStatusInfo?.are_agents_online > 0">
            {{'intermediateScreen.guestAhead' | translate}}
            <svg-icon name="user" class="op-mx-2"></svg-icon>
            <b>{{customersInQueue}}</b>
        </div>
    </div>
</div>
