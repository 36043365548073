import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgentLists, AgentResponseData, AgentStatus } from '../../../core/constants/agent.modal';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { ColorSet } from '../../../core/constants/call.modal';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '../../../core/services/shared/shared.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss']
})
export class AgentListComponent implements OnInit, OnDestroy {
  agentStatusInfo: AgentResponseData;
  agentList: AgentLists[];
  colorList = ColorSet;

  statusColor = {
    onlineColor: '#14B22D',
    offlineColor: '#E95F5F'
  }

  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private sharedService: SharedService,
              private utils: UtilsService) {
    this.sharedService.updatedAgentInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe(
            {
              next: () => {
                this.getAndSetAgentList();
              },
            });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.getAndSetAgentList();
  }

  getAndSetAgentList() {
    this.agentStatusInfo = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    const offlineAgents = this.agentStatusInfo?.offline_agents || [];
    const onlineAgents = this.agentStatusInfo?.agents || [];
    this.agentList = [...onlineAgents, ...offlineAgents];
    this.addShortName();
  }


  addShortName(): void {
    this.agentList?.forEach(agent=> {
      const nameArray = agent.agent_first_name.split(' ')
      agent.agentShortName =  nameArray?.length > 1 ?
          `${nameArray[0].charAt(0)}${nameArray[nameArray.length-1].charAt(0)}` : nameArray[0].charAt(0);
    })
  }

}
