import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgentResponseData } from '../../../core/constants/agent.modal';
import { QueueBubble } from '../../../core/constants/call.modal';
import { CustomButton } from '../../../core/constants/chat.modal';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';
import { AutoQueueInfoStates, PluginStates, RoomInfoStates, UserInfo } from '../../../core/constants/common.enum';
import {
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels
} from '../../../core/constants/trackerLabels';

interface QueueQuestion {
  message: string;
  choices:  CustomButton[];
  index: number;
}

@Component({
  selector: 'app-queue-questions',
  templateUrl: './queue-questions.component.html',
  styleUrls: ['./queue-questions.component.scss']
})

export class QueueQuestionsComponent implements OnInit {
  @Input()agentStatusInfo: AgentResponseData;
  @Output() emitQueueQuestionNext = new EventEmitter();

  answeredQuestions: CustomButton[]
  currentQuestion: QueueQuestion;
  currentQuestionIndex = 0;
  language: string;
  lastQueueQuestionIndex: number = -1;
  queueWaitQuestions: QueueBubble[];
  selectedAnswer: CustomButton;

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
  };

  constructor(private snowplowService: SnowplowService,
              private utils: UtilsService) { }

  ngOnInit(): void {
    this.answeredQuestions = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.answeredQuestions) || [];
    this.selectedAnswer = this.answeredQuestions[this.answeredQuestions?.length - 1];
    this.language = this.utils.getLocalVal(PluginStates.language);
    this.queueWaitQuestions = this.agentStatusInfo?.join_queue_config?.queue_wait_questions;
    this.currentQuestionIndex = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.currentQuestionIndex) || 0;
    this.lastQueueQuestionIndex = this.currentQuestionIndex - 1;
    this.executeBtnAction();
  }


  prepareQueueWaitQuestion(index: number | any) {
    this.currentQuestionIndex = index;
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.currentQuestionIndex], [this.currentQuestionIndex]);
    this.currentQuestion = this.modifyQueueQuestion(this.queueWaitQuestions[index], index);
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.screenShown,
        this.currentQuestionIndex === 0 ? this.spTracker.labels.questionsScreenFirst : this.spTracker.labels.questionsScreenNext,
        this.spTracker.labels.version, 2);
  }

  modifyQueueQuestion (question: QueueBubble, index: number) : QueueQuestion {
    return {
      message: this.utils.getTextFromCurrentLanguage(question?.text, this.language),
      choices: this.utils.getChoices(question?.choices, this.language, this.agentStatusInfo?.booking_url),
      index
    }
  }

  executeBtnAction(choice?: CustomButton, index?: number) {
    if (choice && index !== undefined) {
      this.checkAnswerAlreadyInStorage(choice, index);
      const eventKey = this.queueWaitQuestions[this.currentQuestionIndex]?.event_key;
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.select,
          this.currentQuestionIndex === 0 ? this.spTracker.labels.questionsScreenFirst : this.spTracker.labels.questionsScreenNext, eventKey);
    }
    if(choice?.queue_id) {
      let userInfo: UserInfo = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.userInfo);
      userInfo = {
        ...userInfo,
        queue_id: choice?.queue_id
      }
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.userInfo], [userInfo]);
    }
    const skipToQuestion = choice?.skip_to_question;
    if (skipToQuestion && skipToQuestion > 0) {
      this.lastQueueQuestionIndex = skipToQuestion - 1;
      this.prepareQueueWaitQuestion(this.lastQueueQuestionIndex)
    } else if (skipToQuestion === 0 || (this.lastQueueQuestionIndex + 1 >= this.queueWaitQuestions?.length)) {
      this.skipQuestions();
    } else {
      this.prepareQueueWaitQuestion(++this.lastQueueQuestionIndex)
    }
  }

  checkAnswerAlreadyInStorage(choice: CustomButton, index: number) {
    const updatedAnswer = {
      ...choice,
      questionIndex: index
    }
    this.answeredQuestions = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.answeredQuestions) || [];
    const indexOfItem = this.answeredQuestions?.findIndex((res: any)=>  res.questionIndex === index);
    if(indexOfItem === -1) {
      this.answeredQuestions = [...this.answeredQuestions, updatedAnswer];
    } else {
      this.answeredQuestions.splice(indexOfItem, 1);
      this.answeredQuestions = [...this.answeredQuestions, updatedAnswer];
    }
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.answeredQuestions], [this.answeredQuestions]);
  }

  goBackToPrevious() {
    const eventKey = this.queueWaitQuestions[this.currentQuestionIndex]?.event_key;
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.back,
        this.currentQuestionIndex === 0 ? this.spTracker.labels.questionsScreenFirst : this.spTracker.labels.questionsScreenNext, eventKey);
    --this.lastQueueQuestionIndex;
    this.answeredQuestions = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.answeredQuestions);
    const currentIndex =  this.answeredQuestions?.findIndex((res: any)=>  res.questionIndex === this.currentQuestionIndex);
    if(currentIndex !== -1) {
      this.answeredQuestions.splice(currentIndex, 1);
      this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.answeredQuestions], [this.answeredQuestions]);
    }
    this.selectedAnswer = this.answeredQuestions[this.answeredQuestions?.length - 1];
    this.prepareQueueWaitQuestion(this.selectedAnswer?.questionIndex);
  }

  skipQuestions() {
    this.emitQueueQuestionNext.emit();
    const eventKey = this.queueWaitQuestions[this.currentQuestionIndex]?.event_key;
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundForm, this.spTracker.labels.skip,
        this.currentQuestionIndex === 0 ? this.spTracker.labels.questionsScreenFirst : this.spTracker.labels.questionsScreenNext, eventKey);
  }

}
