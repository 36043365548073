import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import { firstValueFrom, interval, startWith, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../core/constants/constant';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SharedService } from '../../../core/services/shared/shared.service';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { VideoCallService } from '../../../core/services/video-call/video-call.service';
import { CallStatus, RouteSate } from '../../../core/constants/call.modal';
import {
  ActiveScreen,
  AutoQueueInfoStates, GuestStatus,
  PluginStates, QueueUpdateReq, RoomData, RoomInfoStates,
  RoutesUrls, UserInfo,
  WaitTemplate
} from '../../../core/constants/common.enum';
import { SocketService } from '../../../core/services/socket/socket.service';
import { Participant } from '../../video-call/video-call.types';
import { AgentLists, AgentRating, AgentResponseData } from '../../../core/constants/agent.modal';
import {
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels, SnowplowTrackerProperties
} from '../../../core/constants/trackerLabels';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';

@Component({
  selector: 'app-queue-wait-screen',
  templateUrl: './queue-wait-screen.component.html',
  styleUrls: ['./queue-wait-screen.component.scss']
})
export class QueueWaitScreenComponent implements OnInit, OnDestroy{
  @Input() agentStatusInfo: AgentResponseData;
  @Output() emitWaitScreenAction = new EventEmitter();

  agentJoined: AgentLists;
  agentName: string;
  countDown: number;
  currentTemplate: string;
  siteLanguage: string;
  languages: string[];
  waitTemplate = WaitTemplate;
  initiatedTime: number;
  participants: Participant[];
  rating: number;
  ratingObj: AgentRating;
  timeKillerMessage: string;

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties
  };

  timerForKillerMessage$: Subject<boolean> = new Subject<boolean>();
  timerForCountDown$: Subject<boolean> = new Subject<boolean>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router,
              private snowplowService: SnowplowService,
              private socketService: SocketService,
              private sharedService: SharedService,
              private translate: TranslateService,
              private utils: UtilsService,
              private videoCallService: VideoCallService,
              private zone: NgZone) {
      this.socketService.roomData
          .pipe(takeUntil((this.destroy$)))
          .subscribe({
            next: (data: RoomData) => {
              if (+data.queue_position_id === this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId)) {
                if(data?.room_status === CallStatus.in_progress) {
                  this.timerForKillerMessage$.next(true)
                  this.utils.setLocalVal(
                      PluginStates.roomInfo,
                      [RoomInfoStates.roomStatus, RoomInfoStates.routeState],
                      [CallStatus.in_progress, RouteSate.onGoing]);
                  this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.autoQueueInfo]);
                  this.getAgent(data?.room_name);
                }
              }
            }
          });
      this.sharedService.joinedTheQueue$
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.prepareTimeKillerSchedule();
          });
  }

  ngOnInit() {
    this.siteLanguage = this.utils.getLocalVal(PluginStates.language);
    this.initiatedTime = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.waitScreenInitiatedTime);
    const templateInStore = this.utils.getLocalVal(PluginStates.autoQueueInfo, AutoQueueInfoStates.waitScreenTemplate);
    this.currentTemplate = templateInStore ? templateInStore : this.waitTemplate.waitIntro;
    this.callScreenShownTrack();
    if (this.currentTemplate === this.waitTemplate.waitIntro) {
      this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.activeScreenForHeader], [ActiveScreen.waitingScreen]);
      const inQueue = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
      if (inQueue) {
        this.prepareTimeKillerSchedule();
      }
    }
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.waitScreenTemplate], [this.currentTemplate])
  }

  ngOnDestroy() {
    this.timerForKillerMessage$.next(true);
    this.timerForCountDown$.next(true)
  }

  callScreenShownTrack() {
    if (this.currentTemplate === this.waitTemplate.waitIntro) {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue, this.spTracker.labels.screenShown,
          this.spTracker.labels.mainQueue, this.spTracker.labels.version, 2);
    } else if(this.currentTemplate === this.waitTemplate.waitNoShowWithoutMobile) {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue, this.spTracker.labels.screenShown,
          this.spTracker.labels.mainQueueTimeoutNoText, this.spTracker.labels.version, 2);
    } else if(this.currentTemplate === this.waitTemplate.waitNoShowWithMobile) {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue, this.spTracker.labels.screenShown,
          this.spTracker.labels.mainQueueTimeoutText, this.spTracker.labels.version, 2);
    } else if(this.currentTemplate === this.waitTemplate.waitAgentJoin) {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue, this.spTracker.labels.screenShown,
          this.spTracker.labels.mainQueueAgent, this.spTracker.labels.version, 2);
    }
  }

  prepareTimeKillerSchedule() {
    if (!this.initiatedTime) {
      this.scheduleTimeKillerMessage(0);
      this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.waitScreenInitiatedTime], [moment().unix()])
    } else {
      const duration = moment.duration(moment.unix(moment().unix()).diff(moment.unix(this.initiatedTime)));
      this.scheduleTimeKillerMessage(duration.seconds())
    }
  }

  scheduleTimeKillerMessage(startWithVal: number) {
    interval(1000)
        .pipe(takeUntil(this.timerForKillerMessage$), startWith(0))
        .subscribe((val) => {
          let value = startWithVal + val;
          if(value > 5 && value < 10) {
            this.timeKillerMessage = this.translate.instant('intermediateScreen.timeKillerMsg1');
          } else if (value > 10 && value < 20) {
            this.timeKillerMessage = this.translate.instant('intermediateScreen.timeKillerMsg2');
          } else if (value > 20) {
            this.timerForKillerMessage$.next(true);
            this.checkPhoneProvided();
          }
        });
  }

  checkPhoneProvided() {
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.activeScreenForHeader], [null])
    const userInfo = this.utils.getLocalVal(PluginStates.roomInfo)?.userInfo;
    if(userInfo?.phone) {
      this.currentTemplate = this.waitTemplate.waitNoShowWithMobile;
      this.executeLeaveMessageInBackground();
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue, this.spTracker.labels.screenShown,
          this.spTracker.labels.mainQueueTimeoutText, this.spTracker.labels.version, 2);
    } else {
      this.currentTemplate = this.waitTemplate.waitNoShowWithoutMobile;
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue, this.spTracker.labels.screenShown,
          this.spTracker.labels.mainQueueTimeoutNoText, this.spTracker.labels.version, 2);
    }
    this.utils.setLocalVal(PluginStates.autoQueueInfo, [AutoQueueInfoStates.waitScreenTemplate], [this.currentTemplate])
  }

  executeLeaveMessageInBackground() {
    const guestToken = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestToken);
    const queuePositionId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
    const userInfo: UserInfo = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.userInfo);
    const req: QueueUpdateReq = {
      guest_hostname: this.utils.removeExtraParamsFormUrl(),
      lang: this.utils.getLocalVal(PluginStates.language),
      guest_locale: navigator.language,
      domain_sessionid: this.utils.getDomainSessionId(),
      customer_contact :  {
        full_name: userInfo?.full_name || '',
        phone: userInfo?.phone
      },
      comment: '',
      message_left: 1
    }
    this.videoCallService.queuePositionUpdate(queuePositionId, guestToken,
        {status: CallStatus.missed, attr_update: JSON.stringify(req)}).subscribe(() => {
      this.utils.checkAndSetGuestStatus(GuestStatus.available, guestToken);
      this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo]);
    })
  }

  closeWait() {
    this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.autoQueueInfo]);
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }], {skipLocationChange: true});
  }

  async getAgent(roomName: string) {
    this.participants = await firstValueFrom(this.videoCallService.getParticipantList(roomName));
    const firstAgent = this.participants?.find((participant)=> !participant?.identity?.includes('guest'));
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.agentName, RoomInfoStates.participantList],
        [firstAgent?.attributes?.first_name, this.participants]);
    const agentIndex = this.agentStatusInfo?.agents?.findIndex(agent=> agent?.agent_first_name === firstAgent?.attributes?.first_name);
    if(agentIndex !== -1) {
      this.agentJoined = this.agentStatusInfo?.agents[agentIndex];
      this.agentName = this.agentJoined?.agent_first_name;
      const lang = this.agentJoined?.languages || ['en'];
      this.getLanguageList(lang);
      this.ratingObj = this.agentJoined?.rating;
      this.rating = this.ratingObj?.rating_avg;
    }
    this.currentTemplate = this.waitTemplate.waitAgentJoin;
    this.scheduleCountDownForCall();
    this.snowplowService.trackStructEvent(this.spTracker.labels.inboundQueue, this.spTracker.labels.screenShown,
        this.spTracker.labels.mainQueueAgent, this.spTracker.labels.version, 2);
  }

  getLanguageList(lang: string[]) {
    const langNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });
    const langNamesInFrench = new Intl.DisplayNames(['fr'], { type: 'language' });
    if(this.siteLanguage === 'en') {
      this.languages = lang.map((lang)=> langNamesInEnglish.of(lang) || '')
    } else {
      this.languages = lang.map(lang=> langNamesInFrench.of(lang) || '')
    }
  }

  scheduleCountDownForCall() {
    const countDownLimit = this.agentStatusInfo?.join_queue_config?.call_start_countdown || 10;
    interval(1000)
        .pipe(takeUntil(this.timerForCountDown$), startWith(0))
        .subscribe((val) => {
          if((countDownLimit-val) > -1) {
            this.zone.run(() => {
              this.countDown = countDownLimit - val;
            });
          } else {
            this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.autoQueueInfo]);
            this.timerForCountDown$.next(true);
            this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }], { skipLocationChange : true});
          }
        });
  }

}
