<div class="queue-intermediate" [id]="isMobile ? 'on-mobile' : ''" [ngClass]="{'non-intro': currentTemplate !== queueTemplates.start, 'on-mobile': isMobile }">
    <ngx-spinner *ngIf="showSpinner" [fullScreen]="false" type="ball-pulse-sync" size="default"></ngx-spinner>
    <div class="op-w-100">
        <app-queue-header [agentStatusInfo]="agentStatusInfo"
                          [introScreen]="currentTemplate === queueTemplates.start" (emitHeaderAction)="removeFromQueue($event)"></app-queue-header>
    </div>
    <div class="op-w-100">
        <ng-container [ngSwitch]="currentTemplate">
            <ng-template [ngSwitchCase]="queueTemplates.start">
                <app-queue-intro [agentStatusInfo]="agentStatusInfo" (emitAction)="onActionClick()"></app-queue-intro>
            </ng-template>
            <ng-template [ngSwitchCase]="queueTemplates.leaveMessage">
                <app-leave-message #leaveMessageCmp [queueId]="agentStatusInfo?.default_queue_id" ></app-leave-message>
            </ng-template>
            <ng-template [ngSwitchCase]="queueTemplates.contact">
                <app-queue-form (emitQueueFormNext)="onActionClick()"></app-queue-form>
            </ng-template>
            <ng-template [ngSwitchCase]="queueTemplates.questions">
                <app-queue-questions (emitQueueQuestionNext)="onActionClick()" [agentStatusInfo]="agentStatusInfo"></app-queue-questions>
            </ng-template>
            <ng-template [ngSwitchCase]="queueTemplates.waiting">
                <app-queue-wait-screen (emitWaitScreenAction)="removeFromQueue($event)" [agentStatusInfo]="agentStatusInfo"></app-queue-wait-screen>
            </ng-template>
    </ng-container>
    </div>
    <div class="queue-intermediate-footer">
        <app-footer></app-footer>
    </div>
</div>
