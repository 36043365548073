<div class="queue-form" [ngStyle]="{'height': language === 'fr' ? '506px' : '482px' }">
    <form [formGroup]="queueForm" class="op-h-100">
        <div class="op-d-flex op-flex-column op-justify-content-between op-h-100">
            <div class="op-d-flex op-flex-column op-justify-content-start">
                <div class="form-field">
                    <label [innerHTML]="'queueForm.nameField' | translate"></label>
                    <input formControlName="name"  maxlength="100" (ngModelChange)="optionChange()">
                </div>
                <div class="form-field op-mt-3">
                    <label [innerHTML]="'queueForm.callDropMessage' | translate"></label>
                    <div class="op-d-flex op-justify-content-center op-align-items-center op-mt-3">
                        <div class="radio-button op-mx-3" [ngClass]="{'active': queueForm?.value.callDropOpt === callDropOptions.noThanks}">
                            <input formControlName="callDropOpt" id="1" type="radio" (ngModelChange)="optionChange()" [value]="callDropOptions.noThanks">
                            <label for="1">
                                <svg-icon name="ban"></svg-icon>
                                <span class="op-mt-2" [innerHTML]="'queueForm.noThanks' | translate"></span>
                            </label>
                            <svg-icon name="check" class="check" *ngIf="queueForm?.value.callDropOpt === callDropOptions.noThanks"></svg-icon>
                        </div>
                        <div class="radio-button op-mx-3" [ngClass]="{'active': queueForm?.value.callDropOpt === callDropOptions.yesTextMe}">
                            <input formControlName="callDropOpt" id="2" type="radio" (ngModelChange)="optionChange()" [value]="callDropOptions.yesTextMe">
                            <label for="2">
                                <svg-icon class="text-me" name="message"></svg-icon>
                                <span class="op-mt-2" [innerHTML]="'queueForm.yesTextMe' | translate"></span>
                            </label>
                            <svg-icon name="check" class="check" *ngIf="queueForm?.value.callDropOpt === callDropOptions.yesTextMe"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="form-field op-mt-3" *ngIf="queueForm?.value.callDropOpt === callDropOptions.yesTextMe">
                    <label [innerHTML]="'leaveMessage.mobileNumber' | translate"></label>
                    <div class="intel-phone">
                        <app-intel-tell-input (sendPhoneNumber)="setPhoneNumberValue($event)"></app-intel-tell-input>
                    </div>
                </div>
            </div>
            <div class="op-d-flex op-flex-column op-mt-4">
                <button class="pointer op-btn op-btn-flat op-btn-primary op-flex-1"
                        [disabled]="queueForm?.invalid"
                        (click)="updateUserDetails()"
                        [innerHTML]="'intermediateScreen.next' | translate"></button>
            </div>
        </div>
    </form>
</div>
