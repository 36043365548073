<optimy-modal [openModal]="openModalSub.asObservable()" [closeOnOutsideClick]="true"
  [closeModal]="closeModalSub.asObservable()" (modalClosed)="closeModal(false)">
  <div header>
    <div class="settings-header">
      <div class="op-d-flex op-justify-content-start op-align-items-center">
        <span class="op-common-icon pointer" (click)="closeModal(true)" appAddSnowplowEvent
          [label]="spTracker.labels.close" [category]="currentCategory"
          [action]="spTracker.labels.click" [context]="context">
          <i class="close"></i>
        </span>
        <span class="settings-title" [innerHTML]="'inCall.settings' | translate"></span>
      </div>
      <button class="op-btn op-btn-light op-btn-stroked" (click)="saveConfig()" appAddSnowplowEvent
        [label]="spTracker.labels.save" [category]="currentCategory"
              [action]="spTracker.labels.click" [context]="context">
        {{ 'common.save' | translate}}

      </button>
    </div>
  </div>
  <div class="settings-body">
    <form [formGroup]="deviceSelectForm" class="settings-form">
      <div class="settings-selection">
        <h5 class="op-m-0 op-d-flex">
          <svg-icon name="speaker" class="op-mr-3"> </svg-icon> {{ 'inCall.audioOption' | translate}}
        </h5>
        <div class="op-d-flex op-justify-content-start op-align-items-center op-mt-3" *ngIf="!noOutputDeviceFound">
          <select *ngIf="!audPermissionDenied" formControlName="audioOutput" class="w-100 op-form-control-dark"
            appAddSnowplowEvent [label]='"audio_output"' [category]='currentCategory'
            [action]='spTracker.labels.click' [context]="context">
            <option *ngFor="let device of audioOutputs" [value]="device.deviceId"
              class="op-d-flex op-justify-content-center op-align-items-center">
              {{device?.label}}</option>
          </select>
          <div class="op-w-100 op-mt-2 permission-error" *ngIf="audPermissionDenied"
            [innerHTML]="'inCall.micError' | translate"></div>
        </div>
        <div class="op-d-flex op-justify-content-start op-align-items-center op-mt-3">
          <select *ngIf="!audPermissionDenied" formControlName="audioInput" class="w-100 op-form-control-dark"
            appAddSnowplowEvent [label]="'audio_input'" [category]="currentCategory"
            [action]="spTracker.labels.click" [context]="context">
            <option *ngFor="let device of audioInputs" [value]="device.deviceId"
              class="op-d-flex op-justify-content-center op-align-items-center">
              {{device?.label}}</option>
          </select>
          <div class="op-w-100 op-mt-2 permission-error" *ngIf="audPermissionDenied"
            [innerHTML]="'inCall.micError' | translate"></div>
        </div>

      </div>
      <div class="settings-selection">
        <h5 class="op-m-0 op-d-flex">
          <svg-icon name="camera" class="op-mr-3"> </svg-icon> {{ 'inCall.camera' | translate}}
        </h5>
        <div class="op-d-flex op-justify-content-start op-align-items-center op-mt-3">
          <select *ngIf="!camPermissionDenied" formControlName="videoInput" class="w-100 op-form-control-dark"
            appAddSnowplowEvent [label]='"video"' [category]='currentCategory'
            [action]='spTracker.labels.click' [context]="context">
            <option *ngFor="let device of videoInputs" [value]="device.deviceId"
              class="op-d-flex op-justify-content-center op-align-items-center">
              {{device?.label}}</option>
          </select>
          <div class="op-w-100 op-mt-2 permission-error" *ngIf="camPermissionDenied"
            [innerHTML]="'inCall.camError' | translate"></div>
        </div>
        <div class="op-d-flex op-justify-content-center op-align-items-center op-mt-3" *ngIf="!camPermissionDenied">
          <div class="w-100 cam-preview op-d-flex op-justify-content-center" #elLocal></div>
        </div>
        <div class="setting-disclaimer">
          <span [innerHTML]="'inCall.displayPreview' | translate"> </span>
        </div>
      </div>
    </form>
  </div>
  <!-- <div footer></div> -->
</optimy-modal>
