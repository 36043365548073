<div class="leave-form-container op-my-3 op-d-flex op-flex-column op-justify-content-between">
    <div class="title" [ngClass]="{'hide': activeSection === 2}" >
        <span *ngIf="activeSection === 1" [innerHTML]="'leaveMessage.leaveMessageTitle' | translate"></span>
    </div>
    <ngx-spinner [fullScreen]="false" type="ball-pulse-sync" size="default"></ngx-spinner>
    <form [formGroup]="leaveMessageForm">
        <div class="op-d-flex op-flex-column op-justify-content-between leave-form">
            <ng-container *ngIf="activeSection === 1">
                <div class="op-d-flex op-flex-column op-justify-content-start">
                    <div class="form-field op-mt-3">
                        <label [innerHTML]="'leaveMessage.name' | translate"></label>
                        <input formControlName="name" [placeholder]="'leaveMessage.name' | translate" maxlength="100">
                        <small class="text-danger"
                               *ngIf="leaveMessageForm.get('name')?.errors &&
                                       leaveMessageForm.get('name')?.invalid &&
                                       leaveMessageForm.get('name')?.touched" [innerHTML]="'leaveMessage.nameError' | translate">
                        </small>
                    </div>
                    <div class="form-field op-mt-3">
                        <label [innerHTML]="'leaveMessage.mobileNumber' | translate"></label>
                        <div class="intel-phone">
                            <app-intel-tell-input (sendPhoneNumber)="setPhoneNumberValue($event)" [errorMessage]="true"></app-intel-tell-input>
                        </div>
                    </div>
                </div>
                <div class="op-d-flex op-flex-column op-justify-content-start op-mt-3">
                    <label [innerHTML]="'leaveMessage.message' | translate"></label>
                    <div class="form-field">
                        <textarea formControlName="message" maxlength="500" [placeholder]="'inCall.typeMessage' | translate"></textarea>
                    </div>
                </div>
                    <button class="submit op-btn op-btn-flat op-btn-primary op-flex-1 op-mt-4"
                            [disabled]="leaveMessageForm.invalid" (click)="executeSubmit()"
                            [innerHTML]="'common.submit' | translate"></button>
            </ng-container>
            <ng-container *ngIf="activeSection === 2">
                <div class="op-d-flex op-flex-column op-justify-content-start confirmation op-align-items-center">
                    <h6 [innerHTML]="'leaveMessage.getBackYou' | translate"></h6>
                    <svg-icon name="mobile-conversation"></svg-icon>
                </div>
                <div class="op-d-flex op-flex-column">
                    <button class="pointer op-btn op-btn-flat op-btn-primary op-flex-1" (click)="closeLeaveMessage()"
                            [innerHTML]="'common.close' | translate"></button>
                </div>
            </ng-container>
        </div>
    </form>
</div>
