<div class="confirmation-container op-mt-5" *ngIf="!showSuccessModal">
  <div class="schedule-appointment__text op-mb-4" [innerHTML]="'bookingAppointment.scheduleLiveSessionConfirmText' | translate"></div>
  <div class="final-values op-d-flex op-flex-column op-mb-4">
    <div class="common-details first" *ngIf="editForm && checkPreviousAndLatestBooking()">
      <span class="common-label op-mr-3" [innerHTML]="'common.from' | translate"></span>
      <span class="common-info">{{rescheduleTime()}}</span>
    </div>
    <div class="common-details">
      <span class="common-label op-mr-3"> {{ (editForm && checkPreviousAndLatestBooking() ? 'common.to' : 'bookingAppointment.dateTime') | translate}}</span>
      <span class="common-info">{{getTime()}}</span>
    </div>
    <div class="common-details">
      <span class="common-label op-mr-3" [innerHTML]="'bookingAppointment.scheduledBy' | translate"></span>
      <span class="common-info">{{form?.controls['full_name']?.value}}</span>
    </div>
    <div class="common-details">
      <span class="common-label op-mr-3" [innerHTML]="'bookingAppointment.contactInformation' | translate"></span>
      <div class="op-d-flex op-flex-column contact-info">
        <span class="common-info">{{form?.controls['email']?.value}}</span>
        <span class="common-info">{{form?.controls['phone']?.value}}</span>
      </div>
    </div>
    <div class="common-details" *ngIf="form?.controls['schoolName']?.value">
      <span class="common-label op-mr-3" [innerHTML]="'bookingAppointment.segmentName' | translate"></span>
      <div class="common-info">{{form.controls['schoolName'].value}}</div>
    </div>
    <div *ngIf="extraFormFields?.length" class="op-d-flex op-flex-column">
      <div class="common-details" *ngFor="let control of extraFormFields">
        <span class="common-label op-mr-3">{{control.label}}</span>
        <div class="common-info">{{form.controls[control.name].value}}</div>
      </div>
    </div>
    <div class="common-details last" *ngIf="form?.controls['comment']?.value">
      <span class="common-label op-mr-3" [innerHTML]="'bookingAppointment.message' | translate"></span>
      <div class="common-info comment op-mt-1 op-text-break">{{form?.controls['comment']?.value}}</div>
    </div>
  </div>
  <div class="button-container op-mb-3 op-d-flex op-justify-content-between">
    <button class="op-btn op-btn-stroked op-btn-danger op-flex-1 op-mr-1" (click)="close()"
            appAddSnowplowEvent
            [category]="spTrackerLabels.bookingReview"
            [label]="editForm && checkPreviousAndLatestBooking() ?
            spTrackerLabels.reviewPageReschedule : spTrackerLabels.reviewPage"
            [action]="spTrackerLabels.cancel"
            [innerHTML]="'common.cancel' | translate"></button>
    <button class="op-btn op-btn-stroked op-btn-primary op-flex-1 op-mr-1" [innerHTML]="'common.back' | translate" (click)="onBackClick()"
            appAddSnowplowEvent
            [category]="spTrackerLabels.bookingReview"
            [label]="editForm && checkPreviousAndLatestBooking() ?
            spTrackerLabels.reviewPageReschedule : spTrackerLabels.reviewPage"
            [action]="spTrackerLabels.back"></button>
    <button class="op-btn op-btn-flat op-btn-primary op-flex-1" (click)="onSubmitForm()"
            appAddSnowplowEvent
            [category]="spTrackerLabels.bookingReview"
            [label]="editForm && checkPreviousAndLatestBooking() ?
            spTrackerLabels.reviewPageReschedule : spTrackerLabels.reviewPage"
            [action]="spTrackerLabels.confirm"
            [innerHTML]="'common.confirm' | translate" [disabled]="isSubmitted"></button>
  </div>
</div>
