<div class="op-overlay" *ngIf="menuOpen" (click)="toggleMenu()"></div>
<div class="controls-container" [ngClass]="{'open': menuOpen}">
  <div class="control-btn-list-vertical">
    <control-button iconNameOn="user-plus" (clickEvent)="toggleInvite()">
    </control-button>
    <control-button iconNameOn="settings" (clickEvent)="toggleSettings()">
    </control-button>
  </div>
  <control-button class="btn-trigger main-button big-button menu-button" iconNameOn="hamburger-menu" *ngIf="!menuOpen"
    (clickEvent)="toggleMenu()">
  </control-button>
  <control-button class="btn-trigger main-button big-button close-menu-button" iconNameOn="close" *ngIf="menuOpen"
    (clickEvent)="toggleMenu()">
  </control-button>
  <div class="control-btn-list-horizontal">
    <control-button iconNameOn="mic-on-icon" iconNameOff="mic-off-icon" [isOn]="isMicOn" [isDisabled]="micDisable"
      [showError]="showMicError" [errorMessage]="'inCall.micError'" (clickEvent)="toggleMicrophone($event)">
    </control-button>

    <control-button iconNameOn="camera-on" iconNameOff="camera-off" [isOn]="isCameraOn" [isDisabled]="camDisable || switchCamDisable"
      [showError]="showCamError" [errorMessage]="'inCall.camError'" (clickEvent)="toggleCamera($event)">
    </control-button>

    <control-button iconNameOn="camera-switch" [isDisabled]="switchCamDisable || checkSwitchCameraIconStatus()"
      (clickEvent)="onClickOfSwitchCamera()"> </control-button>

    <!-- This one is going to be the switch to full screen -->
    <control-button class="switch-video" iconNameOn="{{maximize ? 'chat-new': 'switch-video'}}" iconNameOff="{{maximize ? 'chat-new': 'switch-video'}}" (clickEvent)="toggleFullScreen()">
      <span *ngIf="maximize && (showNotification || (unreadMessageCount && unreadMessageCount > 0))"
            class="notification">{{unreadMessageCount}}</span>
    </control-button>
  </div>
</div>
