<div class="queue-header" [ngClass]="{'non-intro': !introScreen}">
    <div class="close-button">
        <svg-icon class="op-d-flex op-align-items-center op-justify-content-center"
                  (click)="initiateClose()" name="close-menu"></svg-icon>
        <ng-container *ngIf="showCloseModal">
            <ng-container *ngTemplateOutlet="waitScreenPopover"></ng-container>
        </ng-container>
    </div>
    <div class="queue-client-logo" *ngIf="agentStatusInfo?.tenant_logo">
        <img alt="Tenant Logo" *ngIf="agentStatusInfo?.tenant_logo" [src]="agentStatusInfo?.tenant_logo">
    </div>
    <ng-template #waitScreenPopover>
        <div class="close-popover" >
            <div class="op-d-flex op-justify-content-end op-align-items-center">
                <svg-icon name="close" (click)="showCloseModal = false" appAddSnowplowEvent
                          [category]="spTracker.labels.inboundQueue" [action]="spTracker.labels.cancel"
                          [label]="spTracker.labels.mainQueueLeave"></svg-icon>
            </div>
            <div class="op-d-flex op-justify-content-start op-align-items-center op-mt-3">
                <p [innerHTML]="'intermediateScreen.waitCloseMsg' | translate"></p>
            </div>
            <div class="op-d-flex op-justify-content-end op-align-items-center op-mt-3">
                <button class=" op-btn op-btn-primary op-btn-stroked" [innerHTML]="'common.yes' | translate"
                        appAddSnowplowEvent
                        [category]="spTracker.labels.inboundQueue" [action]="spTracker.labels.cancel"
                        [label]="spTracker.labels.mainQueueLeave" [property]="spTracker.labels.callId" [value]="queuePositionId"
                        (click)="removeFromQueue(true)"></button>
                <button class=" op-btn op-btn-flat op-btn-primary  op-ml-3"
                        [innerHTML]="'intermediateScreen.yesLeaveMessage' | translate" appAddSnowplowEvent
                        [category]="spTracker.labels.inboundQueue" [action]="spTracker.actions.leaveMessage"
                        [label]="spTracker.labels.mainQueueLeave"
                        (click)="removeFromQueue(false)"></button>
            </div>
        </div>
    </ng-template>
</div>
