import { LocalPluginStates, SessionPluginStates } from '../core/constants/common.enum';

export const SET_LOCAL_STORE_INFO = '[Local Store] Set Local Store';
export const SET_SESSION_STORE_INFO = '[Session Store] Set Session Store';

export class SetLocalStoreInfo {
    readonly type = SET_LOCAL_STORE_INFO;
    constructor(public optimyLocalStore: LocalPluginStates) {}
}
export class SetSessionStoreInfo {
    readonly type = SET_SESSION_STORE_INFO;
    constructor(public optimySessionStore: SessionPluginStates) {}
}

export type Action =
    SetLocalStoreInfo
    | SetSessionStoreInfo;
